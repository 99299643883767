import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import slide1 from '../assets/images/josefin1.webp';
import slide2 from '../assets/images/josefin2.webp';

var striptags = require('striptags');

class DesignCollection extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      ...props.initialData,
      products: [],
      sections: [],
      categories: [],
      activeIndex: 0,
      slides: [
        {
          src: slide1,
          title: 'Designkollektion',
          subtitle: 'med josefin lustig',
          category: 'LIVE HÖSTEN 2021!',
          align: 'center',
        },
        {
          src: slide2,
          title: 'upptäckt det okända med oss!',
          category: 'NYA TRENDER 2021',
          align: 'left',
        },
      ],
      instagramPhotos: [],
    };
  }

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.slides.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.slides.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    let params = getSearchParams(this.props[0].location.search, {});

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          params,
          this.props[0].location.pathname,
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.searchResultsWrap &&
      this.searchResultsWrap.current &&
      !this.searchResultsWrap.current.contains(event.target)
    ) {
      this.setState({
        showSuggestions: false,
      });
    }
  };

  render() {
    let imageSize =
      typeof window != 'undefined' && window.innerWidth < 768 ? 'xs' : 'lg';
    console.log(this.props);

    return (
      <div>
        <div className="home-slider design-collection-slider">
          <Carousel
            activeIndex={this.state.activeIndex}
            next={this.next}
            previous={this.previous}
          >
            {this.state.slides.map((slide, idx) => {
              console.log(slide);

              return (
                <CarouselItem
                  key={idx}
                  onExiting={this.onExiting}
                  onExited={this.onExited}
                >
                  <div className="slide">
                    <div className="layer-15"></div>
                    <img src={slide.src} />
                    <div className={`slide-content slide-${slide.align}`}>
                      <h6>{slide.category}</h6>
                      <h1>{slide.title}</h1>
                      {slide.subtitle ? <p>{slide.subtitle}</p> : null}
                    </div>
                  </div>
                </CarouselItem>
              );
            })}

            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={this.previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={this.next}
            />
          </Carousel>
        </div>

        <div className="design-collection">
          <Container>
            <Row>
              <Col lg="12">
                <div className="design-collection-content">
                  <h1>JOSEFIN LUSTIG X VENTURE DESIGN</h1>
                  <h6>
                    Venture Design ingår designsamarbete med Josefin Lustig.
                    Tillsammans släpper vi en första kollektion hösten 2021.
                    Kollektionen, som är Josefins första vad gäller möbler
                    kommer att bestå av olika ting som förgyller hemmet.
                  </h6>
                  <blockquote>
                    ”Josefin har ett spännande öga för mode och design. Här
                    kommer vi mötas av en häftig mix mellan historia och
                    framtid, lyx och funk­tion. Hennes inredning i hemmet i
                    Västerås samplar snyggt från minimalism till hotellmiljöers
                    scenografi – något som väckt en nyfikenhet hos oss”, säger
                    VD Ola Johansson
                  </blockquote>
                  <h3>Om Josefin:</h3>
                  <h4>
                    Josefin jobbar till vardags med sina sociala kanaler och
                    design i olika former. Numera bosatt i Västerås tillsammans
                    med maken Mikael Lustig och deras 2 barn, efter ett par års
                    äventyr utomlands.{' '}
                  </h4>
                  <blockquote>
                    "Jag är så glad och taggad på att få skapa min egen
                    kollektion tillsammans med Venture Design! Att få skapa sina
                    egna möbler är verkligen en dröm som går i uppfyllelse.” –
                    Josefin Lustig
                  </blockquote>
                  <a href="https://josefinlustig.com/">LÄS MER OM JOSEFIN</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Page(DesignCollection);
