import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import homeBackgroundImage from '../assets/images/omm.jpg';
import aboutImage from '../assets/images/about.jpg';
import aboutImage2 from '../assets/images/about2.jpg';
import aboutImage3 from '../assets/images/about3.jpg';
import aboutImage4 from '../assets/images/about4.jpg';
import aboutImage5 from '../assets/images/about5.jpg';

var striptags = require('striptags');

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    console.log(this.props);
    return (
      <div className="about-us-movie">
        <video controls autoPlay muted playsInline>
          <source src="/aboutus.mov"></source>
        </video>
      </div>
    );
  }
}

export default Page(AboutPage);
