import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import AccountInfo from '../components/forms/complaintForm';
import user from '../assets/images/user.png';
import AccountNav from '../components/accountNav';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';

import { API_ENDPOINT } from '../constants';
var striptags = require('striptags');

class ComplaintPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      controlPanel: true,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  submit = (data) => {
    this.setState(
      {
        _loading: true,
      },
      () => {
        fetch(API_ENDPOINT + '/account/complaint', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.error) {
              this.setState({ error: result.error, _loading: false });
            } else {
              this.setState({ _loading: false });
              this.props[0].history.push('/account');
            }
          });
      },
    );
  };

  render() {
    return (
      <div className="account-page">
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? imageMobile
                : image
            }
          />
          <div className="test-content">
            <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
          </div>
        </section>

        <Container>
          <Row>
            <Col lg="4">
              <AccountNav {...this.props} />
            </Col>
            <Col lg="8">
              <div className="account-container">
                {this.props.uData ? (
                  <AccountInfo
                    lang={this.props.lang}
                    initialValues={this.props.uData}
                    onSubmit={this.submit}
                    loading={this.state._loading}
                  />
                ) : null}
                {this.state.error ? (
                  <p className="form-error">{this.state.error}</p>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(ComplaintPage);
