import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

const required = (value) => (value ? undefined : 'Required');

const renderCheckField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Check
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    // multilang={multilang}
    // lang={lang}

    {...input}
  />
);

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  type,
  disabled,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    disabled={disabled}
    label={label}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

class form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
    };
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="login-form accoutn-info-form">
        <Row>
          {/* <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="username"
                                component={renderTextField}
                                label={"Företagsnamn eller Förnamn och Efternamn *".translate(this.props.lang)}
                                disabled
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <p style={{ fontSize: 16, fontStyle: 'italic', fontWeight: 300, marginBottom: 5 }}>Detta är hur ditt namn kommer visas i kontosektionen och i recensioner</p>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="email"
                                component={renderTextField}
                                label={"E-postadress *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col> */}
          <Col lg="12">
            <h3>{'RETURNERA VAROR'.translate(this.props.lang)}</h3>
            <h3>{'Kära kund'.translate(this.props.lang)}</h3>
            <h6>
              {'Gäller din retur ett ångerköp eller inom din returrätt vänligen fyll i formuläret nedan. Kundtjänst kommer besvara dig angående din retur inom 24 timmar.'.translate(
                this.props.lang,
              )}
            </h6>
            <h6>
              {'Tack på förhand och ha en fin dag!'.translate(this.props.lang)}
            </h6>
          </Col>

          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="orderNumber"
                component={renderTextField}
                label={'*Ordernummer'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="articleNumber"
                component={renderTextField}
                label={'*Artikelnummer'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="numberOfArticles"
                component={renderTextField}
                label={'*Antal artiklar'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="reasonOfReturn"
                component={renderTextareaField}
                label={'Anledning till att du önskar returnera'.translate(
                  this.props.lang,
                )}
              ></Field>
            </div>
          </Col>

          <Col
            lg="12"
            className="btn-wrap"
            style={{
              marginTop: 30,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {this.props.loading ? null : (
              <button className="button">
                {'SKICKA'.translate(this.props.lang)}
              </button>
            )}
          </Col>

          <Col lg="12" style={{ marginTop: 45 }}>
            <p>
              {'Vid retur kommer en returavgift debiteras på 150 sek per paket. Vid retur av större/tyngre artiklar debiteras även en returfrakt på 350 sek per paket.'.translate(
                this.props.lang,
              )}
            </p>
            <br></br>
            <p>
              {'Om din hantering av varan inneburit en värdeminskning av densamma eller vid saknad eller förstörd originalkartong kan ett avdrag för värdeminskning göras, avdraget bedöms från fall till fall.'.translate(
                this.props.lang,
              )}
            </p>
            <br></br>

            <p>
              {'Återbetalning görs så snart vi har mottagit returen och behandlat den, normalt tar detta ca 2-3 veckor från det datum du skickade returen. Återbetalning sker via samma betalningssätt som du använde vid beställningen. Vid återbetalning via bankkort tar det några dagar extra innan du ser pengarna på ditt konto p.g.a.bankens hanteringstid.'.translate(
                this.props.lang,
              )}
            </p>
          </Col>
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: 'accountForm', // a unique identifier for this form
})(form);
