import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Gototext from '../components/forms/fields/number';
import Gotobutton from '../components/forms/fields/button';
import Article from '../components/article';
import homeBackgroundImage from '../assets/images/home.jpg';
import filter from '../assets/images/filter.png';
import plusIcon from '../assets/svg/plus.svg';
import closeIcon from '../assets/svg/close.svg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import {IMAGE_URL} from '../constants';
import sale from '../assets/images/sale.jpg';
import logoDark from '../assets/svg/logo-dark.svg';
import ArticleDetail from '../components/articleDetail';
var striptags = require('striptags');

class ArticlePage extends Component {
  constructor(props) {
    super(props);
    let page = getSearchParams(this.props[0].location.search, {});
    !page.page ? (page.page = 1) : (page.page = page.page);
    this.state = {
      ...props.initialData,
      products: [],
      filters: {},
      categoryChain: [],
      pageSize: 24,
      showFilters: false,
      gotovalue: page.page,
      gotoindex: parseInt(page.page),
      login_check: null,
      _page: 1,
      pages: [],
      loadingProducts: true,
      _loading: true,
      categoryIndex: 0,
    };
    this.handlechage = this.handlechage.bind(this);
  }
  handlechage(event) {
    if (event.target.value <= this.state.total / 24 + 1) {
      const numberpage = event.target.value - 1;
      this.setState({ gotovalue: numberpage, gotoindex: event.target.value });
    }
  }

  get = () => {
    if (this.props[0].location.pathname.indexOf('/products') === 0 && !this.state.articleBreadcrumb) {
      return;
    }

    this.setState(
      {
        _loading: true,
      },
      () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
          this.props.loadData[i](
            window.fetch,
            this.props.lang,
            this.props[0].match,
          ).then((data) => {
            this.setState({
              ...data,
            });
          });
        }

        let params = getSearchParams(this.props[0].location.search, {});

        if (this.props.loadDataWithQuery) {
          for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
              window.fetch,
              this.props.lang,
              this.props[0].match,
              params,
              this.props[0].location.pathname.indexOf('/products') === 0 ? this.state.articleBreadcrumb : this.props[0].location.pathname,
            ).then((data) => {
              let stateObj = {
                ...data,
              };

              let isProductAPI = false;
              if (data && data.products) {
                isProductAPI = true;
                let page = data.__page;
                stateObj.pages = this.state.pages;
                stateObj.pages[page] = data.products;
                stateObj._loading = null;
              }

              this.setState(stateObj, () => {
                this.props.setCacheState({ ...this.state });
                this.props.updateMeta(
                  this.props.generateSeoTags(this.state, this.props.lang),
                );
                if (isProductAPI) {
                  setTimeout(() => {
                    this.setState({
                      loadingProducts: null,
                    });
                  }, 2000);
                }
              });
            });
          }
        }

        this.props.updateMeta(
          this.props.generateSeoTags(this.state, this.props.lang),
        );
      },
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
      let params = {};
      let s = false;
      params = getSearchParams(this.props[0].location.search, { page: 0 });

      if (params.page && params.page != 0 && !this.props.cacheState) {
        this.props[0].history.push(
          this.props[0].location.pathname +
          generateSearchLink(
            this.props[0].location.search,
            { page: 0 },
            'page',
            0,
            false,
          ),
        );
      }

      if (params) {
        if (params.page) {
          this.setState({
            _page: Number(params.page) + 1,
          });
        }

        let check = Object.keys(params).filter(
          (item) => item.indexOf('attrs') !== -1,
        ).length;
        this.setState({
          showFilters: check,
        });
      }
    }

    this.get();

    if (this.props[0].history.action == 'POP' && this.props.cacheState) {
      this.setState({
        ...this.props.cacheState,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let params = {};
    params = getSearchParams(this.props[0].location.search, { page: 0 });

    if (
      !prevState.category &&
      this.state.category &&
      this.state.category.attributes &&
      this.state.category.attributes.length
    ) {
      this.state.category.attributes.map((item, idx) => {
        let filters = this.state.filters;
        params['attrs.' + item.key]
          ? (filters[item.key] = true)
          : (filters[item.key] = false);
      });
    }

    if (prevProps[0].location.pathname != this.props[0].location.pathname && (this.props[0].location.pathname.indexOf('/products') === -1 && prevProps[0].location.pathname.indexOf('/products') === -1)) {
      this.setState({
        // category: null,
        showFilters: false,
        products: [],
        total: 0,
        categoryIndex: 0
      }, () => {
        this.setState({ showFilters: false })
        this.get();
      })
    }

    if (prevProps[0].location.search != this.props[0].location.search && this.props[0].location.pathname.indexOf('/products') == -1) {
      let params = getSearchParams(this.props[0].location.search, { page: 0 });
      let prevParams = getSearchParams(prevProps[0].location.search, {
        page: 0,
      });

      if (params.page == 0) {
        this.setState(
          {
            products: [],
            pages: [],
            total: 0,
          },
          () => {
            this.get();
          },
        );
      } else {
        this.get();
      }
    }
  }

  render() {
    let imageSize =
      typeof window != 'undefined' && window.innerWidth < 768 ? 'xs' : 'lg';
    let params = getSearchParams(this.props[0].location.search, { page: 0 });

    let categoryImage = null;
    if (this.state.categoryChain) {
      for (let i = this.state.categoryChain.length; i >= 0; i--) {
        if (
          this.state.categoryChain[i] &&
          this.state.categoryChain[i].image &&
          this.state.categoryChain[i].image[imageSize]
        ) {
          categoryImage = this.state.categoryChain[i].image[imageSize];
          break;
        }
      }
    }

    let maxWidth = 0;
    let width =
      typeof window !== 'undefined' ? window.innerWidth - 40 - 252 - 64 : 0;

    if (this.categoriesScroll) {
      let sum = 0;

      for (let i = 0; i < this.categoriesScroll.children.length; i++) {
        if (
          sum +
          this.categoriesScroll.children[i].getBoundingClientRect().width >=
          width
        ) {
          maxWidth = sum;
          break;
        }
        sum += this.categoriesScroll.children[i].getBoundingClientRect().width;
      }
    }
    if (maxWidth > 1200) {
      maxWidth = 1200;
    }

    return (
      <div>
        {this.props[0].location.pathname.indexOf('/search') == -1 &&
          this.state.categoryChain &&
          this.state.categoryChain.length ? (
          this.state.category && this.state.category.brandCategory ? (
            <section className="test-brand">
              {this.props[0].location.pathname.indexOf('campaign') == -1 ? (
                <div
                  className="test-content-brand"
                  style={{
                    backgroundColor: this.state.category.brandBackgroundColor,
                  }}
                >
                  <img src={IMAGE_URL + this.state.category.brandImage} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.categoryChain.length
                        ? Object.translate(
                          this.state.categoryChain[
                          this.state.categoryChain.length - 1
                          ],
                          'description',
                          this.props.lang,
                        )
                        : null,
                    }}
                  ></div>
                </div>
              ) : null}
              <img
                src={
                    IMAGE_URL.replace('testapi', 'api') +
                  (this.state.categoryChain.length ? categoryImage : null)
                }
              />
            </section>
          ) : (
            <section className="test">
              <img
                src={
                    IMAGE_URL.replace('testapi', 'api') +
                  (this.state.categoryChain.length ? categoryImage : null)
                }
              />
              {this.props[0].location.pathname != '/campaign' &&
                this.props[0].location.pathname.indexOf('/sale') == -1 ? (
                <div className="test-content">
                  <h1>
                    {this.state.categoryChain.length
                      ? Object.translate(
                        this.state.categoryChain[
                        this.state.categoryChain.length - 1
                        ],
                        'name',
                        this.props.lang,
                      )
                      : null}
                  </h1>
                </div>
              ) : null}
            </section>
          )
        ) : null}
        {this.props[0].location.pathname.indexOf('/search') == -1 ? (
          <div className="breadcrumb-container-2">
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Link to="/">{'HEM'.translate(this.props.lang)}</Link>
                  {this.state.categoryChain.map((item, idx) => {
                    return (
                      <>
                        <span>/</span>
                        <Link
                          to={Object.translate(
                            item,
                            'breadcrumb',
                            this.props.lang,
                          )}
                        >
                          {Object.translate(item, 'name', this.props.lang)}
                        </Link>
                      </>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}

        <div
          className={`filters ${this.state.categoryChain.length == 1 &&
            this.state.categoryChain &&
            this.state.categoryChain[this.state.categoryChain.length - 1] &&
            this.state.categoryChain[this.state.categoryChain.length - 1]
              .parent == null
            ? 'filters-1'
            : ''
            }`}
        >
          <Container fluid>
            <div className={`filters-content`}>
              <div className="filters-left">
                {this.state.categoryChain[1] &&
                  this.state.categoryChain &&
                  this.state.categoryChain[this.state.categoryChain.length - 1] &&
                  this.state.categoryChain[this.state.categoryChain.length - 1]
                    .parent != null &&
                  this.props[0].location.pathname.indexOf('campaign') == -1 ? (
                  this.state.showFilters ? (
                    <button
                      className="filter-button filter-opened"
                      onClick={() => {
                        this.setState(
                          {
                            showFilters: !this.state.showFilters,
                          },
                          () => {
                            this.forceUpdate();
                          },
                        );
                      }}
                    >
                      <Isvg src={closeIcon} className="close-icon" />
                      <span>{'Filtrera'.translate(this.props.lang)}</span>
                    </button>
                  ) : (
                    <button
                      className="filter-button filter-closed"
                      onClick={() => {
                        this.setState(
                          {
                            showFilters: !this.state.showFilters,
                          },
                          () => {
                            this.forceUpdate();
                          },
                        ),
                          console.log(this.state.showFilters);
                      }}
                    >
                      <img src={filter} />
                      <span>{'Filtrera'.translate(this.props.lang)}</span>
                    </button>
                  )
                ) : null}

                {(this.state.categoryChain.length == 1 ||
                  (this.state.categoryChain &&
                    this.state.categoryChain[
                    this.state.categoryChain.length - 1
                    ] &&
                    this.state.categoryChain[
                      this.state.categoryChain.length - 1
                    ].parent == null)) &&
                  typeof window !== undefined ? (
                  <div className="categories-with-icons-wrap">
                    {                                     (this.props[0].location.pathname.match(new RegExp("/", "g")) || []).length >= 3 ?
                    null :
                    <div className="gradient"></div>}
                    {maxWidth &&
                      this.state.category &&
                      this.state.category.children.length > maxWidth / 100 ? (
                      <button
                        className={`categories-with-icons-prev ${this.state.categoryIndex - maxWidth / 100 < 0
                          ? 'disabled'
                          : ''
                          }`}
                        onClick={() => {
                          console.log('prev');

                          let newIdx =
                            this.state.categoryIndex - maxWidth / 100;
                          if (newIdx < 0) {
                            return;
                          }
                          // let scrollLeft = 0;
                          // for (let i = 0; i < newIdx; i++) {
                          //     scrollLeft += this.categoriesScroll.children[i].getBoundingClientRect().width;
                          // }

                          this.categoriesScroll.children[newIdx].scrollIntoView(
                            { behavior: 'smooth', block: 'nearest' },
                          );
                          //this.categoriesScroll.scrollLeft = scrollLeft;
                          this.setState({
                            categoryIndex: newIdx,
                          });
                        }}
                      ></button>
                    ) : null}
                    <div
                      className="categories-with-icons"
                      style={
                        maxWidth &&
                          typeof window !== 'undefined' &&
                          window.innerWidth > 768
                          ? { maxWidth: maxWidth }
                          : {}
                      }
                      ref={(node) => (this.categoriesScroll = node)}
                    >
                      {this.state.category && this.state.category.children
                        ? this.state.category.children.map((item, idx) => {
                          //if (idx < 5 || imageSize == 'xs')
                          return (
                            <div className="categories-with-icons-item">
                              <Link
                                key={idx}
                                to={Object.translate(
                                  item,
                                  'breadcrumb',
                                  this.props.lang,
                                )}
                              >
                                <button>
                                  <Isvg src={IMAGE_URL.replace('testapi', 'api') + item.icon} />
                                </button>{' '}
                                {Object.translate(
                                  item,
                                  'name',
                                  this.props.lang,
                                )}
                              </Link>
                            </div>
                          );
                        })
                        : null}
                    </div>
                    {maxWidth &&
                      this.state.category &&
                      this.state.category.children.length > maxWidth / 100 ? (
                      <button
                        className={`categories-with-icons-next ${!maxWidth ||
                          (this.categoriesScroll &&
                            this.state.categoryIndex + maxWidth / 100 >=
                            this.categoriesScroll.children.length)
                          ? 'disabled'
                          : ''
                          }`}
                        onClick={() => {
                          console.log(
                            'next',
                            this.categoriesScroll.children.length,
                          );
                          let newIdx =
                            this.state.categoryIndex + maxWidth / 100;
                          console.log(
                            newIdx,
                            maxWidth / 100,
                            this.categoriesScroll.children.length,
                          );
                          if (!maxWidth) {
                            return;
                          }

                          if (newIdx >= this.categoriesScroll.children.length) {
                            return;
                          }
                          this.categoriesScroll.children[newIdx].scrollIntoView(
                            {
                              behavior: 'smooth',
                              block: 'nearest',
                              inline: 'start',
                            },
                          );

                          // let scrollLeft = 0;
                          // for (let i = 0; i < newIdx; i++) {
                          //     scrollLeft += this.categoriesScroll.children[i].getBoundingClientRect().width;
                          // }
                          // console.log(newIdx, scrollLeft)
                          // this.categoriesScroll.scrollLeft = scrollLeft;
                          this.setState({
                            categoryIndex: newIdx,
                          });
                        }}
                      ></button>
                    ) : null}
                  </div>
                ) : (
                  <div
                    className={`subcategories ${this.state.categoryChain.length == 1 ||
                      (this.state.categoryChain &&
                        this.state.categoryChain[
                        this.state.categoryChain.length - 1
                        ] &&
                        this.state.categoryChain[
                          this.state.categoryChain.length - 1
                        ].parent == null)
                      ? 'subcategories-1'
                      : ''
                      }`}
                  >
                    <div className="categories-with-icons-wrap">
{                                     (this.props[0].location.pathname.match(new RegExp("/", "g")) || []).length >= 3 ?
                    null :
                    <div className="gradient"></div>}
                    {maxWidth &&
                        this.state.category &&
                        this.state.category.children.length > maxWidth / 100 ? (
                        <button
                          className={`categories-with-icons-prev ${this.state.categoryIndex - maxWidth / 100 < 0
                            ? 'disabled'
                            : ''
                            }`}
                          onClick={() => {
                            console.log('prev');

                            let newIdx =
                              this.state.categoryIndex - maxWidth / 100;
                            if (newIdx < 0) {
                              return;
                            }

                            this.categoriesScroll.children[newIdx].scrollIntoView(
                              { behavior: 'smooth', block: 'nearest' },
                            );
                            this.setState({
                              categoryIndex: newIdx,
                            });
                          }}
                        ></button>
                      ) : null}
                      <div
                        className="categories-with-icons"
                        style={
                          maxWidth &&
                            typeof window !== 'undefined' &&
                            window.innerWidth > 768
                            ? { maxWidth: maxWidth }
                            : {}
                        }
                        ref={(node) => (this.categoriesScroll = node)}
                      >
                        {this.state.category && this.state.category.children
                          ? this.state.category.children.map((item, idx) => {
                            //if (idx < 5 || imageSize == 'xs')
                            return (
                              <>

                                {
                                 ( ( 
                                    item.breadcrumb.sv.match(new RegExp("/", "g")) || []).length === 4 && (this.props[0].location.pathname.indexOf('/campaign') === -1 && this.props[0].location.pathname.indexOf('/nyheter') === -1) )
                                    ||
                                    ( ( 
                                        item.breadcrumb.sv.match(new RegExp("/", "g")) || []).length === 5 && (this.props[0].location.pathname.indexOf('/campaign') === 0 || this.props[0].location.pathname.indexOf('/nyheter') === 0) )  ?
                                    <Link
                                      key={idx}
                                      to={Object.translate(
                                        item,
                                        'breadcrumb',
                                        this.props.lang,
                                      )}
                                    >
                                      <button className="category-button">
                                        {Object.translate(
                                          item,
                                          'name',
                                          this.props.lang,
                                        )}
                                      </button>
                                    </Link> :
                                    <div className="categories-with-icons-item">
                                      <Link
                                        key={idx}
                                        to={Object.translate(
                                          item,
                                          'breadcrumb',
                                          this.props.lang,
                                        )}
                                      >
                                        <button>
                                          <Isvg src={IMAGE_URL.replace('testapi', 'api') + item.icon} />
                                        </button>{' '}
                                        {Object.translate(
                                          item,
                                          'name',
                                          this.props.lang,
                                        )}
                                      </Link>
                                    </div>
                                }

                              </>
                            );
                          })
                          : null}
                      </div>
                    </div>
                    {/* {this.state.category && this.state.category.children
                      ? this.state.category.children.map((item, idx) => {
                        //if (idx < 5 || imageSize == 'xs')
                        return (
                          <Link
                            key={idx}
                            to={Object.translate(
                              item,
                              'breadcrumb',
                              this.props.lang,
                            )}
                          >
                            <button className="category-button">
                              {Object.translate(
                                item,
                                'name',
                                this.props.lang,
                              )}
                            </button>
                          </Link>
                        );
                      })
                      : null} */}

                    {/* {this.state.category &&
                                            !this.state.category.children.length &&
                      this.state.category.siblings
                      ? this.state.category.siblings.map((item, idx) => {
                        //  if (idx < 5 || imageSize == 'xs')
                        return (
                          <Link
                            key={idx}
                            to={Object.translate(
                              item,
                              'breadcrumb',
                              this.props.lang,
                            )}
                          >
                            <button
                              className={`category-button ${this.props[0].location.pathname ==
                                Object.translate(
                                  item,
                                  'breadcrumb',
                                  this.props.lang,
                                )
                                ? 'active'
                                : ''
                                }`}
                            >
                              {Object.translate(
                                item,
                                'name',
                                this.props.lang,
                              )}
                            </button>
                          </Link>
                        );
                      })
                      : null}
                                    </div>
              )}
                      */}
                    {this.state.category &&
                      !this.state.category.children.length &&
                      this.state.category.siblings
                      ?
                      <div className="categories-with-icons-wrap">
{                                     (this.props[0].location.pathname.match(new RegExp("/", "g")) || []).length >= 3 ?
                    null :
                    <div className="gradient"></div>}
                    
                        <div
                          className="categories-with-icons"
                          style={
                            maxWidth &&
                              typeof window !== 'undefined' &&
                              window.innerWidth > 768
                              ? { maxWidth: maxWidth }
                              : {}
                          }
                          ref={(node) => (this.categoriesScroll = node)}
                        > {
                            this.state.category.siblings.map((item, idx) => {
                              //  if (idx < 5 || imageSize == 'xs')
                              return (
                                <>
                                  {
                                    (item.breadcrumb.sv.match(new RegExp("/", "g")) || []).length === 4 ?
                                      <Link
                                        key={idx}
                                        to={Object.translate(
                                          item,
                                          'breadcrumb',
                                          this.props.lang,
                                        )}
                                      >
                                        <button className="category-button">
                                          {Object.translate(
                                            item,
                                            'name',
                                            this.props.lang,
                                          )}
                                        </button>
                                      </Link> :
                                      <div className="categories-with-icons-item">
                                        <Link
                                          key={idx}
                                          to={Object.translate(
                                            item,
                                            'breadcrumb',
                                            this.props.lang,
                                          )}
                                        >
                                          <button>
                                            <Isvg src={IMAGE_URL.replace('testapi', 'api') + item.icon} />
                                          </button>{' '}
                                          {Object.translate(
                                            item,
                                            'name',
                                            this.props.lang,
                                          )}
                                        </Link>
                                      </div>
                                  }

                                </>


                              )
                            })}
                        </div>
                      </div> : null}



                  </div>
                )}

              </div>
              <div className="filters-right">
                <span>
                  {/*{'Visar'.translate(this.props.lang)} {(Number(params.page)) * this.state.pageSize + 1}–{(Number(params.page) + 1) * this.state.pageSize < this.state.total ? (Number(params.page) + 1) * this.state.pageSize : this.state.total} {'av'.translate(this.props.lang)} */}
                  {this.state.total} {'TRÄFFAR'.translate(this.props.lang)}{' '}
                  {/*'resultat'.translate(this.props.lang)*/}
                </span>
                <div class="dropdown dropdown-hover">
                  <a class="dropbtn">
                    {'Sortera på'.translate(this.props.lang)}{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </a>
                  <div
                    class={
                      this.state.langDrop
                        ? 'dropdown-content open'
                        : 'dropdown-content'
                    }
                  >
                    <Link
                      to={generateSearchLinkMultiple(
                        this.props[0].location.search,
                        { page: 0 },
                        [
                          { name: 'sort', value: null },
                          { name: 'page', value: 0 },
                        ],
                        false,
                      )}
                    >
                      {'Nyheter Först'.translate(this.props.lang)}
                    </Link>
                    <Link
                      to={generateSearchLinkMultiple(
                        this.props[0].location.search,
                        { page: 0 },
                        [
                          { name: 'sort', value: 'bestseller' },
                          { name: 'page', value: 0 },
                        ],
                        false,
                      )}
                    >
                      {'BÄSTSÄLJARE'.translate(this.props.lang)}
                    </Link>
                    <Link
                      to={generateSearchLinkMultiple(
                        this.props[0].location.search,
                        { page: 0 },
                        [
                          { name: 'sort', value: 'name-asc' },
                          { name: 'page', value: 0 },
                        ],
                        false,
                      )}
                    >
                      {'Namn - A, B, C'.translate(this.props.lang)}
                    </Link>
                    <Link
                      to={generateSearchLinkMultiple(
                        this.props[0].location.search,
                        { page: 0 },
                        [
                          { name: 'sort', value: 'name-desc' },
                          { name: 'page', value: 0 },
                        ],
                        false,
                      )}
                    >
                      {'Namn - Z, Y, X'.translate(this.props.lang)}
                    </Link>
                    <Link
                      to={generateSearchLinkMultiple(
                        this.props[0].location.search,
                        { page: 0 },
                        [
                          { name: 'sort', value: 'brand-asc' },
                          { name: 'page', value: 0 },
                        ],
                        false,
                      )}
                    >
                      {'Varumärke - A, B, C'.translate(this.props.lang)}
                    </Link>
                    <Link
                      to={generateSearchLinkMultiple(
                        this.props[0].location.search,
                        { page: 0 },
                        [
                          { name: 'sort', value: 'brand-desc' },
                          { name: 'page', value: 0 },
                        ],
                        false,
                      )}
                    >
                      {'Varumärke - Z, Y, X'.translate(this.props.lang)}
                    </Link>

                    {!this.props.uData &&
                      this.props[0].location.pathname.indexOf('/sale') ===
                      -1 ? null : (
                      <Link
                        to={generateSearchLinkMultiple(
                          this.props[0].location.search,
                          { page: 0 },
                          [
                            { name: 'sort', value: 'price-asc' },
                            { name: 'page', value: 0 },
                          ],
                          false,
                        )}
                      >
                        {'Pris - 1, 2, 3'.translate(this.props.lang)}
                      </Link>
                    )}

                    {!this.props.uData &&
                      this.props[0].location.pathname.indexOf('/sale') ===
                      -1 ? null : (
                      <Link
                        to={generateSearchLinkMultiple(
                          this.props[0].location.search,
                          { page: 0 },
                          [
                            { name: 'sort', value: 'price-desc' },
                            { name: 'page', value: 0 },
                          ],
                          false,
                        )}
                      >
                        {'Pris - 3, 2, 1'.translate(this.props.lang)}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div >

        <section className="articles">
          <Container fluid>
            <Row>
              {
                console.log('da li se filteri prikazuju; ', this.state.showFilters)
              }
              {this.state.showFilters ? (
                <Col lg="2">
                  <div
                    className={
                      this.state.showFilters
                        ? 'filter-list filter-list-shown'
                        : 'filter-list'
                    }
                  >
                    <ul>
                      {this.state.category &&
                        this.state.category.attributes &&
                        this.state.category.attributes.length
                        ? this.state.category.attributes.map((item, idx) => {
                          if (Object.translate(item, 'name', this.props.lang))
                            return (
                              <li
                                className={
                                  this.state.filters[item.key]
                                    ? 'filter-list-row list-row-opened'
                                    : 'filter-list-row'
                                }
                              >
                                <div
                                  className="filter-list-row-container"
                                  onClick={() => {
                                    let filters = this.state.filters;
                                    filters[item.key] = filters[item.key]
                                      ? false
                                      : true;
                                    this.setState({ filters });
                                  }}
                                >
                                  <h6>
                                    {Object.translate(
                                      item,
                                      'name',
                                      this.props.lang,
                                    )}
                                  </h6>
                                </div>
                                {this.state.filters[item.key] ? (
                                  <div className="filter-dropdown">
                                    {false && item.key == 'color' ? (
                                      <Row>
                                        {item.values.map((value) => {
                                          return (
                                            <Col lg="6" xs="6">
                                              <div
                                                className="color-picker"
                                                onClick={() => {
                                                  if (
                                                    !params[
                                                    'attrs.' + item.key
                                                    ]
                                                  ) {
                                                    this.props[0].history.push(
                                                      generateSearchLinkMultiple(
                                                        this.props[0].location
                                                          .search,
                                                        { page: 0 },
                                                        [
                                                          {
                                                            name:
                                                              'attrs.' +
                                                              item.key,
                                                            value: value,
                                                          },
                                                          {
                                                            name: 'page',
                                                            value: 0,
                                                          },
                                                        ],
                                                        false,
                                                      ),
                                                    );
                                                  } else {
                                                    if (
                                                      params[
                                                      'attrs.' + item.key
                                                      ] == value
                                                    ) {
                                                      this.props[0].history.push(
                                                        generateSearchLinkMultiple(
                                                          this.props[0]
                                                            .location.search,
                                                          { page: 0 },
                                                          [
                                                            {
                                                              name:
                                                                'attrs.' +
                                                                item.key,
                                                              value: null,
                                                            },
                                                            {
                                                              name: 'page',
                                                              value: 0,
                                                            },
                                                          ],
                                                          false,
                                                        ),
                                                      );
                                                    } else {
                                                      this.props[0].history.push(
                                                        generateSearchLinkMultiple(
                                                          this.props[0]
                                                            .location.search,
                                                          { page: 0 },
                                                          [
                                                            {
                                                              name:
                                                                'attrs.' +
                                                                item.key,
                                                              value: value,
                                                            },
                                                            {
                                                              name: 'page',
                                                              value: 0,
                                                            },
                                                          ],
                                                          false,
                                                        ),
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                <div
                                                  className={
                                                    'color-box ' +
                                                    value
                                                      .replace(/,/g, '')
                                                      .replace(/ /g, '-')
                                                      .replace(/\//g, '-')
                                                      .toLowerCase()
                                                  }
                                                ></div>
                                                <span>{value}</span>
                                              </div>
                                            </Col>
                                          );
                                        })}
                                      </Row>
                                    ) : (
                                      <div className="options">
                                        {item.values.map((value) => {
                                          return (
                                            <div
                                              onClick={() => {
                                                if (
                                                  !params['attrs.' + item.key]
                                                ) {
                                                  this.props[0].history.push(
                                                    generateSearchLinkMultiple(
                                                      this.props[0].location
                                                        .search,
                                                      { page: 0 },
                                                      [
                                                        {
                                                          name:
                                                            'attrs.' +
                                                            item.key,
                                                          value: value.key,
                                                        },
                                                        {
                                                          name: 'page',
                                                          value: 0,
                                                        },
                                                      ],
                                                      false,
                                                    ),
                                                  );
                                                } else {
                                                  let paramValue =
                                                    params[
                                                    'attrs.' + item.key
                                                    ];
                                                  let splitted =
                                                    paramValue.split('||');
                                                  if (
                                                    splitted.indexOf(
                                                      String(value.key),
                                                    ) !== -1
                                                  ) {
                                                    if (splitted.length > 1) {
                                                      splitted.splice(
                                                        splitted.indexOf(
                                                          String(value.key),
                                                        ),
                                                        1,
                                                      );
                                                      this.props[0].history.push(
                                                        generateSearchLinkMultiple(
                                                          this.props[0]
                                                            .location.search,
                                                          { page: 0 },
                                                          [
                                                            {
                                                              name:
                                                                'attrs.' +
                                                                item.key,
                                                              value:
                                                                splitted.join(
                                                                  '||',
                                                                ),
                                                            },
                                                            {
                                                              name: 'page',
                                                              value: 0,
                                                            },
                                                          ],
                                                          false,
                                                        ),
                                                      );
                                                    } else {
                                                      this.props[0].history.push(
                                                        generateSearchLinkMultiple(
                                                          this.props[0]
                                                            .location.search,
                                                          { page: 0 },
                                                          [
                                                            {
                                                              name:
                                                                'attrs.' +
                                                                item.key,
                                                              value: null,
                                                            },
                                                            {
                                                              name: 'page',
                                                              value: 0,
                                                            },
                                                          ],
                                                          false,
                                                        ),
                                                      );
                                                    }
                                                  } else {
                                                    splitted.push(value.key);
                                                    this.props[0].history.push(
                                                      generateSearchLinkMultiple(
                                                        this.props[0].location
                                                          .search,
                                                        { page: 0 },
                                                        [
                                                          {
                                                            name:
                                                              'attrs.' +
                                                              item.key,
                                                            value:
                                                              splitted.join(
                                                                '||',
                                                              ),
                                                          },
                                                          {
                                                            name: 'page',
                                                            value: 0,
                                                          },
                                                        ],
                                                        false,
                                                      ),
                                                    );
                                                  }
                                                }
                                              }}
                                            >
                                              <div
                                                className={`filter-checkbox ${params[
                                                  'attrs.' + item.key
                                                ] &&
                                                  params['attrs.' + item.key]
                                                    .split('||')
                                                    .indexOf(
                                                      String(value.key),
                                                    ) !== -1
                                                  ? 'checked'
                                                  : ''
                                                  }`}
                                              ></div>{' '}
                                              {value
                                                ? value[this.props.lang]
                                                : null}{' '}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </li>
                            );
                        })
                        : null}
                    </ul>
                  </div>
                </Col>
              ) : null}
              <Col
                lg={this.state.showFilters ? 10 : 12}
                className={
                  this.state.showFilters ? 'articles-with-filters' : ''
                }
              >
                <Row>
                  {this.state.category &&
                    !this.state.category.brandCategory &&
                    this.state.categoryChain.length &&
                    Object.translate(
                      this.state.categoryChain[
                      this.state.categoryChain.length - 1
                      ],
                      'description',
                      this.props.lang,
                    ) ? (
                    <Col lg="3" className="category-description">
                      <div>
                        <h3>
                          {this.state.categoryChain.length
                            ? Object.translate(
                              this.state.categoryChain[
                              this.state.categoryChain.length - 1
                              ],
                              'name',
                              this.props.lang,
                            )
                            : null}
                        </h3>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.categoryChain.length
                              ? Object.translate(
                                this.state.categoryChain[
                                this.state.categoryChain.length - 1
                                ],
                                'description',
                                this.props.lang,
                              )
                              : null,
                          }}
                        ></div>
                      </div>
                    </Col>
                  ) : null}
                  {this.state.pages.map((page, pidx) => {
                    if (page && page.length && ((this.props[0].location.pathname.indexOf('/products') !== -1 || pidx <= params.page)  )) {
                      return page.map((item, idx) => {
                        if (
                          typeof window !== 'undefined' &&
                          String(localStorage.getItem('guestToken')).indexOf(
                            'retrack-',
                          ) === -1
                        ) {
                          if (
                            !this.props.uData &&
                            item.price &&
                            this.props[0].location.pathname.indexOf('/sale') ==
                            -1
                          ) {
                            item.price = null;
                          }
                        }
                        return (
                          <Col lg="3" key={`${pidx}${idx}${item.sku}`}>
                            <Article
                              wishlistDict={this.props.wishlistDict}
                              addToWishlist={this.props.addToWishlist}
                              pathname={this.props[0].location.pathname}
                              uData={this.props.uData}
                              lang={this.props.lang}
                              {...item}
                            />
                          </Col>
                        );
                      });
                    }
                  })}
                  <Col lg="12">
                    {!this.state.loadingProducts &&
                      this.state.total > 0 &&
                      (Number(params.page) || 0) + 1 <
                      Math.ceil(this.state.total / this.state.pageSize) ? (
                      <Waypoint
                        onEnter={() => {
                          if (this.props[0].location.pathname.indexOf('/products') !== -1){
                            return;
                          }

                          this.setState(
                            {
                              loadingProducts: true,
                            },
                            () => {
                              let currPage = params.page || 0;
                              currPage++;
                              if (
                                currPage >
                                this.state.total / this.state.pageSize
                              ) {
                                currPage = Math.ceil(
                                  this.state.total / this.state.pageSize,
                                );
                              }
                              this.props[0].history.push(
                                this.props[0].location.pathname +
                                generateSearchLink(
                                  this.props[0].location.search,
                                  { page: 0 },
                                  'page',
                                  currPage,
                                  false,
                                ),
                              );
                            },
                          );
                        }}
                      />
                    ) : null}
                    <div className="spinner-wrapper">
                      {this.state.loadingProducts ? (
                        <div className="lds-spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <button
                    className="scroll-top"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {'TOPP'.translate(this.props.lang)}
                  </button>
                  {/*this.state.total > 0 ?
                                    <Col lg="12">
                                        <div className='pagination'>
                                            <span>{'VISAR SIDA'.translate(this.props.lang)}</span>
                                            <div>
                                                <button className='prev' onClick={() => {
                                                    let value = this.state._page || ((this.state._page !== '' && this.state._page !== 0) && params.page ? params.page : this.state._page);
                                                    if (!value) {
                                                        value = 1;
                                                    }
                                                    value = Number(value) - 1;
                                                    if (value > this.state.total / this.state.pageSize) {
                                                        value = Math.ceil(this.state.total / this.state.pageSize);
                                                    }
                                                    if (value < 1) {
                                                        value = 1;
                                                    }

                                                    this.setState({
                                                        _page: value
                                                    }, () => {
                                                        this.props[0].history.push(this.props[0].location.pathname + generateSearchLink(this.props[0].location.search, { page: 0 }, 'page', this.state._page - 1, false));

                                                    })

                                                }}></button>
                                                <input type="text" value={this.state._page} onKeyUp={(e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                                        this.props[0].history.push(this.props[0].location.pathname + generateSearchLink(this.props[0].location.search, { page: 0 }, 'page', this.state._page - 1, false));
                                                    }
                                                }} onChange={(e) => {
                                                    let value = e.target.value.replace(/[^\d]/g, '')
                                                    if (value == '') {
                                                        this.setState({
                                                            _page: value
                                                        })
                                                        return;
                                                    }
                                                    value = Number(value);
                                                    if (value > this.state.total / this.state.pageSize) {
                                                        value = Math.ceil(this.state.total / this.state.pageSize);
                                                    }
                                                    if (value < 1) {
                                                        value = 1;
                                                    }

                                                    this.setState({
                                                        _page: value
                                                    })
                                                }}></input>
                                                <button className='next' onClick={() => {
                                                    let value = this.state._page || ((this.state._page !== '' && this.state._page !== 0) && params.page ? params.page : this.state._page);
                                                    if (!value) {
                                                        value = 1;
                                                    }
                                                    value = Number(value) + 1;
                                                    if (value > this.state.total / this.state.pageSize) {
                                                        value = Math.ceil(this.state.total / this.state.pageSize);
                                                    }
                                                    if (value < 1) {
                                                        value = 1;
                                                    }

                                                    this.setState({
                                                        _page: value
                                                    }, () => {
                                                        this.props[0].history.push(this.props[0].location.pathname + generateSearchLink(this.props[0].location.search, { page: 0 }, 'page', this.state._page - 1, false));

                                                    })

                                                }}></button>
                                            </div>
                                            <span>{'AV'.translate(this.props.lang)} {Math.ceil(this.state.total / this.state.pageSize)}</span>

                                        </div>
                                      
                                    </Col>
  :
  null*/}
                  {this.state.total == 0 &&
                    this.props[0].location.pathname.indexOf('search') == -1 &&
                    Object.keys(params).filter(
                      (item) => item.indexOf('attrs') !== -1,
                    ).length == 0 &&
                    !this.state._loading ? (
                    <Col lg="12">
                      <h3 className="coming-soon-title">
                        {'COMING SOON'.translate(this.props.lang)}
                      </h3>
                    </Col>
                  ) : null}
                </Row>
              </Col>

              {/* {(Number(params.page) + 1) * 24 < this.state.total ?
                                <div className="more-button">
                                    <h6>{'Visar'.translate(this.props.lang)} {(Number(params.page) + 1) * 24} {'av'.translate(this.props.lang)} {this.state.total} {'produkter'.translate(this.props.lang)}</h6>
                                    <Link to={generateSearchLink(this.props[0].location.search, { page: 0 }, 'page', Number(params.page) + 1, false)} className="button-transparent">
                                        <div className="button-inner">
                                            {'VISA ALLA'.translate(this.props.lang)}
                                        </div>
                                        <div className="button-outer">
                                            {'VISA ALLA'.translate(this.props.lang)}
                                        </div>
                                    </Link>
                                </div>
                                :
                                null

                            } */}
            </Row>
          </Container>
        </section>

        {this.props[0].location.pathname.indexOf('/products') !== -1 ?
          <ArticleDetail {...this.props} get={this.get} setBreadcrumb={
            (breadcrumb) => {
              if (this.state.category) {
                return;
              }

              this.setState({
                articleBreadcrumb: breadcrumb
              }, () =>
                this.get()
              )
            }
          }></ArticleDetail>
          :
          null
        }

        <h2 className="section-3-title">
          {Object.translate(
            this.props,
            'homeData.section[3].title',
            this.props.lang,
          )}
        </h2>
        <div className="cta">
          {typeof window !== 'undefined' && window.innerWidth < 768 ? (
            <img
              src={
                  IMAGE_URL +
                Object.get(this.props, 'homeData.section[3].mobileImage')
              }
            />
          ) : (
            <img
              src={
                  IMAGE_URL +
                Object.get(this.props, 'homeData.section[3].image')
              }
            />
          )}
          <div className="cta-content">
            <h3>
              <span>
                {Object.translate(
                  this.props,
                  'homeData.section[3].text',
                  this.props.lang,
                )}
              </span>
            </h3>
            <Link
              to={Object.translate(
                this.props,
                'homeData.section[3].button.link',
                this.props.lang,
              )}
              className="button-clasic"
            >
              <div className="button-inner">
                {Object.translate(
                  this.props,
                  'homeData.section[3].button.text',
                  this.props.lang,
                )}
              </div>
              <div className="button-outer">
                {Object.translate(
                  this.props,
                  'homeData.section[3].button.text',
                  this.props.lang,
                )}
              </div>
            </Link>
          </div>
        </div>

        {
          this.props[0].location.pathname.indexOf('/sale') !== -1 && this.props[0].location.pathname.indexOf('/products') === -1 &&
            !this.state.saleModalClosed &&
            typeof window !== 'undefined' &&
            String(localStorage.getItem('guestToken')).indexOf('retrack-') ===
            -1 ? (
            <div className="sale-modal">
              <div
                className="sale-modal-overlay"
                onClick={() => this.setState({ saleModalClosed: true })}
              ></div>
              <div className="sale-modal-content">
                <div className="sale-modal-header">
                  <Isvg src={logoDark} />
                  <button
                    onClick={() => this.setState({ saleModalClosed: true })}
                  >
                    &times;
                  </button>
                </div>
                <img src={sale} />
                <h3>
                  {'VÄLKOMMEN TILL VENTURE DESIGNS SALE!'.translate(
                    this.props.lang,
                  )}
                </h3>
                <p>
                  {'Tack för att du besöker vår sale-sida, nu börjar en brilliant shoppingupplevelse där du kan fynda utvalda produkter till fantastiska priser.'.translate(
                    this.props.lang,
                  )}
                </p>
                <p>
                  <strong>
                    {'Produkterna du finner på den här sidan är utvalda produkter. Produkter utan priser på övriga sidor är ej tillgängliga för köp via vår hemsida utan hänvisas till köp hos någon av våra återförsäljare.'.translate(
                      this.props.lang,
                    )}
                  </strong>
                </p>
                <div className="buttons">
                  {this.props.lang != 'en' ? (
                    <button
                      onClick={() => this.setState({ saleModalClosed: true })}
                    >
                      {'FORTSÄTT HANDLA'.translate(this.props.lang)}
                    </button>
                  ) : null}
                  <Link to="/hitta-aterforsaljare">
                    <button
                      className="btn1"
                      onClick={() => this.setState({ saleModalClosed: true })}
                    >
                      {'HITTA VÅRA BUTIKER HÄR'.translate(this.props.lang)}
                    </button>{' '}
                  </Link>
                </div>
              </div>
            </div>
          ) : null
        }
      </div >
    );
  }
}

export default Page(ArticlePage);
