import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

import { API_ENDPOINT } from '../constants';
import ResetPasswordForm from '../components/forms/resetPasswordForm';

var striptags = require('striptags');

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  resetPassword = (data) => {
    console.log(data);

    fetch(API_ENDPOINT + '/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: this.props[0].match.params.uid,
        code: this.props[0].match.params.code,
        ...data,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        this.props[0].history.push('/account');
      });
  };

  render() {
    return (
      <div className="account-page">
        <Container fluid>
          <Row>
            <Col lg="6">
              <div className="account-container">
                <ResetPasswordForm onSubmit={this.resetPassword} />
                {this.state.loginError ? <p>{this.state.loginError}</p> : null}
              </div>
            </Col>
            {/* <Col lg="6">
                            <div className="account-container register-form-container">
                                <h3>{'REGISTRERA'.translate(this.props.lang)}</h3>
                                <RegisterForm onSubmit={this.register} />
                                {this.state.registerError ? <p>{this.state.registerError}</p> : null}

                            </div>
                        </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(ForgotPasswordPage);
