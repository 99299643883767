import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';
import {API_ENDPOINT, IMAGE_URL} from '../constants';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parent: null,
      categoryDrop: false,
      categoryIdx: null
    };
  }

  componentDidMount() {
    if (this.props.parent && this.props.parent._id) {
      this.setState({
        parent: this.props.parent,

      });
    }
  }

  render() {
    let isMobile = typeof window != 'undefined' && window.innerWidth < 768;

    return (
      <div className={`menu-v2 ${this.state.parent ? 'menu-sub-v2' : ''}`}>
        <Container fluid>
          <button
            className="close"
            onClick={() => this.props.handleMenu(false)}
          >
            &times;
          </button>
          <Row>
            <Col lg="12">
              <button
                className="prev"
                onClick={() => {
                  if (this.state.isSubItem) {
                    this.setState({
                      isSubItem: null,
                      parent: this.state.oldParent,
                      oldParent: null,
                    });
                  } else {
                    this.setState({
                      isSubItem: null,
                      parent: null,
                      oldParent: null,
                    });
                  }
                }}
              ></button>

              <h2
                onClick={() => {
                  if (this.state.isSubItem) {
                    this.setState({
                      isSubItem: null,
                      parent: this.state.oldParent,
                      oldParent: null,
                    });
                  } else {
                    if (this.state.parent) {
                      this.setState({
                        isSubItem: null,
                        parent: null,
                        oldParent: null,
                      });
                    } else {
                      this.props.handleMenu(false);
                    }
                  }
                }}
              >
                {'MENY'.translate(this.props.lang)}
              </h2>
            </Col>
          </Row>
          {!this.state.parent ? (
            <Row>
              <Col lg="3">
                <h3>{'PRODUKTER'.translate(this.props.lang)}</h3>
                <ul>
                  {this.props.rootCategories
                    ? this.props.rootCategories
                      .filter((item) => !item.parent && !item.brandCategory)
                      .map((item, idx) => {
                        if (idx < 2) {
                          return (
                            <li
                              key={idx}
                              className={idx < 2 ? 'arrow-right' : ''}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  this.setState({ parent: item })
                                }
                              >
                                {' '}
                                <div className="icon">
                                  <Isvg src={IMAGE_URL + item.icon} />
                                </div>{' '}
                                {Object.translate(
                                  item,
                                  'name',
                                  this.props.lang,
                                )}
                              </Link>
                            </li>
                          );
                        } else {
                          return (
                            <li key={idx}>
                              <Link
                                to={Object.translate(
                                  item,
                                  'breadcrumb',
                                  this.props.lang,
                                )}
                                onClick={() => this.props.handleMenu(false)}
                              >
                                {' '}
                                <div className="icon">
                                  <Isvg src={IMAGE_URL + item.icon} />
                                </div>{' '}
                                {Object.translate(
                                  item,
                                  'name',
                                  this.props.lang,
                                )}
                              </Link>
                            </li>
                          );
                        }
                      })
                    : null}
                </ul>
              </Col>
              <Col lg="3">
                <h3>{'VARUMÄRKEN'.translate(this.props.lang)}</h3>
                <ul className="links">
                  {this.props.rootCategories
                    ? this.props.rootCategories
                      .filter((item) => !item.parent && item.brandCategory)
                      .map((item, idx) => {
                        return (
                          <li key={idx}>
                            <Link
                              to={Object.translate(
                                item,
                                'breadcrumb',
                                this.props.lang,
                              )}
                              onClick={() => this.props.handleMenu(false)}
                            >
                              {Object.translate(
                                item,
                                'name',
                                this.props.lang,
                              )}
                            </Link>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </Col>
              <Col lg="3">
                <h3></h3>
                <ul className="links">
                  <li>
                    <Link
                      to={'/hitta-aterforsaljare'}
                      onClick={() => this.props.handleMenu(false)}
                    >
                      {'HITTA BUTIK'.translate(this.props.lang)}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/bli-aterforsaljare'}
                      onClick={() => this.props.handleMenu(false)}
                    >
                      {'BLI ÅTERFÖRSÄLJARE'.translate(this.props.lang)}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/vara-skotselrad'}
                      onClick={() => this.props.handleMenu(false)}
                    >
                      {'VÅRA SKÖTSELRÅD'.translate(this.props.lang)}
                    </Link>
                  </li>
                  {isMobile ? (
                    <>
                      <li>
                        <Link
                          to={'/faq'}
                          onClick={() => this.props.handleMenu(false)}
                        >
                          {'FAQ'.translate(this.props.lang)}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'/kontakt'}
                          onClick={() => this.props.handleMenu(false)}
                        >
                          {'KONTAKT'.translate(this.props.lang)}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'/account'}
                          onClick={() => this.props.handleMenu(false)}
                        >
                          {this.props.uData
                            ? this.props.uData.username
                            : 'Logga in'.translate(this.props.lang)}
                        </Link>
                      </li>
                    </>
                  ) : null}
                </ul>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="12">
                <h3>
                  {Object.translate(this.state.parent, 'name', this.props.lang)}
                </h3>
                <Link
                  to={Object.translate(
                    this.state.parent,
                    'breadcrumb',
                    this.props.lang,
                  )}
                  onClick={() => this.props.handleMenu(false)}
                >
                  {' '}
                  <h6>
                    {'ALLA'.translate(this.props.lang)}{' '}
                    {Object.translate(
                      this.state.parent,
                      'name',
                      this.props.lang,
                    )}
                  </h6>{' '}
                </Link>
              </Col>
              {this.state.parent.children.map((subItem, idx) => {
                return (
                  <div
                    className={`subcategory ${this.state.isSubItem ? 'sub-items' : ''
                      }`}
                    key={idx}
                  >
                    {isMobile &&
                      !this.state.isSubItem &&
                      subItem.children &&
                      subItem.children.length ? (
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            parent: subItem,
                            oldParent: this.state.parent,
                            isSubItem: true,
                          })
                        }
                      >
                        {' '}
                        <div className="icon">
                          <Isvg src={IMAGE_URL + subItem.icon} />
                        </div>{' '}
                        {Object.translate(subItem, 'name', this.props.lang)}
                      </Link>
                    ) : (
                      <Link
                        to={Object.translate(
                          subItem,
                          'breadcrumb',
                          this.props.lang,
                        )}
                        onClick={() => this.props.handleMenu(false)}
                      >
                        {' '}
                        <div className="icon">
                          <Isvg src={IMAGE_URL + subItem.icon} />
                        </div>{' '}
                        {Object.translate(subItem, 'name', this.props.lang)}
                      </Link>
                    )}
                    <ul className="links">
                      {subItem.children && subItem.children.length
                        ? subItem.children.map((item, idx) => {
                          return (
                            item.children ?
                              !item.children.length ?
                                <li key={idx}>
                                  <Link
                                    to={Object.translate(
                                      item,
                                      'breadcrumb',
                                      this.props.lang,
                                    )}
                                    onClick={() => this.props.handleMenu(false)}
                                  >
                                    {Object.translate(
                                      item,
                                      'name',
                                      this.props.lang,
                                    )}
                                  </Link>
                                </li> :
                                <>
                                  <li className={this.state.categoryDrop && this.state.categoryIdx === item._id ? 'arrow-up' : 'arrow-right'}>

                                    <div style={{cursor: 'pointer'}} className={'category-list'} onClick={() => { this.props[0].history.push(Object.translate(
                                                  item,
                                                  'breadcrumb',
                                                  this.props.lang,
                                                )); this.props.handleMenu(false);}}>
                                    
                                  

                                      {Object.translate(
                                        item,
                                        'name',
                                        this.props.lang,
                                      )}


                                    </div>
                                    <div style={{cursor: 'pointer'}}  className="arrow" onClick={() => this.setState({ categoryDrop: !this.state.categoryDrop, categoryIdx: item._id })}></div>

                                  </li >
                                  {
                                    this.state.categoryDrop ?
                                      item.children && item.children.map((childItem, idx) => {
                                        return (
                                          this.state.categoryIdx === childItem.parent ?
                                            <li key={idx + 1} className={'category-submenu'}>
                                              <Link
                                                to={Object.translate(
                                                  childItem,
                                                  'breadcrumb',
                                                  this.props.lang,
                                                )}
                                                onClick={() => this.props.handleMenu(false)}
                                              >
                                                -  {Object.translate(
                                                  childItem,
                                                  'name',
                                                  this.props.lang,
                                                )}
                                              </Link>

                                            </li> : null
                                        )
                                      })
                                      : null
                                  }
                                </>
                              :
                              <li key={idx}>
                                <Link
                                  to={Object.translate(
                                    item,
                                    'breadcrumb',
                                    this.props.lang,
                                  )}
                                  onClick={() => this.props.handleMenu(false)}
                                >
                                  {Object.translate(
                                    item,
                                    'name',
                                    this.props.lang,
                                  )}
                                </Link>
                              </li>
                          );
                        })
                        : null}
                    </ul>
                  </div>
                );
              })}
            </Row>
          )}
        </Container>
      </div >
    );
  }
}

export default Menu;
