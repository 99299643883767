import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import cookieImage from '../assets/images/cookie.jpg';

var striptags = require('striptags');

class CookiesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <section className="cookies-page">
          <Container fluid>
            <Row>
              {/* <Col lg="6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="cookie-content">
                                    <h2>Cookies </h2>
                                    <p>
                                        {'”Om du lämnar en kommentar på vår webbplats kan du välja att spara ditt namn, din e-postadress och webbplatsadress i cookie-filer. Detta är för din bekvämlighet för att du inte ska behöva fylla i dessa uppgifter igen nästa gång du skriver en kommentar. Dessa cookie-filer gäller i ett år.'.translate(this.props.lang)}
                                        <br /><br />
                                        {'Om du besöker vår inloggningssida kommer vi att sätta en tillfällig cookie för att undersöka om din webbläsare accepterar dem. Denna cookie innehåller inga personuppgifter och den försvinner när du stänger din webbläsare.'.translate(this.props.lang)}
                                        {'När du loggar in kommer vi dessutom att skapa flera cookie-filer för att spara information om din inloggning och dina val för utformning av skärmlayouten. Cookie-filer för inloggning gäller i två dagar och cookie-filer för layoutval gäller i ett år. Om du kryssar i ”Kom ihåg mig” kommer din cookie att finnas kvar i två veckor. Om du loggar ut från ditt konto kommer cookie-filerna för inloggning att tas bort.'.translate(this.props.lang)}
                                        <br /><br />
                                        {'Om du redigerar eller publicerar en artikel kommer en extra cookie-fil att sparas i din webbläsare. Denna cookie-fil innehåller inga personuppgifter utan anger endast inläggs-ID för den artikel du just redigerade. Den gäller under 1 dygn. Artiklar på denna webbplats kan innehålla inbäddat innehåll (exempelvis videoklipp, bilder, artiklar o.s.v.). Inbäddat innehåll från andra webbplatser beter sig precis på samma sätt som om besökaren har besökt den andra webbplatsen. Dessa webbplatser kan samla in uppgifter om dig, använda cookie-filer, bädda in ytterligare spårning från tredje part och övervaka din interaktion med sagda inbäddade innehåll, inklusive spårning av din interaktion med detta inbäddade innehåll om du har ett konto och är inloggad på webbplatsen i fråga.'.translate(this.props.lang)}
                                        <br /><br />
                                        {'För användare som registrerar sig på er webbplats sparar vi även de personuppgifter de anger i sin användarprofil. Alla användare kan se, redigera eller radera sina personuppgifter när som helst (med undantaget att de inte kan ändra sitt användarnamn). Även webbplatsens administratörer kan se och redigera denna information.”'.translate(this.props.lang)}
                                    </p>
                                </div>
                            </Col> */}
              <Col lg={{ size: 6, offset: 3 }}>
                <div className="cookie-content">
                  <h2>{'Allmänna villkor '.translate(this.props.lang)} </h2>
                  <h6>{'Allmänt'.translate(this.props.lang)}</h6>
                  <p>
                    {'Vi som tillhandahåller sajten www.venturedesign.se är Venture Design AB, org. nr 556787-4424, 352 46 Växjö, Sverige. För att kunna köpa produkt(er) eller registrera ett användarkonto hos oss måste du vara 18 år. Om du är under 18 år måste du ha målsmans godkännande, vi hänvisar till Föräldrabalken. Genom registrering av användarkonto hos oss, köp av produkt(er) ur vårt sortiment godkänner du våra vid var tid gällande Allmänna villkor och intygar därmed att de uppgifter som du lämnar om dig själv är riktiga. Vi förbehåller oss rätten att ändra dessa villkor utan att meddela dig på förhand om skäl för detta förekommer. Den version av villkoren som du godkänner vid din beställning gäller för ditt köp.'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <h6>{'Företagsinformation'.translate(this.props.lang)}</h6>
                  <h6>{'Telefon kundservice:'.translate(this.props.lang)}</h6>
                  <p>{'+46 470 55 40 00'.translate(this.props.lang)}</p>
                  <h6>{'Orderavdelning:'.translate(this.props.lang)}</h6>
                  <p>
                    {'customercare@venturedesign.se'.translate(this.props.lang)}
                  </p>
                  <h6>
                    {'Reklamationsavdelningen:'.translate(this.props.lang)}
                  </h6>
                  <p>
                    {'solution@venturedesign.se'.translate(this.props.lang)}
                  </p>

                  <h6>
                    {'Öppettider kundservice:'.translate(this.props.lang)}
                  </h6>
                  <p>
                    <strong>
                      {'Måndag till torsdag:'.translate(this.props.lang)}
                    </strong>{' '}
                    {'9:30 - 15:00.'.translate(this.props.lang)}
                    <br />
                    <strong>{'Fredag:'.translate(this.props.lang)}</strong>{' '}
                    {'10:00 - 14:00.'.translate(this.props.lang)}
                    <br />
                    <strong>
                      {'Helgdag:'.translate(this.props.lang)}
                    </strong>{' '}
                    {'Stängt.'.translate(this.props.lang)}
                  </p>

                  <h6>{'Besöksadress kontor:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Storgatan 68, 352 46 Växjö'.translate(this.props.lang)}
                  </p>

                  <h6>{'Organisationsnummer:'.translate(this.props.lang)}</h6>
                  <p>{'556787-4424'.translate(this.props.lang)}</p>

                  <h6>{'Priser & avgifter'.translate(this.props.lang)}</h6>
                  <p>
                    {'Våra priser och avgifter kan utifrån omständigheter som vi ej råder över komma att ändras, exempel på detta kan bero på ändrad moms, ändrade råvarupriser eller valutaförändringar. I alla priser ingår moms om 25 %. Ifall momsen ändras förbehåller vi oss rätten att justera våra avgifter och priser med motsvarande belopp.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Fraktavgifter'.translate(this.props.lang)}</h6>
                  <p>
                    {'Fri frakt gäller för normalpaket över nedan angivet belopp. För normalpaket med lägre ordervärde debiteras fraktavgift. För större eller tyngre varor debiteras fraktavgift för hemleverans av transportör. Vi erbjuder i många fall även tilläggstjänster för din bekvämlighet. I kassan visas vilka leveransalternativ och vilka leveranstider som erbjuds för just din beställning, baserat på typ av vara och var du bor. Se aktuella priser nedan.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Normalpaket för order över 599 SEK (efter avdrag för ev. rabatter)	0 SEK'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Normalpaket för order mellan 1-599 SEK (efter avdrag för ev. rabatter) 49,90 SEK'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Hemleverans normalpaket (tilläggsavgift)+ 95 SEK'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Fraktavgift för varor med större vikt eller volym - hemleverans till tomtgräns/ ytterport*545 SEK'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Inbärningshjälp vid hemleverans för varor med större vikt eller volym (tilläggsavgift)+ 350 SEK'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Returavgift 45 SEK'.translate(this.props.lang)}
                    <br />
                    {'Returfrakt för varor med större vikt eller volym (tilläggsavgift)	+ 195 SEK'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Avgift för outlösta paket 199 SEK'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Leverans sker till tomtgräns om du bor i villa och till fastighetens ytterport om du bor i lägenhet.'.translate(
                      this.props.lang,
                    )}
                    <br />
                  </p>

                  <h6>{'Betalsätt (Ekonomi)'.translate(this.props.lang)}</h6>
                  <p>
                    <i>
                      {'“I samband med din ansökan om betalning genom faktura eller konto hos oss använder vi ditt personnummer för att kontrollera din kreditstatus och din adress. Sedvanlig kreditprövning görs, och detta genererar en omfrågekopia. Detta brev kommer att skickas till dig från det kreditupplysningsföretag som vi samarbetar med. Vid order överstigande din tidigare beviljade limit kan en högre köpgräns beviljas. Vi erbjuder dessa betalsätt:”'.translate(
                        this.props.lang,
                      )}
                    </i>
                  </p>
                  <h6>{'Leveranssätt'.translate(this.props.lang)}</h6>
                  <p>
                    {'Leverans alternativen som erbjuds för din beställning visas i kassan. Leverans alternativen varierar beroende på vart ni bor samt vad ni har beställt. I kassan gör du dina val av vilken leveransmetod ni önskar att använda, att er adress är korrekt samt en säkerställning av att dina kontaktuppgifter stämmer innan ni godkänner ordern, detta då det ej går att korrigera dessa uppgifter när er order är beställd. Ifall er beställning delas upp i flera leveranser kommer en fraktavgift för varje leverans att debiteras. Vi har följande leveranssätt:'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>
                    {'Service point'.translate(this.props.lang)} <br />{' '}
                    {'Home delivery'.translate(this.props.lang)}
                  </h6>

                  <h6>{'Ångerrätt'.translate(this.props.lang)}</h6>
                  <p>
                    {'Enligt gällande lagstiftning har du 14 dagars ångerrätt. Ångerrätten är giltig i 14 dagar från den dag du tog emot din vara, eller ifall leverans av varor vid separata tillfällen får den sista varan i en beställning. Med enstaka undantag har du  i den utsträckning som är nödvändig för att fastställa varans egenskaper och funktion rätt till att öppna förpackningen och prova/undersöka den vara du har köpt. Ångerrätten är giltig på samtliga av våra varor'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />
                    {'Inom 14 dagar ska du, ifall du vill nyttja din ångerrätt, meddela oss detta inom 14 dagar. Om tidsfristen löper ut på en lördag, söndag eller helgdag, förlängs den till därpå följande vardag.  Efter detta har du 14 dagar på dig att skicka tillbaka din beställning till oss. För instruktioner om hur ni nyttjar er ångerrätt kan ni finna denna information under “ Så här returnerar du” på våran hemsida. *LÄNK* '.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />

                    {'Din vara ska vara oanvänd med alla etiketter samt emballage i ursprungligt skick när din retur görs.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />

                    {'Du behöver vid tagen kontakt med oss vara tydlig med att ni vill utnyttja din ångerrätt och för att undvika fel och förseningar önskar vi att ni anger: Ordernummer, kundnummer samt annan relevant information i mailet ni inkommer till oss med. Vid kontakt till oss via mail får ni en bekräftelse på att ert mail är mottaget. Observera att det inte räknas som att ni utnyttjar ångerrätten om du endast avstår från att hämta ut er leverans, utan att ge oss ett tydligt meddelande om detta. Din leverans skickas automatiskt tillbaka till oss efter angiven tid, men du debiteras då en outlöstavgift på 199 SEK. Vid retur debiteras alltid en returavgift på 45 SEK per paket och vid retur av större/tyngre varor debiteras även returfrakt på 195 SEK per paket.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />
                    {'Återbetalning görs så snart som möjligt och senast inom 14 dagar från att vi mottagit ditt meddelande om att du vill utnyttja din ångerrätt. Tills dess att vi har mottagit din retur har vi rätt att avvakta med återbetalningen till dess att din retur inkommit till vårt lager. Om din hantering av varan inneburit en värdeminskning av densamma eller vid saknad eller förstörd originalkartong kan ett avdrag för värdeminskning göras, avdraget bedöms från fall till fall. Vid återbetalning kommer detta att ske via samma betalningssätt som du använde vid tillfället av beställningen. Vid återbetalning via ert bankkort tar det några dagar extra innan du ser pengarna på ditt konto p.g.a. bankens hanteringstid.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Returrätt'.translate(this.props.lang)}</h6>
                  <p>
                    {'Din returrätt gäller upp till 30 dagar när du handlar av oss, returrätten gäller alla våra artiklar som du finner på vår hemsida. Vissa undantag finns där du har rätt att öppna förpackningen och prova/undersöka varan du köpt, det gäller endast i de fall där du behöver kontrollera varans funktion/egenskaper.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />
                    {'Returrätten gäller i 30 dagar från den dagen du mottog din vara. Har din leverans dellevererats gäller det från att du mottog din sista vara. Vänligen returnera din oanvända vara inom 30 dagar i originalförpackningen, om förpackningen är obrukbar går det bra att emballera med emballage som är likvärdigt originalemballaget.  Emballera varan noga så det är skyddat under transporten.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />

                    {'Återbetalning för din retur kommer återbetalas så fort vi mottar returen och den har behandlats, detta brukar ta ca 3-4 veckor efter den dagen du skickade det i retur till oss.'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <h6>{'Såhär returnerar du:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Gå in på vår hemsida och logga in på ”Mina sidor.” Här finner du dina ordrar och all information kring vilka artiklar som du beställt.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />
                    {'Gäller detta en retur som avser ett ångerköp vänligen inkom med ett mail till Customercare@venturedesign.se med nedan info så återkommer vi med retursedel:'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <ul>
                      <li>{'Ordernummer'.translate(this.props.lang)}</li>
                      <li>{'Artikelnummer'.translate(this.props.lang)}</li>
                      <li>{'Antal artiklar'.translate(this.props.lang)}</li>
                      <li>
                        {'Anledning till att du önskar returnera.'.translate(
                          this.props.lang,
                        )}
                      </li>
                    </ul>
                    <br />
                    <br />
                    {'Vid retur kommer en returavgift debiteras på 150 sek per paket, vid retur av större/tyngre artiklar debiteras även en returfrakt på 350 sek per paket. Om din retur avser en reklamation eller att du mottagit fel vara kommer du inte debiteras för några avgifter.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <br />
                    {'Återbetalning görs så snart vi har mottagit returen och behandlat den, normalt tar detta ca 2-3 veckor från det datum du skickade returen. Om din hantering av varan inneburit en värdeminskning av densamma eller vid saknad eller förstörd originalkartong kan ett avdrag för värdeminskning göras, avdraget bedöms från fall till fall. Återbetalning sker via samma betalningssätt som du använde vid beställningen. Vid återbetalning via bankkort tar det några dagar extra innan du ser pengarna på ditt konto p.g.a. bankens hanteringstid.'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <h6>{'Outlösta paket'.translate(this.props.lang)}</h6>
                  <p>
                    {'Venture Design kommer debiterar en avgift på 200 SEK för ordrar som inte hämtas ut inom angiven tid. Avgiften motsvarar kostnaderna för frakt, retur samt hantering.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Reklamation:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Enligt konsumentköplagen så har du rätt att reklamera din artikel inom 3 år från det att du mottar din order, detta under förutsättningen att det är ett fabrikationsfel men detta behöver inte visa sig direkt. Du ska reklamera din vara inom ”skälig tid” vilket är ca. 2 månader från att du fått din artikel.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Information om hur du ska gå tillväga med en reklamation och vad vi på Ventures kundtjänst behöver finner du nedan:'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Maila till Solution@venturedesign.se med nedan info:'.translate(
                      this.props.lang,
                    )}
                    <br />
                    <ul>
                      <li>{'Ordernummer'.translate(this.props.lang)}</li>
                      <li>{'Artikelnummer'.translate(this.props.lang)}</li>
                      <li>{'Antal artiklar'.translate(this.props.lang)}</li>
                      <li>
                        {'Beskrivning på defekten på artikeln/artiklarna.'.translate(
                          this.props.lang,
                        )}
                      </li>
                      <li>
                        {'Bild som påvisar defekten på artikeln/artiklarna, gärna från olika vinklar.'.translate(
                          this.props.lang,
                        )}
                      </li>
                    </ul>
                    <br />
                    <br />
                    {'Efter att kundtjänst har fått in all information dem behöver för att kunna hjälpa er i ärendet kommer dem ge er olika lösningsförslag beroende på vad det är för fel/defekt. Du avsäger dig framtida reklamations- och returrätt för det specifika felet om du godkänner ett prisavdrag/kompensation. Väljer du att skicka din vara direkt i retur till oss utan att vi gör en bedömning på ditt ärende ber vi er att vända er till instruktionerna under “så här returnerar du” där du kommer fylla i den information som finns i formuläret.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Transportskada:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Om du mottar din leverans och det är en synlig transportskada på varan kommer chauffören göra en notering på fraktsedeln vid mottagande. Du måste efter detta genast anmäla skadan till oss, senast inom 3 dagar. För att upptäcka dolda och synliga fel är det viktigt att du packar upp varan/varorna och kontrollera dem. När du gör en skadeanmälan maila då in till Solution@venturedesign.se med information samt bilder där man tydligt ser att varan är defekt/transportskadad, gärna ur flera olika vinklar.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Återbetalning:'.translate(this.props.lang)}</h6>
                  <p>
                    {'När din återbetalning sker beror på av vilken anledning som återbetalning ska ske. om det är p.g.a. försenad leverans så kommer återbetalning ske inom 14 dagar. Gäller det vid en retur eller reklamation kommer du få en återbetalning så fort vi mottagit varan och den har hanteras. '.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Återbetalning sker via samma betalsätt som användes när beställningen gjordes. Vid faktura- eller kontoköp dras din retur/reklamation automatiskt av och du ser återbetalningen under "mina sidor" när du är inloggad, återbetalningen kommer även visas på din nästkommande faktura. Det skickas ingen uppdaterad faktura samma månad, du använder de inbetalningsuppgifter som finns på din befintliga faktura och justerar bara beloppet. Vid kortköp görs återbetalningen till samma betalkort som pengarna drogs ifrån, observera dock att det tar några dagar extra innan du ser dessa på ditt konto p.g.a. bankens hanteringstid. Om fakturan har betalts in innan returen behandlats och du önskar få ditt tillgodobelopp utbetalt , kontakta Resurs Bank på 033-435 75 00.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Elektronikgaranti:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Alla elektronik som vi säljer är det ett års garanti på. Din faktura/ följesedel är ditt garantibevis.'.translate(
                      this.props.lang,
                    )}
                    <br />
                    {'Garantin gäller inte om man har hanterat sin vara på ett felaktigt sätt, därav är det viktigt att man läser bruksanvisningen som medföljer för att säkerhetsställa att den har hanterats/installerats på korrekt sätt. '.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Tvister:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Om det blir en tvist som vi tillsammans inte lyckas lösa rekommenderar vi er att kontakta Allmänna reklamationsnämnden, www.arn.se eller box 174, 101 23 Stockholm. Vid tvister följer vi alltid deras rekommendationer. Du kan också vända dig till den onlineplattform som finns tillgänglig för klagomål på EU-kommissionens webbplats: ec.europa.eu/odr. Vilka rättigheter du har som konsument hittar du information om på www.konsumentverket.se. '.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Support:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Vårt mål att alltid kunna besvara frågor via e-post inom 24 timmar från det att mailet inkommit till oss, tiden gäller endast under vardagar. Under högsäsong kan det förekomma längre svarstider då vi kommer vara hårt belastade, detta gäller perioden maj-augusti.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Bedrägeri:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Alla bedrägeri/dataintrång kommer polisanmälas och även vid misstanke av detta. Vi har rätten att neka eller häva ditt köp om vi misstänker ett bedrägeri, dataintrång eller annan typ av missbruk på vår hemsida. '.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Färgskiftningar etc:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Då individuella skärminställningar och bildskärmar kan påverka färgnyanserna på artiklarna samt hur bilder på vår hemsida jämfört med hur det ser ut i verkligheten kan skifta. Vi reserverar oss för eventuella tryck- och skrivfel.'.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Förskott:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Skicka inga förskott, vi tar inte ansvar om du väljer att skicka checkar, pengar eller frimärken som skickas i brev. '.translate(
                      this.props.lang,
                    )}
                  </p>

                  <h6>{'Force majoure:'.translate(this.props.lang)}</h6>
                  <p>
                    {'Venture design är befriade från påföljd för underlåtenhet att fullgöra viss förpliktelser i detta avtal om underlåtenheten har sin grund i befriande omständigheter enligt nedan och omständigheten försvårar, förhindrar eller försenar fullgörandet. Befriande omständigheter är bland annat åtgärder eller underlåtenhet från myndighet, ny eller ändrad lagstiftning, konflikt på arbetsmarknaden, blockad, brand, översvämning, sabotage eller olyckshändelse av större omfattning.  I force majeure ingår även myndigheters beslut som påverkar marknad och produkter negativt, t ex restriktioner, varningar, försäljningsförbud etc.'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <h6>
                    {'Dessa villkor uppdaterades: 2022-12-20'.translate(
                      this.props.lang,
                    )}
                  </h6>

                  <h6>Klarna</h6>
                  <p>
                    För att kunna erbjuda dig Klarnas betalmetoder kan vi i
                    kassan komma att dela dina personuppgifter i form av
                    kontaktuppgifter och orderinformation med Klarna, för att
                    Klarna ska kunna bedöma om du kan välja betalmetoderna, samt
                    för att anpassa dem för dig. Dina personuppgifter som delas
                    behandlas enligt{' '}
                    <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/sv_se/privacy">
                      Klarnas egen dataskyddsinformation.
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="about-footer"></div>
      </div>
    );
  }
}

export default Page(CookiesPage);
