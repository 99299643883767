import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import miniLogo from '../assets/svg/mini-logo.svg';

import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import Article from '../components/article';
import articleTest from '../assets/images/article-test.jpg';
import a1 from '../assets/images/a1.jpg';
import a2 from '../assets/images/a2.jpg';
import a3 from '../assets/images/a3.jpg';
import s1 from '../assets/images/slider1.jpg';
import s2 from '../assets/images/slider2.jpg';
import s3 from '../assets/images/slider3.jpg';
import s4 from '../assets/images/slider4.jpg';
import s5 from '../assets/images/slider5.jpg';
import s6 from '../assets/images/slider6.jpg';
import s7 from '../assets/images/slider7.jpg';
import { API_ENDPOINT } from '../constants';
import { Link } from 'react-router-dom';
import noImage from '../assets/images/no-image.png';
import pdfIcon from '../assets/svg/pdf.svg';
import view360 from '../assets/images/360.png';

import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import moment from 'moment';
import Product360 from '../components/product360';
var striptags = require('striptags');

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

class ArticleDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      filters: false,
      tab: 0,
      quantity: 1,
      photoIndex: 0,
      activeIndex: null,
    };
  }

  get = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          getSearchParams(this.props[0].location.search, {}),
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    this.get();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    return (
      <div className="product-360-page">
        {this.state.product &&
        ((this.props[0].match.params.orientation == 'horizontal' &&
          this.state.product.have360Horizontal) ||
          (this.props[0].match.params.orientation == 'vertical' &&
            this.state.product.have360Vertical)) ? (
          <div className="product-360-view">
            <Product360
              amount={12}
              imagePath={`${API_ENDPOINT}/assets/products/360-view/`}
              fileName={`${this.state.product.sku}/${this.props[0].match.params.orientation}/{index}.jpg`}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ArticleDetailPage;
