import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

import FFLoginForm from '../components/forms/ffLoginForm';
import FFRegisterForm from '../components/forms/ffRegisterForm';
import { API_ENDPOINT } from '../constants';
import bgImage from '../assets/images/fsc.png';

import logo from '../assets/svg/fsc-logo.svg';

import fsc1 from '../assets/svg/fsc1.svg';
import fsc2 from '../assets/svg/fsc2.svg';
import fsc3 from '../assets/svg/fsc3.svg';
import fsc4 from '../assets/svg/fsc4.svg';
import fsc5 from '../assets/svg/fsc5.svg';
import fsc6 from '../assets/svg/fsc6.svg';
import fsc7 from '../assets/svg/fsc7.svg';
import fsc8 from '../assets/svg/fsc8.svg';
import fsc9 from '../assets/svg/fsc9.svg';
import fsc10 from '../assets/svg/fsc10.svg';

var striptags = require('striptags');

class FSCPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  render() {
    return (
      <div className="fsc-page">
        <section className="test">
          <img src={bgImage} />
        </section>

        <Container fluid>
          <Row>
            <Col lg={{ size: 8, offset: 2 }} className="fsc-content">
              <h2>
                {'FSC- Forest Stewardship Council'.translate(this.props.lang)}
                <span>®</span>
              </h2>
              <p>
                {'Venture Design kan sedan sommaren 2022 stolt titulera sig som ett FSC-certifierat företag.'.translate(
                  this.props.lang,
                )}
              </p>
              <br />
              <p>
                {'FSC är en oberoende, internationell medlemsorganisation som verkar för ett miljöanpassat, socialt ansvarstagande och ekonomiskt livskraftigt bruk av världens skogar, genom sitt certifieringssystem FSC.'.translate(
                  this.props.lang,
                )}
              </p>
              <br />

              <p>
                {'Certifierade företag följer FSC:s regler (standarder) för skogsbruk och spårbarhet. FSC-märket gör att konsumenter och företag kan välja varor av trä som kommer från ett ansvarsfullt skogsbruk, det vill säga ett skogsbruk som tar hänsyn till människor och miljö.'.translate(
                  this.props.lang,
                )}
              </p>
              <br />

              <p>
                {'Skogen ger oss rent vatten, frisk luft och bidrar till att bromsa den globala uppvärmningen. Den ger mat, medicin och viktiga naturresurser. Ett ansvarsfullt skogsbruk ser till så att skogen också på lång sikt kan ge de här nyttigheterna.'.translate(
                  this.props.lang,
                )}
              </p>
              <br />

              <p>
                {'Vi kan erbjuda en del FSC-certifierade produkter i trä. Vill ni veta mer, ta då kontakt med er närmsta säljare.'.translate(
                  this.props.lang,
                )}
              </p>
              <Isvg src={logo} />
            </Col>
          </Row>
        </Container>
        <div className="fsc-white">
          <Container fluid>
            <Row>
              <Col lg="12" className="title">
                <h2>
                  {'10 ANLEDNINGAR ATT VÄLJA FSC'.translate(this.props.lang)}
                  <span>®</span>
                </h2>
              </Col>
            </Row>
            <Row>
              <div>
                <Isvg src={fsc1} />
                <h6>
                  {'1. VI STYRS AV VÅRA MEDLEMMAR'.translate(this.props.lang)}
                </h6>
                <p>
                  {'Över 800 medlemmar utvecklar tillsammans våra regler för ansvarsfullt skogsbruk. Alla beslut fattas med likställt inflytande för miljö, sociala och ekonomiska intressen.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc2} />
                <h6>
                  {'2. VI HAR STARKAST STANDARDER '.translate(this.props.lang)}
                </h6>
                <p>
                  {'Vi ställer högre krav än andra certifieringssystem för skogsbruk. Företag och konsumenter kan litapå att FSC - certifierade skogar sköts på ett ansvarsfullt sätt.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc3} />
                <h6>
                  {'3. VI ÄR RESPEKTERADE OCH TROVÄRDIGA'.translate(
                    this.props.lang,
                  )}
                </h6>
                <p>
                  {'Vi är det certifieringssystem som många globala företag och varumärken föredrar, exempelvis IKEA, H&M och Tetra Pak.   '.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc4} />
                <h6>
                  {'4. VI INNS PÅ DEN GLOBALA MARKNADEN'.translate(
                    this.props.lang,
                  )}
                </h6>
                <p>
                  {'Vi finns i fler än 100 länder och märker fler produkter än något annat certifieringssystem för skog. '.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc5} />
                <h6>
                  {'5. VI HAR EN POSITIV INVERKAN'.translate(this.props.lang)}
                </h6>
                <p>
                  {'Vi skyddar hotade arter och värdefulla miljöer. Vi respekterar urfolkens rättigheter och kräver sunda och anständiga villkor för dem som arbetar i skogen.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>

              <div>
                <Isvg src={fsc6} />
                <h6>
                  {'6. VI STÖDS AV STARKA ORGANISATIONER'.translate(
                    this.props.lang,
                  )}
                </h6>
                <p>
                  {'Bland våra medlemmar finns några av världens största och mest respekterade miljöorga - nisationer , exempelvis Green - peace och WWF. De väljer FSC framför andra certifierings - system för skogsbruk.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc7} />
                <h6>{'7. VI ÄR TRANSPARENTA'.translate(this.props.lang)}</h6>
                <p>
                  {'Våra regler utvecklas i öppna processer, vi erbjuder insyn och inflytande. Att våra regler sedan följs kontrolleras av  oberoende parter.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc8} />
                <h6>
                  {'8. VÅRA STANDARDER GÄLLER LIKA I VÄRLDEN'.translate(
                    this.props.lang,
                  )}
                </h6>
                <p>
                  {'Våra regler för skogsbruk i Sverige bygger på FSC:s globala principer och kriterier. FSC - skogsbruk följer alltid de globala reglerna.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc9} />
                <h6>{'9. VI TÄNKER LOKALT'.translate(this.props.lang)}</h6>
                <p>
                  {'Vi ger människor möjlighet framföra synpunkter på skogsbruket i deras närhet.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
              <div>
                <Isvg src={fsc10} />
                <h6>{'10. VI ÄR ERKÄNDA'.translate(this.props.lang)}</h6>
                <p>
                  {'Svanen , Bra miljöval och LEED är exempel på märken som använder FSC i sina råvarukrav. FSC är det enda certifierings - systemet för skogsbruk som deltar i ISEAL Alliance, en global sammanslutningen för hållbar - hetsstandarder.'.translate(
                    this.props.lang,
                  )}
                </p>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Page(FSCPage);
