import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import homeBackgroundImage from '../assets/images/care-advice.jpg';
import homeBackgroundImageMobile from '../assets/images/care-advice-mobile.jpg';

import miniLogo from '../assets/svg/mini-logo.svg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class CareAdvicePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      items: [],
      activeIndex: null,
      category: 'Indoor',
    };
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    let params = getSearchParams(this.props[0].location.search, {});

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          params,
          this.props[0].location.pathname,
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;

    let params = getSearchParams(this.props[0].location.search, { page: 0 });

    let items = this.state.items.filter(
      (item) => item.category == this.state.category,
    );
    if (params.ids) {
      items = this.state.items.filter(
        (item) => params.ids.split(',').indexOf(item._id) !== -1,
      );
    }

    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;

    let params = getSearchParams(this.props[0].location.search, { page: 0 });

    let items = this.state.items.filter(
      (item) => item.category == this.state.category,
    );
    if (params.ids) {
      items = this.state.items.filter(
        (item) => params.ids.split(',').indexOf(item._id) !== -1,
      );
    }

    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    let params = getSearchParams(this.props[0].location.search, { page: 0 });

    let items = this.state.items.filter(
      (item) => item.category == this.state.category,
    );
    if (params.ids) {
      items = this.state.items.filter(
        (item) => params.ids.split(',').indexOf(item._id) !== -1,
      );
    }

    let texts = {
      Outdoor: {
        title: 'TA HAND OM DINA UTEMÖBLER PÅ RÄTT SÄTT!'.translate(
          this.props.lang,
        ),
        description:
          'Sol och värme lockar till lata dagar på balkongen och ute i trädgården. Trots att utemöblerna endast är i bruk några enstaka månader om året får de vara med om skiftande väder och vind, vilket kräver underhåll på rätt sätt. Genom att följa skötselråden för respektive material ger du dina utemöbler förutsättningarna för ett längre liv i solen. Observera att solblekt tyg inte anses vara en reklamation då tyg är ett material som bleks vid direkt solljus.'.translate(
            this.props.lang,
          ),
      },
      Indoor: {
        title: 'TA HAND OM DINA INNEMÖBLER PÅ RÄTT SÄTT!'.translate(
          this.props.lang,
        ),
        description:
          'För att dina möbler ska leva extra länge krävs det omvårdnad. Möblerna används dagligen i våra hem och får vara med om allt från förslitningar, spillda glas och fläckar. Genom att följa skötselråden för respektive material ger du dina innemöbler förutsättningar för ett längre liv där hemma. Läs bland annat hur du får bort svåra fläckar och hur du får möblerna att kännas som nya igen.'.translate(
            this.props.lang,
          ),
      },
    };

    return (
      <div>
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? homeBackgroundImageMobile
                : homeBackgroundImage
            }
          />
          <div className="test-content">
            <h1>{'VÅRA SKÖTSELRÅD'.translate(this.props.lang)}</h1>
          </div>
        </section>
        {!params.ids ? (
          <div className="filters">
            <Container fluid>
              <div className="filters-content">
                <div className="filters-left">
                  <div className="subcategories care-advice-subcategories">
                    <button
                      onClick={() => this.setState({ category: 'Indoor' })}
                      className="category-button"
                    >
                      {'Innemöbler'.translate(this.props.lang)}
                    </button>
                    <button
                      onClick={() => this.setState({ category: 'Outdoor' })}
                      className="category-button"
                    >
                      {'Utemöbler'.translate(this.props.lang)}
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        ) : null}

        <div className="catalog-container">
          <Container>
            <Row>
              <Col lg="12" className="care-advice-title">
                <h3>{texts[this.state.category].title}</h3>
                <p>{texts[this.state.category].description}</p>
              </Col>
              {items.map((item, idx) => {
                return (
                  <Col lg={2} xs={6}>
                    <div
                      className="care-advice-item"
                      onClick={() => this.setState({ activeIndex: idx })}
                    >
                      <div className="care-advice-item">
                        <img
                          src={
                            API_ENDPOINT.replace('testapi', 'api') + item.image
                          }
                        />
                        <h3>
                          {Object.translate(item, 'name', this.props.lang)}
                        </h3>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>

        {this.state.activeIndex !== null ? (
          <div className="care-advice-carousel">
            <Carousel
              activeIndex={this.state.activeIndex}
              next={this.next}
              previous={this.previous}
              autoPlay={false}
              interval={3600 * 1000}
            >
              {items.map((item, idx) => {
                return (
                  <CarouselItem
                    key={idx}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                  >
                    <div>
                      <div className="care-advice-box">
                        <div className="care-advice-media">
                          <img
                            src={
                              API_ENDPOINT.replace('testapi', 'api') +
                              item.image
                            }
                            style={{ height: item.video ? '70%' : '100%' }}
                          />
                          {item.video ? (
                            <video muted controls>
                              <soruce src={API_ENDPOINT + item.video}></soruce>
                            </video>
                          ) : null}
                        </div>
                        <div className="care-advice-detail">
                          <div className="care-advice-head">
                            <Isvg src={miniLogo} />
                            {'VÅRA SKÖTSELRÅD'.translate(this.props.lang)}
                          </div>
                          <div className="care-advice-text">
                            <h6>
                              {Object.translate(item, 'name', this.props.lang)}
                            </h6>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  item,
                                  'text',
                                  this.props.lang,
                                ).replace(/\n/g, '<br/>'),
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CarouselItem>
                );
              })}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
            <button
              className="care-advice-carousel-close"
              onClick={() => this.setState({ activeIndex: null })}
            >
              &times;
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Page(CareAdvicePage);
