import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';

import CartForm from '../components/forms/cartAddressForm';
import FactureForm from '../components/forms/factureForm';

import arrowRightIcon from '../assets/svg/arrow-right.svg';
import tagIcon from '../assets/svg/tag.svg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import noImage from '../assets/images/no-image.png';
import { API_ENDPOINT } from '../constants';
import Text from '../components/forms/fields/text';
import Check from '../components/forms/fields/check';
import moment from "moment/moment";

var striptags = require('striptags');
function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

class CartPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      items: [],
      servicePoints: [],
    };
  }

  get = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          getSearchParams(this.props[0].location.search, {}),
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  applyDiscountCode = () => {
    if (!this.state.discountCode) {
      return;
    }

    if (!this.state.discountCode.trim()) {
      return;
    }

    fetch(API_ENDPOINT + '/cart/discount/apply', {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('authToken')
          ? `Bearer ${localStorage.getItem('authToken')}`
          : localStorage.getItem('guestToken')
            ? `Guest ${localStorage.getItem('guestToken')}`
            : null,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        discountCode: this.state.discountCode,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        {
          console.log('res: ', result)
        }
        if (result.error) {
          this.setState({ discountError: result.error, discountCode: '' });
        } else {
          this.setState({
            discountError: '',
          });
          if (!this.props.uData) {
            this.get();
          } else {
            this.props.verifyUser(this.get);
          }
        }
      });
  };

  componentDidMount() {
    this.get();

    window.addEventListener('message', this.messageHandler);
  }

  messageHandler = (e) => {
    console.log(e.data);

    if (e && e.data && e.data.indexOf && e.data.indexOf('transaction') !== -1) {
      this.props[0].history.push(
        `/checkout?transaction=${e.data.replace('transaction=', '')}`,
      );
    }
  };

  searchServicePoints = () => {
    fetch(API_ENDPOINT + '/cart/service-points', {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('authToken')
          ? `Bearer ${localStorage.getItem('authToken')}`
          : localStorage.getItem('guestToken')
            ? `Guest ${localStorage.getItem('guestToken')}`
            : null,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        postalCode: this.state.postalCode,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.setState({
            servicePoints: result.items,
            servicePointsError: result.error,
          });

          return;
        }

        if (result.items) {
          this.setState({
            servicePoints: result.items,
            servicePointsError: '',
          });
        }
      });
  };

  initPayment = () => {

    this.setState(
      {
        payment:  !(this.props.uData && this.props.uData.briqPayData),
      },
      () => {
        fetch(API_ENDPOINT + '/cart/init-payment', {
          method: 'POST',
          headers: {
            Authorization: localStorage.getItem('authToken')
              ? `Bearer ${localStorage.getItem('authToken')}`
              : localStorage.getItem('guestToken')
                ? `Guest ${localStorage.getItem('guestToken')}`
                : null,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            billingData: this.state.billingData,
            shippingData: this.state.shippingData,
            deliveryDate: this.state.deliveryDate,
            orderReference: this.state.orderReference,
            deliverToDifferentAddress: this.state.deliverToDifferentAddress,
            partialDelivery: this.state.partialDelivery,
            freightMarks: this.state.freightMarks,
            servicePoint: this.state.servicePoint,
            registerForNewsletter: this.state.registerForNewsletter,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            console.log(result, 1111)
            //if (detectMob()){
            if(!result || result.error) {
              this.setState({payment: false})
              return
            }
            if(this.props.uData && this.props.uData.briqPayData) {
              //this.props[0].history.push('/briqpay-payment');
              this.props[0].history.push({
                pathname: '/briqpay/payment',
                search: `?order=${result.order_id}`,
              });
            } else {
              window.location.href = API_ENDPOINT + '/cart/payment-gateway/' + result.order_id
            }



            /*}else{
                    this.iframe.src = `data:text/html;charset=utf-8,` + escape(result.html_snippet)

                }*/
          }).catch((e) => {
          this.setState({payment: false})
        });
      },
    );
  };

  render() {
    return (
      <div className="cart-page">
        <Container fluid>
          <Row>
            <Col lg="12" style={{ textAlign: 'center' }}>
              <nav className="breadcrumbs">
                <Link to="/cart" className="current">
                  {'Varukorg'.translate(this.props.lang)}
                </Link>
                <span className="divider hide-for-small">
                  <i className="icon-angle-right"></i>
                </span>
                <Link to="#" className="hide-for-small">
                  {'Kassan'.translate(this.props.lang)}
                </Link>
                <span className="divider hide-for-small">
                  <i className="icon-angle-right"></i>
                </span>
                <Link to="#" className="no-click hide-for-small">
                  {'Ordern komplett'.translate(this.props.lang)}
                </Link>
              </nav>
            </Col>

            <Col lg="7">
              <table className="cart-table">
                <thead>
                  <tr style={{ borderBottom: '3px solid #ececec' }}>
                    <th className="product-name" colspan="3">
                      {'Produkt'.translate(this.props.lang)}
                    </th>
                    <th className="product-price">
                      {'Pris'.translate(this.props.lang)}
                    </th>
                    <th className="product-quantity">
                      {'Antal'.translate(this.props.lang)}
                    </th>
                    <th className="product-subtotal">
                      {'Delsumma'.translate(this.props.lang)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map((item, idx) => {
                    return (
                      <tr className="cart-item" key={idx}>
                        <td className="product-remove">
                          {item.sku == 'Presentkort' ? null : (
                            <button
                              className="remove"
                              onClick={() => {
                                this.props.addToCart(
                                  item._id,
                                  0,
                                  true,
                                  this.get,
                                  false,
                                  item,
                                  item.price.salesUnit
                                );
                              }}
                            >
                              ×
                            </button>
                          )}
                        </td>
                        <td className="product-thumbnail">
                          <Link
                            to={
                              item.sku == 'Presentkort'
                                ? ''
                                : `/products/${Object.translate(
                                  item,
                                  'alias',
                                  this.props.lang,
                                )}`
                            }
                          >
                            <img
                              src={
                                item.thumbnailExposed
                                  ? API_ENDPOINT +
                                  item.thumbnailExposed.thumbnail
                                  : item.thumbnailMood
                                    ? API_ENDPOINT + item.thumbnailMood.thumbnail
                                    : noImage
                              }
                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                              alt=""
                            />
                          </Link>
                        </td>
                        <td className="product-name">

                          <Link
                            to={
                              item.sku == 'Presentkort'
                                ? ''
                                : `/products/${Object.translate(
                                  item,
                                  'alias',
                                  this.props.lang,
                                )}`
                            }
                          >
                            {item.productFamily}{' '}
                            {Object.translate(item, 'name', this.props.lang)}
                          </Link>{' '}
                          - {item.price ? item.price.salesUnit + (item.price.salesUnit === 'FRP' ? `(${item.price.unitQuantity})` : '') : ''}
                          <div className="show-for-small mobile-product-price">
                            <span className="mobile-product-price__qty">
                              {item.quantity} x{' '}
                            </span>
                            <span className="amount">
                              <bdi>

                                {this.props.uData &&
                                  !this.props.uData.creditCardPayment
                                  ? Number(item.price.value).formatPrice(2)
                                  : Number(item.price.value *
                                  (1 + item.price.vat / 100)).formatPrice(2) }
                                &nbsp;<span>{item.price.currency}</span>


                              </bdi>
                            </span>

                          </div>

                          {this.props.uData && this.props.uData.briqPayData &&  this.props.uData.briqPayData.sessionId ?
                              <>
                                <br/>
                                <span>{
                                  item.inStock < 10 &&
                                  item.nextStock &&
                                  item.nextStock.value >= item.quantityStep &&
                                  item.nextStock.timestamp &&
                                  item.price ? (
                                      <p
                                          className="quantity-step-info"
                                          style={{fontSize: 14, fontWeight: 600}}
                                      >
                                        {'Beräknas åter i lager'.translate(this.props.lang)}{' '}
                                        {moment
                                            .unix(item.nextStock.timestamp)
                                            .format('YYYY-MM-DD')}

                                      </p>
                                  ) : null}</span>
                              </>
                              : null
                          }

                        </td>
                        <td className="product-price">
                          <span className="amount">
                            <bdi>
                            {
                              item.price.originalValue && item.price.originalValue != item.price.value ?
                                  <>
                                  <span style={{
                                    textDecoration: 'line-through', color: '#3A3C47',
                                    fontWeight: 300,
                                  }}>
                                                                {this.props.uData &&
                                                                !this.props.uData.creditCardPayment
                                                                    ? Number(item.price.originalValue).formatPrice(2)
                                                                    : Number(item.price.originalValue * (1 + item.price.vat / 100)).formatPrice(2)}
                              &nbsp;<span>{item.price.currency}</span>

                                  </span>
                                  <br/>

                                  </>
                                 
                                  :

                                  null
                                }
                              {this.props.uData &&
                                !this.props.uData.creditCardPayment
                                ? Number(item.price.value).formatPrice(2)
                                : Number(item.price.value * (1 + item.price.vat / 100)).formatPrice(2)}
                              &nbsp;<span>{item.price.currency}</span>


                            </bdi>
                          </span>
                        </td>
                        <td className="product-quantity">
                          <div className="quantity buttons_added">
                            <button
                              className="minus button is-form"
                              onClick={() => {
                                if (
                                  item.quantity -
                                  (item.quantityStep
                                    ? item.quantityStep
                                    : 1) >
                                  0
                                ) {
                                  this.props.addToCart(
                                    item._id,
                                    item.quantity -
                                    (item.quantityStep && item.price.salesUnit !== 'FRP'
                                      ? item.quantityStep
                                      : 1),
                                    true,
                                    this.get,
                                    false,
                                    item,
                                    item.price.salesUnit
                                  );
                                }
                              }}
                            >
                              -
                            </button>
                            <input
                              disabled
                              type="number"
                              className="input-text qty text"
                              step={item.quantityStep ? item.quantityStep : 1}
                              min="0"
                              value={item.quantity}
                              inputmode="numeric"
                              onChange={(e) => {
                                if (
                                  parseInt(e.target.value) > 0 &&
                                  (!item.quantityStep ||
                                    item.quantityStep == 1 ||
                                    e.target.value % item.quantityStep == 0)
                                )
                                  this.props.addToCart(
                                    item._id,
                                    parseInt(e.target.value),
                                    true,
                                    this.get,
                                    false,
                                    item,
                                    item.price.salesUnit
                                  );
                              }}
                            />
                            <button
                              className="plus button is-form"
                              onClick={() => {
                                this.props.addToCart(
                                  item._id,
                                  item.quantity +
                                  (item.quantityStep && item.price.salesUnit !== 'FRP' ? item.quantityStep : 1),
                                  true,
                                  this.get,
                                  false,
                                  item,
                                  item.price.salesUnit
                                );
                              }}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="product-subtotal">
                          <span className="amount">
                            <bdi>

                            {
                                  item.price.originalValue && item.price.originalValue != item.price.value ?
                                  <>
                                  <span style={{textDecoration: 'line-through',                              color: '#3A3C47',
                                  fontWeight: 300,
      }}>
                                                                {this.props.uData &&
                                !this.props.uData.creditCardPayment
                                ? Number(item.price.originalValue * item.quantity).formatPrice(2)
                                : Number(item.price.originalValue * item.quantity * (1 + item.price.vat / 100)).formatPrice(2)}
                              &nbsp;<span>{item.price.currency}</span>

                                  </span>
                                  <br/>

                                  </>
                                 
                                  :

                                  null
                                }

                              {parseFloat(
                                this.props.uData &&
                                  !this.props.uData.creditCardPayment
                                  ? item.price.value * item.quantity
                                  : item.price.value *
                                  item.quantity *
                                  (1 + item.price.vat / 100),
                              ).formatPrice(2)}
                              &nbsp;<span>{item.price.currency}</span>
                            </bdi>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colspan="6" className="actions clear">
                      <div className="continue-shopping pull-left text-left">
                        <a
                          className="button-continue-shopping button primary is-outline"
                          href="https://venturedesign.se/butik/"
                        ></a>
                      </div>
                      <button
                        type="submit"
                        className="button primary mt-0 pull-left small"
                        name="update_cart"
                        disabled=""
                        aria-disabled="true"
                        onClick={() => this.props[0].history.push('/')}
                      >
                        <span>
                          <Isvg
                            src={arrowRightIcon}
                            className="button-arrow-right"
                          />
                        </span>
                        {'FORTSÄTT HANDLA'.translate(this.props.lang)}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col lg="5">
              <div className="cart-total">
                <table cellSpacing="0">
                  <thead>
                    <tr>
                      <th
                        className="product-name"
                        colspan="2"
                        style={{ borderWidth: 3 }}
                      >
                        {'Varukorg totalbelopp'.translate(this.props.lang)}
                      </th>
                    </tr>
                  </thead>
                </table>
                <table cellSpacing="0">
                  <tbody>
                  <tr className="tax-total">
                    <th>{'Delsumma'.translate(this.props.lang)}</th>
                    <td data-title="Delsumma">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              {Number(this.state.subtotalWithoutDiscount).formatPrice(2)}&nbsp;
                              <span className="woocommerce-Price-currencySymbol">
                                {this.state.currency}
                              </span>
                            </bdi>
                          </span>
                    </td>
                  </tr>
                  {this.state.discountValue ? (
                      <>
                        <tr className="cart-subtotal">
                          <th>{'Rabatt'.translate(this.props.lang)} (-{this.state.discountValue}%)</th>
                          <td data-title="Rabatt">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>                            -{this.props.uData &&
                            !this.props.uData.creditCardPayment
                                ? parseFloat(this.state.subtotalWithoutDiscount - this.state.subtotal).formatPrice(2)
                                : parseFloat(
                                    this.state.subtotalWithoutDiscount - (this.state.subtotal - this.state.shippingCost),
                                ).formatPrice(2)}
                              <span className="woocommerce-Price-currencySymbol">
                              {this.state.currency}
                            </span>

</bdi>
                          </span>
                          </td>
                        </tr>
                        <tr className="tax-total">
                          <th>{'Delsumma'.translate(this.props.lang)}</th>
                          <td data-title="Delsumma">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              {Number(this.state.subtotal).formatPrice(2)}&nbsp;
                              <span className="woocommerce-Price-currencySymbol">
                                {this.state.currency}
                              </span>
                            </bdi>
                          </span>
                          </td>
                        </tr>
                      </>

                  ) : null}
                  {this.props.uData && !this.props.uData.creditCardPayment ? (
                      <tr className="tax-total">
                        <th>{'Moms'.translate(this.props.lang)}</th>
                        <td data-title="Moms">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              {Number(this.state.vat).formatPrice(2)}&nbsp;
                              <span className="woocommerce-Price-currencySymbol">
                                {this.state.currency}
                              </span>
                            </bdi>
                          </span>
                        </td>
                      </tr>
                  ) : null}
                  <tr className="cart-subtotal">
                    <th>{'Delsumma'.translate(this.props.lang)}</th>
                    <td data-title="Delsumma">
                        <span className="woocommerce-Price-amount amount">
                          <bdi>
                            {this.props.uData &&
                            !this.props.uData.creditCardPayment
                                ? parseFloat(this.state.total).formatPrice(2)
                                : parseFloat(
                                    this.state.total,
                                ).formatPrice(2)}
                            &nbsp;
                            <span className="woocommerce-Price-currencySymbol">
                              {this.state.currency}
                            </span>
                          </bdi>
                        </span>
                    </td>
                  </tr>


                  {
                    console.log('state: ', this.state)
                  }

                  {this.props.uData && !this.props.uData.creditCardPayment && !this.props.uData.briqPayData ? (
                      <tr class="woocommerce-shipping-totals shipping ">
                        <td class="shipping__inner" colspan="2">
                          <table class="shipping__table ">
                            <tbody>
                            <tr className="big-row">
                              <th style={{border: 'none'}}>
                                {'Frakt'.translate(this.props.lang)}
                              </th>
                              <td style={{border: 'none'}}>
                                <ul
                                    id="shipping_method"
                                    class="shipping__list woocommerce-shipping-methods"
                                >
                                  <li class="shipping__list_item">
                                    <label
                                        class="shipping__list_label"
                                        for="shipping_method_0_flat_rate1"
                                    >
                                      {'Beräknas på fakturan'.translate(
                                          this.props.lang,
                                      )}
                                    </label>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                  ) : this.state.shippingCost ||
                  this.state.shippingCost === 0 ? (
                      <tr className="tax-total">
                        <th>{'Frakt'.translate(this.props.lang)}</th>
                        <td data-title="Frakt">
                          <span className="woocommerce-Price-amount amount">
                            {this.state.shippingCost === 0 ? (
                                'fri frakt'.translate(this.props.lang)
                            ) : (
                                <bdi>
                                  {this.state.shippingCost}&nbsp;
                                  <span className="woocommerce-Price-currencySymbol">
                                  {this.state.currency}
                                </span>
                                </bdi>
                            )}
                          </span>
                        </td>
                      </tr>
                  ) : null}


                  <tr
                      className="order-total"
                      style={{borderBottom: '3px solid #ececec'}}
                  >
                    <th>{'Totalt'.translate(this.props.lang)}</th>
                    <td data-title="Summa">
                      <strong>
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              {parseFloat(this.state.total + this.state.shippingCost).formatPrice(2)}&nbsp;
                              <span className="woocommerce-Price-currencySymbol">
                                {this.state.currency}
                              </span>
                            </bdi>
                          </span>
                      </strong>{' '}
                    </td>
                  </tr>
                  {!this.props.uData ||
                  (this.props.uData && this.props.uData.creditCardPayment) ? (
                      <tr
                          className="tax-total"
                          style={{borderBottom: '3px solid #ececec'}}
                      >
                        <th>{'Varav moms'.translate(this.props.lang)}</th>
                        <td data-title="Moms">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              {parseFloat(this.state.total * 0.2).formatPrice(2)}
                              &nbsp;
                              <span className="woocommerce-Price-currencySymbol">
                                {this.state.currency}
                              </span>
                            </bdi>
                          </span>{' '}
                        </td>
                      </tr>
                  ) : null}
                  </tbody>
                </table>
                {!this.props.uData ||
                (this.props.uData && this.props.uData.creditCardPayment) ? (
                    <div className="cart-info">
                      <p style={{fontSize: 12, marginBottom: 20}}>
                        {'Ordervärde under 2000:-  / fraktavgift 49:-'.translate(
                            this.props.lang,
                        )}
                        <br/>
                        {'Ordervärde över 2000:- / fraktkostnad 349 :-'.translate(
                            this.props.lang,
                        )}
                      </p>
                      {/* {
                                                !this.props.uData ?
                                                    <Check label={'Registrera mig för nyhetsbrevet!'.translate(this.props.lang)} value={this.state.registerForNewsletter} onChange={(val) => this.setState({ registerForNewsletter: val })}></Check>
                                                    :
                                                    null
                                            } */}
                  </div>
                ) : null}

                {(!this.props.uData ||
                  (this.props.uData && this.props.uData.creditCardPayment)) &&
                  this.state.servicePointRequired ? (
                  <div className="service-points">
                    <h3>
                      {'Hitta närmaste service point'.translate(
                        this.props.lang,
                      )}
                    </h3>
                    <div className="service-points-search">
                      <label>{'Postnummer'.translate(this.props.lang)}</label>
                      <div>
                        <input
                          type="text"
                          placeholder={'Ange postnummer'.translate(
                            this.props.lang,
                          )}
                          value={this.state.postalCode}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              this.searchServicePoints();
                            }
                          }}
                          onChange={(e) =>
                            this.setState({ postalCode: e.target.value })
                          }
                        ></input>
                        <button
                          className="button"
                          onClick={this.searchServicePoints}
                        >
                          {'Search'.translate(this.props.lang)}
                        </button>
                      </div>
                      {this.state.servicePointsError ? (
                        <p className="error">
                          {this.state.servicePointsError.translate(
                            this.props.lang,
                          )}
                        </p>
                      ) : null}
                    </div>
                    <div className="service-points-items">
                      {this.state.servicePoints.map((item, idx) => {
                        return (
                          <div
                            className={
                              this.state.servicePoint &&
                                this.state.servicePoint.id == item.id
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              this.setState({
                                servicePoint: item,
                              });
                            }}
                          >
                            <h6>{item.name}</h6>
                            <p>
                              <span>{item.street}</span>, {item.postalCode}{' '}
                              {item.cityName}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}

                {this.state.items.length &&
                  this.props.uData &&
                  !this.props.uData.creditCardPayment &&
                  !(this.props.uData.briqPayData &&  this.props.uData.briqPayData.sessionId) &&
                  !this.props.uData.isVirtual ? (
                  <div className="proceed-to-checkout">
                                        {
                      this.state.total <= 0 ?
                      <>
                      <p style={{color: 'red', fontSize: 14, marginBottom: 15, textAlign: 'center',}}>{'För att kunna gå vidare till kassan måste saldot överstiga presentkortets värde.'.translate(this.props.lang)}</p>
                      <button
                      onClick={() => this.props[0].history.push('/')}
                      className="checkout-button button alt wc-forward"
                    >
                      {'KEEP SHOPPING'.translate(this.props.lang)}
                    </button>

                      </>

                      :

                    <Link
                      to="/checkout/"
                      className="checkout-button button alt wc-forward"
                    >
                      {'Fortsätt till kassan'.translate(this.props.lang)}
                    </Link>
  }
                  </div>
                ) : this.state.items.length &&
                  (!this.props.uData ||
                    (this.props.uData &&  !this.props.uData.isVirtual && (this.props.uData.creditCardPayment || this.props.uData.briqPayData))) &&
                  (!this.state.servicePointRequired ||
                    (this.state.servicePointRequired &&
                      this.state.servicePoint)) ? (
                  <div className="proceed-to-checkout">
                    {
                      this.state.total <= 0 ?
                      <>
                      <p style={{color: 'red', fontSize: 14, marginBottom: 15, textAlign: 'center',}}>{'För att kunna gå vidare till kassan måste saldot överstiga presentkortets värde.'.translate(this.props.lang)}</p>
                      <button
                      onClick={() => this.props[0].history.push('/')}
                      className="checkout-button button alt wc-forward"
                    >
                      {'KEEP SHOPPING'.translate(this.props.lang)}
                    </button>

                      </>

                      :

                      <button
                      onClick={this.initPayment}
                      className="checkout-button button alt wc-forward"
                    >
                      {'Fortsätt till kassan'.translate(this.props.lang)}
                    </button>

                      
                    }
                  </div>
                ) : null}
              </div>
              {true ||
                (this.props.uData && !this.props.uData.creditCardPayment) ? (
                <div className="checkout-coupon">
                  <div className="coupon">
                    <h3 className="widget-title">
                      <Isvg src={tagIcon} className="tag-icon" />{' '}
                      {'Rabattkod'.translate(this.props.lang)}
                    </h3>
                    <input
                      type="text"
                      className="input-text"
                      disabled={this.state.usedDiscountCode}
                      value={
                        this.state.usedDiscountCode
                          ? this.state.usedDiscountCode
                          : this.state.discountCode
                      }
                      onChange={(e) =>
                        this.setState({ discountCode: e.target.value })
                      }
                      placeholder="Rabattkod"
                    />
                    {!this.state.usedDiscountCode ? (
                      <button
                        className={`is-form expand ${this.state.discountCode ? 'active' : ''
                          }`}
                        onClick={this.applyDiscountCode}
                      >
                        {'Använd rabattkod'.translate(this.props.lang)}
                      </button>
                    ) : null}
                    {this.state.discountError ? (
                      <p style={{ fontSize: 12, color: 'red' }}>
                        {this.state.discountError.translate(this.props.lang)}
                      </p>
                    ) : null}
                    {this.state.discountCodeExpired ? (
                      <p style={{ fontSize: 12, color: 'red' }}>
                        {'Din kod har upphört att gälla. Kontakta personen du fick koden frän.'.translate(
                          this.props.lang,
                        )}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
        {this.state.payment ? (
          <div className="klarna-wrapper">
            <div
              className="overlay"
              onClick={() => this.setState({ payment: null })}
            ></div>
            <div className="iframe">
              <button onClick={() => this.setState({ payment: null })}>
                &times;
              </button>
              <iframe ref={(node) => (this.iframe = node)}></iframe>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Page(CartPage);
