import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import user from '../assets/images/user.png';

class AccountNav extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="account-nav-wrapper">
        {this.props.uData ? (
          <ul className="account-nav">
            <li
              className={
                this.props[0].location.pathname == '/account' ? 'active' : ''
              }
            >
              <Link to="/account">
                {'MINA SIDOR'.translate(this.props.lang)}
              </Link>
            </li>
            <li
              className={
                this.props[0].location.pathname == '/account/preview' ||
                this.props[0].location.pathname == '/account/edit'
                  ? 'active'
                  : ''
              }
            >
              <Link to="/account/preview">
                {'MINA UPPGIFTER'.translate(this.props.lang)}
              </Link>
            </li>
            <li
              className={
                this.props[0].location.pathname.indexOf(
                  '/account/edit-address',
                ) === 0
                  ? 'active'
                  : ''
              }
            >
              <Link to="/account/edit-address">
                {'MINA ADRESSER'.translate(this.props.lang)}
              </Link>
            </li>

            {this.props.uData && !this.props.uData.creditCardPayment ? (
              <li
                className={
                  this.props[0].location.pathname == '/account/terms-of-payment'
                    ? 'active'
                    : ''
                }
              >
                <Link to="/account/terms-of-payment">
                  {'BETALNINGSVILLKOR'.translate(this.props.lang)}
                </Link>
              </li>
            ) : null}

            <li
              className={
                this.props[0].location.pathname == '/account/orders'
                  ? 'active'
                  : ''
              }
            >
              <Link to="/account/orders">
                {'MINA ORDRAR'.translate(this.props.lang)}
              </Link>
            </li>
            <li
              className={
                this.props[0].location.pathname == '/account/wishlist'
                  ? 'active'
                  : ''
              }
            >
              <Link to="/account/wishlist">
                {'MINA FAVORITER'.translate(this.props.lang)}
              </Link>
            </li>
            {/* <li className={this.props[0].location.pathname == '/' ? 'active': ''}><Link to="/account">{'MINA REKLAMATIONER'.translate(this.props.lang)}</Link></li> */}

            {this.props.uData && !this.props.uData.creditCardPayment ? (
              <>
                <li>
                  <hr />
                </li>

                <li
                  className={
                    this.props[0].location.pathname == '/account/stock'
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/account/stock">
                    {'HUR FÅR JAG LAGERSTATUS?'.translate(this.props.lang)}
                  </Link>
                </li>
                <li
                  className={
                    this.props[0].location.pathname == '/account/product-data'
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/account/product-data">
                    {'HUR FÅR JAG PRODUKTDATA OCH BILDER?'.translate(
                      this.props.lang,
                    )}
                  </Link>
                </li>
                <li>
                  <hr />
                </li>
              </>
            ) : null}

            <li
              className={
                this.props[0].location.pathname == '/account/return-goods'
                  ? 'active'
                  : ''
              }
            >
              <Link to="/account/return-goods">
                {'RETURNERA VAROR'.translate(this.props.lang)}
              </Link>
            </li>
            <li
              className={
                this.props[0].location.pathname == '/account/complaint'
                  ? 'active'
                  : ''
              }
            >
              <Link to="/account/complaint">
                {'GÖR REKLAMATION'.translate(this.props.lang)}
              </Link>
            </li>
            <li
              className={
                this.props[0].location.pathname == '/account/change-password'
                  ? 'active'
                  : ''
              }
            >
              <Link to="/account/change-password">
                {'ÄNDRA  LÖSENORD'.translate(this.props.lang)}
              </Link>
            </li>
            <li style={{ cursor: 'pointer' }}>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.logOut()}
              >
                {'LOGGA UT'.translate(this.props.lang)}
              </a>
            </li>
          </ul>
        ) : (
          <ul className="account-nav">
            <li
              className={
                this.props[0].location.pathname == '/account' ? 'active' : ''
              }
            >
              {' '}
              <Link to="/account">{'LOGGA IN'.translate(this.props.lang)}</Link>
            </li>
            <li
              className={
                this.props[0].location.pathname == '/forgot-password'
                  ? 'active'
                  : ''
              }
            >
              <Link to="/forgot-password">
                {'ÅTERSTÄLL LÖSENORD'.translate(this.props.lang)}
              </Link>
            </li>
          </ul>
        )}
      </div>
    );

    return (
      <div className="profile">
        <div className="profile-header">
          <img src={user} />
          <span>
            {this.props.uData.userData && this.props.uData.userData.nickName
              ? this.props.uData.userData.nickName
              : this.props.uData.username}{' '}
            {this.props.uData.customerData && this.props.uData.customerData.id
              ? '#' + this.props.uData.customerData.id
              : null}
          </span>
        </div>
        <div className="profile-options">
          <ul className="profile-options-list">
            <li>
              <Link to="/account">
                {'Kontopanel'.translate(this.props.lang)}
              </Link>
            </li>
            <li>
              <Link to="/account/orders">
                {'Beställningar'.translate(this.props.lang)}
              </Link>
            </li>
            <li>
              <Link to="/account/edit-address">
                {'Adresser'.translate(this.props.lang)}
              </Link>
            </li>
            <li>
              <Link to="/account/edit">
                {'Kontouppgifter'.translate(this.props.lang)}
              </Link>
            </li>
            <li>
              <Link to="/account/invoices">
                {'Alla beställningar & fakturor'.translate(this.props.lang)}
              </Link>
            </li>
            <li>
              <a onClick={() => this.props.logOut()}>
                {'Logga ut'.translate(this.props.lang)}
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default AccountNav;
