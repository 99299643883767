import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="input-wrap">
        <label>{this.props.label}</label>
        <input
          disabled={this.props.disabled}
          className={this.props.error ? 'required' : null}
          type={this.props.type}
          onChange={(e) => {
            let value = e.target.value.replace(/[^\d]/g, '');
            this.props.onChange(value);
          }}
          value={this.props.value}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default Text;
