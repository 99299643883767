import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Isvg from 'react-inlinesvg';

import noImage from '../assets/images/no-image.png';
import wishlistIcon from '../assets/svg/wishlist.svg';
import wishlistSolidIcon from '../assets/svg/wishlist-solid.svg';

import dimensionsIcon from '../assets/svg/dimensions.svg';
import fscLogo from '../assets/images/fsc-logo.png';

import { IMAGE_URL} from '../constants';

class Article extends Component {
  constructor(props) {
    super(props);
    this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {};

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    if (this.props.socketIOClient) {
      this.registerSocketIOEvents();
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.socketIOClient) {
      this.registerSocketIOEvents();
    }
  }

  registerSocketIOEvents() {
    if (this.state._registeredEvents) return;

    this.setState({
      _registeredEvents: true,
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.updateWindowDimensions);

    if (!this.props.socketIOClient) return;
  }

  handleClickOutside(event) {
    /*
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
            })
        }
        */
  }

  render() {
    //Price after discount = Original Price - (Original price × Discount %)
    //  (Original price × Discount %) =  Original Price - Price after discount
    //  Discount % =  (Original Price - Price after discount) / Original Price

    return (
      <div key={this.props.key ? this.props.key : this.props._id}>
        <article className="article" ref={(node) => (this.node = node)}>
          <Link
            to={`/products/${Object.translate(
              this.props,
              'alias',
              this.props.lang,
            )}`}
          >
            <div className="product-labels">
              {this.props.tsCreated >=
                new Date().getTime() / 1000 - 180 * 24 * 60 * 60 ? (
                <div className="new-article">
                  {'NYHET!'.translate(this.props.lang)}
                </div>
              ) : null}
              {this.props.isBestSeller ? (
                <div className="best-seller">
                  {'BÄSTSÄLJARE'.translate(this.props.lang)}
                </div>
              ) : null}
            </div>

            {this.props.productDimensionsV2 &&
              this.props.productDimensionsV2.length &&
              this.props.pathname !== '/account/wishlist' ? (
              <div
                className="more-dimensions"
                style={
                  this.node
                    ? { top: this.node.offsetWidth / (3 / 2) - 100 }
                    : {}
                }
              >
                <Isvg src={dimensionsIcon} />
                <div className="more-dimensions-tooltip">
                  {'Several sizes'.translate(this.props.lang)}
                </div>
              </div>
            ) : null}

            {
              this.props.isFscCertified ?
                <img style={
                  this.node
                    ? {
                      top: (this.props.productDimensionsV2 &&
                        this.props.productDimensionsV2.length &&
                        this.props.pathname !== '/account/wishlist') ? this.node.offsetWidth / (3 / 2) - 160 : this.node.offsetWidth / (3 / 2) - 100
                    }
                    : {}
                } src={fscLogo} className={`fsc-certified ${this.props.productDimensionsV2 &&
                  this.props.productDimensionsV2.length &&
                  this.props.pathname !== '/account/wishlist' ? 'more-dimensions' : ''}`} />
                :
                null
            }

            {this.props.pathname !== '/account/wishlist' ? (
              <button
                className="wishlist-icon"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.addToWishlist(this.props._id);
                }}
                style={
                  this.node ? { top: this.node.offsetWidth / (3 / 2) - 50 } : {}
                }
              >
                {this.props.wishlistDict[this.props._id] ? (
                  <Isvg src={wishlistSolidIcon} />
                ) : (
                  <Isvg src={wishlistIcon} />
                )}
                <div className="wishlist-tooltip">
                  {'Add to my favorites'.translate(this.props.lang)}
                </div>
              </button>
            ) : (
              <button
                className="wishlist-remove"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.addToWishlist(this.props._id, true);
                }}
                style={this.node ? { top: 10 } : {}}
              >
                &times;
              </button>
            )}

            {this.state.preview ? (
              <div className="article-header">
                <img
                  key={this.props._id}
                  style={
                    this.node ? { height: this.node.offsetWidth / (3 / 2) } : {}
                  }
                  src={
                    this.props.thumbnailExposed
                      ? IMAGE_URL.replace('testapi', 'api') +
                      this.props.thumbnailExposed.thumbnail
                      : this.props.thumbnailMood
                        ? IMAGE_URL.replace('testapi', 'api') +
                        this.props.thumbnailMood.thumbnail
                        : noImage
                  }
                />
                <img
                  key={this.props._id}
                  style={
                    this.node ? { height: this.node.offsetWidth / (3 / 2) } : {}
                  }
                  src={this.state.preview}
                />
              </div>
            ) : this.props.thumbnailMood || this.props.thumbnailExposed ? (
              <div className="article-header">
                <img
                  key={this.props._id}
                  style={
                    this.node ? { height: this.node.offsetWidth / (3 / 2) } : {}
                  }
                  src={
                    this.props.thumbnailExposed
                      ? IMAGE_URL.replace('testapi', 'api') +
                      this.props.thumbnailExposed.thumbnail
                      : this.props.thumbnailMood
                        ? IMAGE_URL.replace('testapi', 'api') +
                        this.props.thumbnailMood.thumbnail
                        : noImage
                  }
                />
                <img
                  key={this.props._id}
                  style={
                    this.node ? { height: this.node.offsetWidth / (3 / 2) } : {}
                  }
                  src={
                    this.props.thumbnailMood
                      ? IMAGE_URL.replace('testapi', 'api') +
                      this.props.thumbnailMood.thumbnail
                      : this.props.thumbnailExposed
                        ? IMAGE_URL.replace('testapi', 'api') +
                        this.props.thumbnailExposed.thumbnail
                        : noImage
                  }
                />
              </div>
            ) : (
              <div className="article-header">
                <img
                  style={
                    this.node ? { height: this.node.offsetWidth / (3 / 2) } : {}
                  }
                  src={noImage}
                />
                <img
                  style={
                    this.node ? { height: this.node.offsetWidth / (3 / 2) } : {}
                  }
                  src={noImage}
                />
              </div>
            )}
          </Link>

          <div className="article-body">
            <Link
              to={Object.translate(
                this.props,
                'category.breadcrumb',
                this.props.lang,
              )}
            >
              {this.props.brand}
            </Link>
            <Link
              to={`/products/${Object.translate(
                this.props,
                'alias',
                this.props.lang,
              )}`}
            >
              <h4>
                {this.props.productFamily}{' '}
                <span>
                  {Object.translate(this.props, 'name', this.props.lang)}
                </span>
              </h4>
              <div className='d-flex justify-content-between'>
                {this.props.price ? (
                  <p className="price">
                    {this.props.uData && !this.props.uData.creditCardPayment
                      ? Number(this.props.price.value).formatPrice(2)
                      : Number(this.props.price.value *
                      (1 + this.props.price.vat / 100)).formatPrice(2)}{' '}
                    {this.props.price.currency}
                    {this.props.price.oldPrice && this.props.price.oldPrice > this.props.price.value &&
                      (this.props.uData && !this.props.uData.creditCardPayment ? this.props.price.oldPrice : this.props.price.oldPrice * (1 + this.props.price.vat / 100)) != (this.props.uData && !this.props.uData.creditCardPayment? this.props.price.value: this.props.price.value *(1 + this.props.price.vat / 100)) ? (
                      <>
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: '#3A3C47',
                            fontWeight: 300,
                            marginLeft: '5px'
                          }}
                        >
                          {this.props.uData && !this.props.uData.creditCardPayment
                            ? Number(this.props.price.oldPrice).formatPrice(2)
                            : Number(this.props.price.oldPrice *
                            (1 + this.props.price.vat / 100)).formatPrice(2) }{' '}
                          {this.props.price.currency}
                        </span>
                        <span style={{ marginLeft: '5px' }}>
                          -
                          {(
                            (((this.props.uData &&
                              !this.props.uData.creditCardPayment
                              ? this.props.price.oldPrice
                              : this.props.price.oldPrice *
                              (1 + this.props.price.vat / 100)) -
                              (this.props.uData &&
                                !this.props.uData.creditCardPayment
                                ? this.props.price.value
                                : this.props.price.value *
                                (1 + this.props.price.vat / 100))) /
                              (this.props.uData &&
                                !this.props.uData.creditCardPayment
                                ? this.props.price.oldPrice
                                : this.props.price.oldPrice *
                                (1 + this.props.price.vat / 100))) *
                            100
                          ).toFixed()}
                          %
                        </span>
                      </>
                    ) : null}
                  </p>
                ) : null}
                {
                  this.props.inStock && this.props.inStock > 0 && this.props.pathname != '/account/wishlist' ?
                    <p className='inStock'>
                      {
                        this.props.uData || (typeof window !== 'undefined' &&
                          String(localStorage.getItem('guestToken')).indexOf(
                            'retrack-',
                          ) !== -1) ?
                          <>
                            {this.props.inStock <= 100 && this.props.inStock > 0 ?
                              <span
                                style={{
                                  color: '#528499',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  display: 'flex'
                                }}>LAGER: {this.props.inStock}</span> : <span
                                  style={{
                                    color: '#528499',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    display: 'flex'
                                  }}>LAGER: 100+</span>}

                          </>
                          : null
                      }
                    </p> :
                    (this.props.uData || (typeof window !== 'undefined' &&
                      String(localStorage.getItem('guestToken')).indexOf(
                        'retrack-',
                      ) !== -1)) && this.props.pathname != '/account/wishlist' ? <>
                      {
                        this.props.inStock <= 0 && this.props.nextStock && this.props.nextStock.timestamp ?
                          <span style={{
                            color: '#528499',
                            fontWeight: 600,
                            fontSize: 14,
                            display: 'flex',
                            textTransform: 'none'
                          }}> {
                              this.props.nextStock.value > 0 ? moment
                                .unix(this.props.nextStock.timestamp)
                                .format('YYYY-MM-DD') :
                                'Temporarily out of stock'.translate(this.props.lang)
                            }</span> : <span style={{
                              color: '#528499',
                              fontWeight: 600,
                              fontSize: 14,
                              display: 'flex'
                            }}> {'Slut för säsongen'.translate(this.props.lang)}</span>}
                    </> : null
                }
              </div>

            </Link>

            <div className="type">
              {this.props.variations && this.props.variations.length
                ? this.props.variations.map((item, idx) => {
                  if (idx < 5)
                    return (
                      <Link
                        key={idx}
                        to={`/products/${Object.translate(
                          item,
                          'alias',
                          this.props.lang,
                        )}`}
                      >
                        <div
                          className="type-container"
                          onMouseEnter={() => {
                            if (item.thumbnailMood || item.thumbnailExposed)
                              this.setState({
                                preview:
                                    IMAGE_URL.replace('testapi', 'api') +
                                  (item.thumbnailMood
                                    ? item.thumbnailMood.thumbnail
                                    : item.item.thumbnailExposed),
                              });
                          }}
                          onMouseLeave={() =>
                            this.setState({ preview: null })
                          }
                        >
                          <img
                            src={
                              item.thumbnailExposed
                                ? IMAGE_URL.replace('testapi', 'api') +
                                item.thumbnailExposed.thumbnail
                                : noImage
                            }
                          />
                        </div>
                      </Link>
                    );
                })
                : null}
              {/* <div className="type-container">
                                    <img src={a1} />
                                </div>
                                <div className="type-container">
                                    <img src={a2} />
                                </div>
                                <div className="type-container">
                                    <img src={a3} />
                                </div> */}
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default Article;
