import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import homeBackgroundImage from '../assets/images/home.jpg';
import c1 from '../assets/images/c1.jpg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import {API_ENDPOINT, IMAGE_URL} from '../constants';

var striptags = require('striptags');

class CatalogPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      catalogs: [],
    };
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    let params = getSearchParams(this.props[0].location.search, {});

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          params,
          this.props[0].location.pathname,
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  render() {
    return (
      <div>
        <section className="test">
          <img src={homeBackgroundImage} />
          <div className="test-content">
            <h1>{'Våra produktkataloger'.translate(this.props.lang)}</h1>
            <h6>
              {'– här nedan finner du våra senaste produktkataloger för inomhus- och utomhusmöbler!'.translate(
                this.props.lang,
              )}
            </h6>
          </div>
        </section>

        <div className="catalog-container">
          <Container>
            {this.state.catalogs.map((item, idx) => {
              return (
                <a
                  href={API_ENDPOINT.replace('testapi', 'api') + item.file}
                  target="_blank"
                >
                  <div className="catalog">
                    <img
                      src={IMAGE_URL.replace('testapi', 'api') + item.image}
                    />
                    <h6>{Object.translate(item, 'name', this.props.lang)}</h6>
                    <p>
                      {Object.translate(
                        item,
                        'shortDescription',
                        this.props.lang,
                      )}
                    </p>
                  </div>
                </a>
              );
            })}
          </Container>
        </div>
      </div>
    );
  }
}

export default Page(CatalogPage);
