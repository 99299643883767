import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Page from '../containers/page';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';
import AccountNav from '../components/accountNav';

class AccountProductDataPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.initialData,
      controlPanel: true,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="account-page">
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? imageMobile
                : image
            }
          />
          <div className="test-content">
            <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
          </div>
        </section>

        <Container>
          <Row>
            <Col lg="4">
              <AccountNav {...this.props} />
            </Col>

            <Col lg="8">
              <div className="account-container">
                <h3>
                  {'HUR FÅR JAG PRODUKTDATA OCH BILDER?'.translate(
                    this.props.lang,
                  )}
                </h3>
                <p>
                  {'In the folder "Standardfil FTP" on our FTP, every day we put a file called "Standardfil.xlsx.'.translate(
                    this.props.lang,
                  )}
                </p>
                <p>
                  {'In that file you will find all the information about the products and links to their images.'.translate(
                    this.props.lang,
                  )}
                </p>
                <br />
                <p>
                  {'Our FTP access details are:'.translate(this.props.lang)}
                </p>
                <br />
                <p>{'Host: 162.55.247.33 '.translate(this.props.lang)}</p>
                <p>
                  {'Username: pim@venturedesign.se '.translate(this.props.lang)}
                </p>
                <p>{'Password: hR-I5S@k{xyO '.translate(this.props.lang)}</p>
                <p>{'Port: 21'.translate(this.props.lang)}</p>
                <br />
                <p>
                  {'If you have the possibility to get the product data and images through our API, please send us a request to this email address'.translate(
                    this.props.lang,
                  ) + ': '}
                  <a href="mailto:hanna@venturedesign.se">
                    hanna@venturedesign.se
                  </a>
                </p>
                <br />
                <p>
                  {'If you need individual images in high resolution, you can find them in our image bank at the following link:'.translate(
                    this.props.lang,
                  )}
                </p>
                <br />
                <a
                  href="https://bildbank.venturedesign.se/olr8jsa8dubxh4o37lv9oos9mjpvkhbdp8jcpo8uz0/"
                  target="_blank"
                >
                  https://bildbank.venturedesign.se/olr8jsa8dubxh4o37lv9oos9mjpvkhbdp8jcpo8uz0/
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(AccountProductDataPage);
