import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import cookieImage from '../assets/images/cookie.jpg';
import homeBackgroundImage from '../assets/images/care-advice.jpg';
import homeBackgroundImageMobile from '../assets/images/care-advice-mobile.jpg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class CookiesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  get = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          getSearchParams(this.props[0].location.search, {}),
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };
  componentDidMount() {
    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
      this.get();
    }
  }

  render() {
    return (
      <div>
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? API_ENDPOINT + this.state.mobileImage
                : API_ENDPOINT + this.state.image
            }
          />
          <div className="test-content">
            <h1>{Object.translate(this.state, 'title', this.props.lang)}</h1>
          </div>
        </section>

        <section className="cookies-page">
          <Container fluid>
            <Row>
              {/* <Col lg="6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="cookie-content">
                                    <h2>Cookies </h2>
                                    <p>
                                        {'”Om du lämnar en kommentar på vår webbplats kan du välja att spara ditt namn, din e-postadress och webbplatsadress i cookie-filer. Detta är för din bekvämlighet för att du inte ska behöva fylla i dessa uppgifter igen nästa gång du skriver en kommentar. Dessa cookie-filer gäller i ett år.'.translate(this.props.lang)}
                                        <br /><br />
                                        {'Om du besöker vår inloggningssida kommer vi att sätta en tillfällig cookie för att undersöka om din webbläsare accepterar dem. Denna cookie innehåller inga personuppgifter och den försvinner när du stänger din webbläsare.'.translate(this.props.lang)}
                                        {'När du loggar in kommer vi dessutom att skapa flera cookie-filer för att spara information om din inloggning och dina val för utformning av skärmlayouten. Cookie-filer för inloggning gäller i två dagar och cookie-filer för layoutval gäller i ett år. Om du kryssar i ”Kom ihåg mig” kommer din cookie att finnas kvar i två veckor. Om du loggar ut från ditt konto kommer cookie-filerna för inloggning att tas bort.'.translate(this.props.lang)}
                                        <br /><br />
                                        {'Om du redigerar eller publicerar en artikel kommer en extra cookie-fil att sparas i din webbläsare. Denna cookie-fil innehåller inga personuppgifter utan anger endast inläggs-ID för den artikel du just redigerade. Den gäller under 1 dygn. Artiklar på denna webbplats kan innehålla inbäddat innehåll (exempelvis videoklipp, bilder, artiklar o.s.v.). Inbäddat innehåll från andra webbplatser beter sig precis på samma sätt som om besökaren har besökt den andra webbplatsen. Dessa webbplatser kan samla in uppgifter om dig, använda cookie-filer, bädda in ytterligare spårning från tredje part och övervaka din interaktion med sagda inbäddade innehåll, inklusive spårning av din interaktion med detta inbäddade innehåll om du har ett konto och är inloggad på webbplatsen i fråga.'.translate(this.props.lang)}
                                        <br /><br />
                                        {'För användare som registrerar sig på er webbplats sparar vi även de personuppgifter de anger i sin användarprofil. Alla användare kan se, redigera eller radera sina personuppgifter när som helst (med undantaget att de inte kan ändra sitt användarnamn). Även webbplatsens administratörer kan se och redigera denna information.”'.translate(this.props.lang)}
                                    </p>
                                </div>
                            </Col> */}
              <Col lg={{ size: 6, offset: 3 }}>
                <div
                  className="cookie-content"
                  dangerouslySetInnerHTML={{
                    __html: Object.translate(
                      this.state,
                      'content',
                      this.props.lang,
                    ),
                  }}
                >
                  {/* <h2>{'SÅ HÄR RETURNERAR DU VAROR  '.translate(this.props.lang)} </h2>
                                    <h6>{'Gäller din retur ett ångerköp eller retur inom din returrätt gör följande:  '.translate(this.props.lang)}</h6>
                                    <p>{'Gå in på vår hemsida och logga in. Under ”MINA SIDOR” finner du dina ordrar och all information kring vilka artiklar som du beställt. Klicka på “RETURNERA VAROR” knappen i menyn, fyll i formuläret och skicka det till oss, så återkommer vi med retursedel.'.translate(this.props.lang)}</p>
                                    <p>{'Vid retur kommer en returavgift debiteras på 150 sek per paket.  Vid retur av större/tyngre artiklar debiteras även en returfrakt på 350 sek per paket.'.translate(this.props.lang)}</p>
                                    <p>{'Om din hantering av varan inneburit en värdeminskning av densamma eller vid saknad eller förstörd originalkartong kan ett avdrag för värdeminskning göras, avdraget bedöms från fall till fall. '.translate(this.props.lang)}</p>
                                    <p>{'Återbetalning görs så snart vi har mottagit returen och behandlat den, normalt tar detta ca 2-3 veckor från det datum du skickade returen. Återbetalning sker via samma betalningssätt som du använde vid beställningen. Vid återbetalning via bankkort tar det några dagar extra innan du ser pengarna på ditt konto p.g.a.bankens hanteringstid. '.translate(this.props.lang)}</p>

                                    <h6>{'Om din retur avser en reklamation eller att du mottagit fel vara gör följande:  '.translate(this.props.lang)}</h6>
                                    <p>{'Gå in på vår hemsida och logga in. Under ”MINA SIDOR” finner du dina ordrar och all information kring vilka artiklar som du beställt. Klicka på “GÖR REKLAMATION” knappen i menyn, fyll i formuläret och skicka det till oss.  '.translate(this.props.lang)}</p>
                                    <p>{'Efter att kundtjänst har fått in all information dem behöver för att kunna hjälpa er i ärendet kommer dem ge er olika lösningsförslag beroende på vad det är för fel/defekt. Du avsäger dig framtida reklamations- och returrätt för det specifika felet om du godkänner ett prisavdrag/kompensation. Om du väljer att returnera varor i alla fall, kommer du inte debiteras för några avgifter eller returfrakt.  '.translate(this.props.lang)}</p>
 */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="about-footer"></div>
      </div>
    );
  }
}

export default Page(CookiesPage);
