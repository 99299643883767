import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

import logoLight from '../assets/svg/logo-light.svg';
import fb from '../assets/svg/facebook-f.svg';
import inst from '../assets/svg/instagram-f.svg';
import pnt from '../assets/svg/pinterest-f.svg';
import yt from '../assets/svg/youtube-f.svg';
import arrowRight from '../assets/svg/rightAr.svg';
import fsc from '../assets/svg/fsc-logo.svg';
import klarna from '../assets/svg/klarna.svg';
import cards from '../assets/images/cards.png';

export class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    console.log(this.state.subscribeInfo ? this.state.subscribeInfo : null);
    return (
      <div>
        <footer className="footer">
          <Container fluid>
            <Row className="footer-upper justify-center">
              <Col lg="11">
                <Row>
                  <Col lg="2" xs="6">
                    <div className="footer-content">
                      <h6>{'VÅRT FÖRETAG'.translate(this.props.lang)}</h6>
                      <ul>
                        <li>
                          <Link to="/om-oss">
                            {'Om oss'.translate(this.props.lang)}
                          </Link>
                        </li>
                        <li>
                          <Link to="/hitta-aterforsaljare">
                            {'Hitta butik'.translate(this.props.lang)}
                          </Link>
                        </li>
                        <li>
                          <Link to="/kontakt">
                            {'Kontakt'.translate(this.props.lang)}
                          </Link>
                        </li>
                        {!this.props.uData ? (
                          <li>
                            <Link to="/bli-aterforsaljare">
                              {'Bli återförsäljare'.translate(this.props.lang)}
                            </Link>
                          </li>
                        ) : null}
                        {/* <li>
                                                    <Link to="/catalog">Produktkataloger</Link>
                                                </li> */}
                        <li>
                          <Link to="/jobba-hos-oss">
                            {'Jobba hos oss'.translate(this.props.lang)}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg="2" xs="6">
                    <div className="footer-content">
                      <h6>{'SORTIMENT'.translate(this.props.lang)}</h6>
                      <ul>
                        {this.props.rootCategories.map((item, idx) => {
                          return (
                            <li key={idx}>
                              <Link
                                to={Object.translate(
                                  item,
                                  'breadcrumb',
                                  this.props.lang,
                                )}
                              >
                                {Object.translate(
                                  item,
                                  'name',
                                  this.props.lang,
                                )}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      {/*
                                            <ul>
                                                <li>
                                                    <Link>Inomhusmöbler</Link>
                                                </li>
                                                <li>
                                                    <Link>Utomhusmöbler</Link>
                                                </li>
                                                <li>
                                                    <Link>Furniture Fashion</Link>
                                                </li>
                                                <li>
                                                    <Link>Produktkataloger</Link>
                                                </li>
                                            </ul>
                                            */}
                    </div>
                  </Col>
                  <Col lg="2" xs="6">
                    <div className="footer-content">
                      <h6>{'VILLKOR'.translate(this.props.lang)}</h6>
                      <ul>
                        {this.props.uData &&
                        !this.props.uData.creditCardPayment ? (
                          <li>
                            <Link to="/page/kopvillkor">
                              {'Köpvillkor'.translate(this.props.lang)}
                            </Link>
                          </li>
                        ) : null}
                        {!this.props.uData ||
                        (this.props.uData &&
                          this.props.uData.creditCardPayment) ? (
                          <li>
                            <Link to="/page/allmanna-villkor">
                              {'Allmänna villkor'.translate(this.props.lang)}
                            </Link>
                          </li>
                        ) : null}

                        <li>
                          <Link to="/page/personuppgiftspolicy">
                            {'Personuppgiftspolicy'.translate(this.props.lang)}
                          </Link>
                        </li>
                        <li>
                          <Link to="/cookies">
                            {'Cookies'.translate(this.props.lang)}
                          </Link>
                        </li>
                        <li>
                          <Link to="/page/returnera-varor">
                            {'Returnera varor'.translate(this.props.lang)}
                          </Link>
                        </li>
                      </ul>

                      {/* <h6>{'NYHETSBREV'.translate(this.props.lang)}</h6>
                                            <div className="newsletter-container">
                                                <input type="text" placeholder={"ANGE DIN MEJLADRESS".translate(this.props.lang)} onChange={(e) => {
                                                    this.setState({
                                                        subscribeInfo: e.target.value
                                                    })
                                                }} />
                                                {
                                                    this.state.subscribeText ?
                                                        <div className="subscribe-text-container">
                                                            <p className="subscribe-text">{'Du har framgångsrikt registrerat dig!'.translate(this.props.lang)}</p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.subscribeInfo == '' || this.state.subscribeInfo == null ?
                                                        null
                                                        :
                                                        <Isvg src={arrowRight} onClick={() => {
                                                            this.setState({
                                                                subscribeText: true
                                                            })
                                                        }} />
                                                }
                                            </div> */}
                    </div>
                  </Col>
                  <Col xs="6" className="fsc-mobile">
                    <Isvg src={fsc} />
                  </Col>
                  <Col lg={{ size: 4, offset: 2 }} xs="12">
                    <div className="footer-content">
                      <div className="footer-social">
                        <div>
                          <a
                            href="https://www.facebook.com/venturedesignSE"
                            target="_blank"
                          >
                            <Isvg src={fb} /> -{' '}
                            {'FÖLJ OSS'.translate(this.props.lang)}
                          </a>
                          <ul>
                            <li>
                              <a href="https://www.facebook.com/venturedesignSE">
                                Venture Design
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/Venturehomese">
                                Venture Home
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/vindcollections">
                                Vind Collection
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <a
                            href="https://www.instagram.com/venture.design/"
                            target="_blank"
                          >
                            <Isvg src={inst} /> -{' '}
                            {'FÖLJ OSS'.translate(this.props.lang)}
                          </a>
                          <ul>
                            <li>
                              <a href="https://www.instagram.com/venture.design/ ">
                                Venture Design
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/venturehomese/">
                                Venture Home
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/vindcollections/">
                                Vind Collection
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/furniturefashion_sweden/">
                                Furniture Fashion
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="fsc">
                          <Link to="/fsc">
                            <Isvg src={fsc} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="12">
                    <div className="klarna">
                      <img src={cards} />
                      {/* <a href="https://klarna.com/" taret="_blank">
                                            <Isvg src={klarna} />
                                        </a> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="footer-lower">
              <Col lg="12">
                <Link to="/">
                  <Isvg src={logoLight} />
                </Link>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Footer;
