import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import AccountInfo from '../components/forms/accountInfo';
import user from '../assets/images/user.png';

var striptags = require('striptags');
import AccountNav from '../components/accountNav';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import moment from 'moment';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';
import { API_ENDPOINT } from '../constants';

class AccountPageL extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      items: [],
    };
  }
  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    let params = getSearchParams(this.props[0].location.search, {});

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          params,
          this.props[0].location.pathname,
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  getCode = (orderNumber) => {
    fetch(API_ENDPOINT + `/account/orders/${orderNumber}/shipment/tracking`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result && result.code) {
          window.open(
            `https://www.dhl.com/fi-en/home/tracking/tracking-freight.html?submit=1&tracking-id=${result.code}`,
            '_blank',
          );
        } else {
          alert('No shipment code attached!'.translate(this.props.lang));
        }
      });
  };
  getConfirmation = (orderNumber) => {
    fetch(API_ENDPOINT + `/account/orders/${orderNumber}/order-confirmation`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status == 404) {
          alert('No order confirmation attached!'.translate(this.props.lang));
          return;
        }

        return res.blob();
      })
      .then((data) => {
        if (data) window.open(URL.createObjectURL(data));
      });
  };

  render() {
    return (
      <div className="account-page">
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? imageMobile
                : image
            }
          />
          <div className="test-content">
            <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
          </div>
        </section>

        <Container>
          <Row>
            <Col lg="4">
              <AccountNav {...this.props} />
            </Col>
            <Col lg="8">
              {this.state.items.map((item, idx) => {
                return (
                  <div className="account-container">
                    {idx == 0 ? (
                      <h5>{'MINA ORDRAR'.translate(this.props.lang)}</h5>
                    ) : null}
                    <h5 style={{ marginBottom: 5 }}>
                      {'Ordernummer:'.translate(this.props.lang)}{' '}
                      {item.paymentMethod === 'Briqpay' ? 'BP' : 'HS'}{item.orderNumber}
                    </h5>
                    <h5>
                      {'Betalningsmetod:'.translate(this.props.lang)}{' '}
                      {item.paymentMethod
                        ? item.paymentMethod
                        : 'Faktura'.translate(this.props.lang)}
                    </h5>

                    <div className="order">
                      <table className="order-table">
                        <thead>
                          <tr>
                            <th>
                              <span>
                                {'PRODUKT'.translate(this.props.lang)}
                              </span>
                            </th>
                            <th>
                              <span>{'PRIS'.translate(this.props.lang)}</span>
                            </th>
                            <th>
                              <span>{'ANTAL'.translate(this.props.lang)}</span>
                            </th>
                            <th>
                              <span>
                                {'DELSUMMA'.translate(this.props.lang)}
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.items.map((product, pidx) => {
                            return (
                              <tr>
                                <td>
                                  <img
                                    src={
                                      product.thumbnailExposed
                                        ? API_ENDPOINT +
                                          product.thumbnailExposed.thumbnail
                                        : product.thumbnailMood
                                        ? API_ENDPOINT +
                                          product.thumbnailMood.thumbnail
                                        : null
                                    }
                                  />{' '}
                                  {product.productFamily}{' '}
                                  {Object.translate(
                                    product,
                                    'name',
                                    this.props.lang,
                                  )}
                                </td>
                                <td>
                                  {item.paymentMethod == 'Klarna'
                                    ? product.price.value *
                                      (1 + product.price.vat / 100)
                                    : product.price.value}{' '}
                                  {product.price.currency}
                                </td>
                                <td>{product.quantity}</td>
                                <td>
                                  {(item.paymentMethod == 'Klarna'
                                    ? product.price.value *
                                      (1 + product.price.vat / 100)
                                    : product.price.value) *
                                    product.quantity}{' '}
                                  {product.price.currency}
                                </td>
                              </tr>
                            );
                          })}
                          {item.paymentMethod === 'Briqpay' ? (
                              <tr className="sum-row">
                                <td></td>
                                <td></td>
                                <td>{'Moms:'.translate(this.props.lang)}</td>
                                <td>
                                  {item.vat} {item.currency}
                                </td>
                              </tr>
                          ) : null}
                          <tr className="sum-row">
                            <td></td>
                            <td></td>
                            <td>{'Delsumma:'.translate(this.props.lang)}</td>
                            <td>
                              {item.paymentMethod === 'Klarna' || item.paymentMethod === 'Briqpay'
                                ? item.total
                                : item.subtotal}{' '}
                              {item.currency}
                            </td>
                          </tr>

                          <tr className="sum-row">
                            <td></td>
                            <td></td>
                            <td>{'Frakt:'.translate(this.props.lang)}</td>
                            <td>
                              {item.paymentMethod === 'Klarna' || item.paymentMethod === 'Briqpay'
                                ? (item.shippingCost ? item.shippingCost : 0) +
                                  ' SEK'
                                : 'Berkanas pa fakturan'.translate(
                                    this.props.lang,
                                  )}
                            </td>
                          </tr>

                          {!item.paymentMethod ? (
                            <tr className="sum-row">
                              <td></td>
                              <td></td>
                              <td>{'Moms:'.translate(this.props.lang)}</td>
                              <td>
                                {item.vat} {item.currency}
                              </td>
                            </tr>
                          ) : null}

                          <tr className="sum-row">
                            <td></td>
                            <td></td>
                            <td>{'Totalt:'.translate(this.props.lang)}</td>
                            <td>
                              {item.total +
                                (item.shippingCost
                                  ? item.shippingCost
                                  : 0)}{' '}
                              {item.currency}
                            </td>
                          </tr>
                          {item.paymentMethod == 'Klarna' ? (
                            <tr className="sum-row">
                              <td></td>
                              <td></td>
                              <td>
                                {'Varav moms:'.translate(this.props.lang)}
                              </td>
                              <td>
                                {(
                                  (item.total +
                                    (item.shippingCost
                                      ? item.shippingCost
                                      : 0)) *
                                  0.2
                                ).formatPrice(2)}{' '}
                                {item.currency}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                      <div className="order-info">
                        <div>
                          {'Beställningsdatum:'.translate(this.props.lang)}{' '}
                          {moment.unix(item.orderDate).format('DD/MM/YYYY')}
                        </div>
                        <div>{'Skickades:'.translate(this.props.lang)} /</div>
                        <button
                          className="button"
                          onClick={() => this.getCode(item.orderNumber)}
                        >
                          {'SPÅRA PAKET'.translate(this.props.lang)}
                        </button>
                        <button
                          className="button"
                          onClick={() => this.getConfirmation(item.orderNumber)}
                        >
                          {item.paymentMethod == 'Klarna'
                            ? 'ORDERBEKRÄFTELSE'.translate(this.props.lang)
                            : 'VISA FAKTURA'.translate(this.props.lang)}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(AccountPageL);
