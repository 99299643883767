import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import AccountInfo from '../components/forms/returnGoodsForm';
import user from '../assets/images/user.png';
import AccountNav from '../components/accountNav';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';
import Article from '../components/article';
import * as FileSaver from 'file-saver';
import XSLX from 'sheetjs-style';
import { API_ENDPOINT } from '../constants';
var striptags = require('striptags');

class WishlistPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      controlPanel: true,
    };
  }


  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  submit = (data) => {
    fetch(API_ENDPOINT + '/account/update', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.setState({ error: result.error });
        } else {
          this.props.verifyUser();
        }
      });
  };

  exportToExcel = () => {
    console.log('export: ', this.props.wishlist)
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    const fileExtension = ".xlsx";

    const dataExport = this.props?.wishlist?.map((item) => {
      return {
        SKU: item.sku,
        Product_name: Object.translate(
          item,
          'name',
          this.props.lang,
        ),
        Price: item.price?.value + ' ' + item.price?.currency
      }
    })
    const ws = XSLX.utils.json_to_sheet(dataExport);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XSLX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'wishlist' + fileExtension)


  }

  render() {
    return (
      <div className="account-page">
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? imageMobile
                : image
            }
          />
          <div className="test-content">
            <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
          </div>
        </section>

        <Container>
          <Row>
            <Col lg="4">
              <AccountNav {...this.props} />
            </Col>
            <Col lg="8">
              <div className="account-container">
                <div className="wrapper">
                  <h5>{'MINA FAVORITER'.translate(this.props.lang)}</h5>
                  <button className='button' onClick={(e) => this.exportToExcel()}>EXPORT</button>
                </div>


                <Row>
                  {this.props.wishlist.map((item, idx) => {
                    return (
                      <Col lg="4" key={idx}>
                        <Article
                          wishlistDict={this.props.wishlistDict}
                          addToWishlist={this.props.addToWishlist}
                          pathname={this.props[0].location.pathname}
                          uData={this.props.uData}
                          lang={this.props.lang}
                          {...item}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(WishlistPage);
