import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

import n1 from '../assets/images/n1.jpg';

class News extends Component {
  constructor(props) {
    super(props);
    this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {};

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    if (this.props.socketIOClient) {
      this.registerSocketIOEvents();
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.socketIOClient) {
      this.registerSocketIOEvents();
    }
  }

  registerSocketIOEvents() {
    if (this.state._registeredEvents) return;

    this.setState({
      _registeredEvents: true,
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.updateWindowDimensions);

    if (!this.props.socketIOClient) return;
  }

  handleClickOutside(event) {
    /*
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
            })
        }
        */
  }

  render() {
    return (
      <div>
        <article className="news-box">
          <div className="news-box-header">
            <img src={n1} />
            <span>NYHET</span>
          </div>
          <div className="news-box-body">
            <a>BORD</a>
            <h4>
              VENTURE HOME <span>Bianca – matbord</span>
            </h4>
          </div>
        </article>
      </div>
    );
  }
}

export default News;
