import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';

import CartForm from '../components/forms/cartAddressForm';
import FactureForm from '../components/forms/factureForm';

import arrowRightIcon from '../assets/svg/arrow-right.svg';
import tagIcon from '../assets/svg/tag.svg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import noImage from '../assets/images/no-image.png';
import { API_ENDPOINT } from '../constants';
import { connect } from 'react-redux';
import { formValueSelector, change, isDirty, submit } from 'redux-form';
import moment from 'moment';
import logoLight from '../assets/svg/logo-dark.svg';
import Check from '../components/forms/fields/check';

var striptags = require('striptags');

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      ...props.initialData,
      items: [],
      showForm: true,
      deliverTo: 'shipping',
    };
  }

  get = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          getSearchParams(this.props[0].location.search, {}),
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

   this.get();

    let params = getSearchParams(this.props[0].location.search, {});
    if (params.transaction) {
      this.setState(
        {
          _loading: true,
        },
        () => {
          this.transactionInterval = setInterval(() => {
            fetch(API_ENDPOINT + '/cart/order/' + params.transaction, {
              method: 'GET',
              headers: {
                Authorization: localStorage.getItem('authToken')
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : localStorage.getItem('guestToken')
                    ? `Guest ${localStorage.getItem('guestToken')}`
                    : null,
                'Content-Type': 'application/json',
              },
            })
              .then((res) => res.json())
              .then((result) => {
                if (result && result._notReady) {
                  this.props.fetchCartInfo();
                  this.setState({
                    order: result,
                    _loading: null,
                  });

                  return;
                }

                if (result && result.orderNumber) {
                  // if (result.html_snippet){
                  //     this.iframe.src = `data:text/html;charset=utf-8,` + escape(result.html_snippet)
                  // }
                  this.props.fetchCartInfo();

                  if (window.gtag) {
                    window.gtag('event', 'purchase', {
                      transaction_id: result.orderNumber,
                      affiliation: 'B2C | VentureDesign.se',
                      value: result.total,
                      currency: result.currency,
                      tax: result.vat,
                      shipping: 0,

                      items: result.items.map((item) => ({
                        id: item.sku,
                        name: Object.translate(
                          item,
                          'sortName',
                          this.props.lang,
                        ),
                        brand: item.brand,
                        quantity: item.quantity,
                        price: item.price ? item.price.value : null,
                      })),
                    });
                  }

                  this.setState({
                    order: result,
                    _loading: null,
                  });
                  clearInterval(this.transactionInterval);
                }
              });
          }, 1000);
        },
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    }

    if (!prevState.items.length && this.state.items.length) {
      if (window.gtag) {
        window.gtag('event', 'begin_checkout', {
          items: this.state.items.map((item) => ({
            id: item.sku,
            name: Object.translate(item, 'sortName', this.props.lang),
            brand: item.brand,
            quantity: item.quantity,
            price: item.price ? item.price.value : null,
          })),
          coupon: '',
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => { };

  checkout = () => {
    if (this.state.loading) {
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + '/cart/checkout', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            billingData: this.state.billingData,
            shippingData: this.state.shippingData,
            deliveryDate: this.state.deliveryDate,
            orderReference: this.state.orderReference,
            deliverToDifferentAddress: true, //this.state.deliverToDifferentAddress,
            partialDelivery: this.state.partialDelivery,
            freightMarks: this.state.freightMarks,
            deliverTo: this.state.deliverTo,
            deliveryNotificationEmail: this.state.deliveryNotificationEmail
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            this.props.fetchCartInfo();

            if (window.gtag && result.items && result.items.length) {
              window.gtag('event', 'purchase', {
                transaction_id: result.orderNumber,
                affiliation: 'B2B | VentureDesign.se',
                value: result.total,
                currency: result.currency,
                tax: result.vat,
                shipping: 0,

                items: result.items.map((item) => ({
                  id: item.sku,
                  name: Object.translate(item, 'sortName', this.props.lang),
                  brand: item.brand,
                  quantity: item.quantity,
                  price: item.price ? item.price.value : null,
                })),
              });
            }

            this.setState({
              order: result,
              loading: false,
            });
          });
      },
    );
  };

  render() {
    return (
      <div className="cart-page">
        <Container fluid>
          {(!this.state.order ||
            (this.state.order && this.state.order.error)) &&
            !this.state._loading ? (
            <Row>
              <Col lg="12" style={{ textAlign: 'center' }}>
                <nav className="breadcrumbs">
                  <Link to="/cart">
                    {'Varukorg'.translate(this.props.lang)}
                  </Link>
                  <span className="divider hide-for-small">
                    <i className="icon-angle-right"></i>
                  </span>
                  <Link
                    to="/checkout"
                    className={`${!this.state.order ? 'current' : ''
                      } hide-for-small`}
                  >
                    {'Kassan'.translate(this.props.lang)}
                  </Link>
                  <span className="divider hide-for-small">
                    <i className="icon-angle-right"></i>
                  </span>
                  <Link
                    to="#"
                    className={`${this.state.order ? 'current' : ''
                      } no-click hide-for-small`}
                  >
                    {'Ordern komplett'.translate(this.props.lang)}
                  </Link>
                </nav>
              </Col>

              <Col lg="7">
                <div className="facture-address-container">
                  {/* <h6>{'Har du en rabattkod?'.translate(this.props.lang)} <span><a>{'Klicka här'.translate(this.props.lang)}</a></span> {'för att ange kod'.translate(this.props.lang)}</h6> */}
                  <div className="account-container">
                    <Row>
                      <Col lg="4">
                        <h3>
                          {'FAKTURERINGSADRESS'.translate(this.props.lang)}
                        </h3>
                        {this.props.uData && this.props.uData.billingData ? (
                          <address>
                            {this.props.uData.billingData.company}
                            <br />
                            {this.props.uData.billingData.address1}{' '}
                            {this.props.uData.billingData.address2}
                            <br />
                            {this.props.uData.billingData.postalCode}{' '}
                            {this.props.uData.billingData.city}
                            <br/>
                            {this.props.uData.billingData.email}
                          </address>
                        ) : null}
                      </Col>
                      <Col lg="4">
                        <h3>{'LEVERANSADRESS'.translate(this.props.lang)}</h3>
                        {this.props.uData && this.props.uData.shippingData ? (
                          <address>
                            {this.props.uData.shippingData.company}
                            <br />
                            {this.props.uData.shippingData.address1}{' '}
                            {this.props.uData.shippingData.address2}
                            <br />
                            {this.props.uData.shippingData.postalCode}{' '}
                            {this.props.uData.shippingData.city}
                            <br/>
                            {this.props.uData.shippingData.email}
                          </address>
                        ) : null}
                      </Col>

                      <Col lg="12" style={{ borderBottom: '2px solid #000' }}>
                        <p>
                          {'OBS! Om dina uppgifter inte stämmer kan du ändra dem i din panel.'.translate(
                            this.props.lang,
                          )}{' '}
                          <Link
                            style={{ fontWeight: 700 }}
                            to="/account/edit-address"
                          >
                            {'Klicka HÄR'.translate(this.props.lang)}
                          </Link>
                        </p>
                      </Col>

                      <Col lg="12" style={{ marginBottom: 15 }}>
                        <h3>
                          {'WHERE DO YOU WANT US TO DELIVER THE GOODS?'.translate(
                            this.props.lang,
                          )}
                        </h3>
                      </Col>
                      <Col lg="12">
                        <div className="check-wrap">
                          <Check
                            label={'Deliver to my billing address'.translate(
                              this.props.lang,
                            )}
                            onChange={() => {
                              this.setState({ showForm: false }, () => {
                                this.setState({
                                  deliverTo: 'billing',
                                  showForm: true,
                                });
                              });
                            }}
                            value={this.state.deliverTo == 'billing'}
                          ></Check>
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="check-wrap">
                          <Check
                            label={'Deliver to my delivery address.'.translate(
                              this.props.lang,
                            )}
                            onChange={() => {
                              this.setState({ showForm: false }, () => {
                                this.setState({
                                  deliverTo: 'shipping',
                                  showForm: true,
                                });
                              });
                            }}
                            value={this.state.deliverTo == 'shipping'}
                          ></Check>
                        </div>
                      </Col>
                      <Col lg="12">
                        <div
                          className="check-wrap"
                          style={{ marginBottom: 20 }}
                        >
                          <Check
                            label={'Deliver directly to my customer at his address (Fill out the form below)'.translate(
                              this.props.lang,
                            )}
                            onChange={() => {
                              this.setState({ showForm: false }, () => {
                                this.setState({
                                  deliverTo: 'custom',
                                  showForm: true,
                                });
                              });
                            }}
                            value={this.state.deliverTo == 'custom'}
                          ></Check>
                        </div>
                      </Col>
                    </Row>

                    {this.props.uData && this.state.showForm ? (
                      <CartForm
                        lang={this.props.lang}
                        deliveryTo={this.state.deliverTo}
                        initialValues={{
                          ...this.props.uData,
                          shippingData:
                            this.state.deliverTo == 'billing'
                              ? this.props.uData.billingData
                              : this.state.deliverTo == 'shipping'
                                ? this.props.uData.shippingData
                                : {} /*shippingData: { country: this.props.uData && this.props.uData.billingData.country }*/,
                        }}
                        onSubmit={(data) => {
                          if(this.state.deliverTo && this.state.deliverTo === 'custom' && data.deliveryNotificationEmail) {
                            const shippingData = data.shippingData;
                            shippingData.email = this.props.uData.shippingData.email
                            this.setState(
                                {
                                  shippingData,
                                  billingData: data.billingData,
                                  deliverToDifferentAddress: true,
                                  deliveryNotificationEmail: data.deliveryNotificationEmail
                                },
                                this.checkout,
                            );
                          } else {
                            this.setState(
                                {
                                  shippingData: data.shippingData,
                                  billingData: data.billingData,
                                  deliverToDifferentAddress: true,
                                },
                                this.checkout,
                            );
                          }

                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col lg="5">
                <div className="cart-ticket">
                  <div className="ticket-details">
                    <h3>{'Din beställning'.translate(this.props.lang)}</h3>
                    <table>
                      <thead>
                        <tr>
                          <th className="product-name">
                            {'Produkt'.translate(this.props.lang)}
                          </th>
                          <th className="product-total">
                            {'Delsumma'.translate(this.props.lang)}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.map((item, idx) => {
                          return (
                            <tr className="cart'item">
                              <td class="product-name">
                                {item.productFamily}{' '}
                                {Object.translate(
                                  item,
                                  'name',
                                  this.props.lang,
                                )}{' '}
                                - {item.price ? item.price.salesUnit + (item.price.salesUnit === 'FRP' ? `(${item.price.unitQuantity})` : '') : ''}&nbsp;
                                <strong class="product-quantity">
                                  ×&nbsp;{item.quantity}
                                </strong>
                              </td>
                              <td className="product-total">
                                <span className="amount">
                                  <bdi>
                                  {
                                  item.price.originalValue && item.price.originalValue != item.price.value ?
                                  <>
                                  <span style={{textDecoration: 'line-through',                              color: '#3A3C47',
                                  fontWeight: 300,
      }}>
                                                                {this.props.uData &&
                                !this.props.uData.creditCardPayment
                                ? Number(item.price.originalValue * item.quantity).formatPrice(2)
                                : Number(item.price.originalValue * item.quantity * (1 + item.price.vat / 100)).formatPrice(2)}
                              &nbsp;<span>{item.price.currency}</span>

                                  </span>
                                  <br/>

                                  </>
                                 
                                  :

                                  null
                                }


                                    {Number(item.price.value * item.quantity).formatPrice(2)}&nbsp;
                                    <span>{item.price.currency}</span>
                                  </bdi>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr className="cart-subtotal">
                          <th>{'Delsumma'.translate(this.props.lang)}</th>
                          <td>
                            <span className="amount">
                            <bdi>
                            {this.props.uData &&
                              !this.props.uData.creditCardPayment
                              ? parseFloat(this.state.totalWithoutDiscount).formatPrice(2)
                              : parseFloat(
                                this.state.totalWithoutDiscount ,
                              ).formatPrice(2)}
                            &nbsp;
                            <span className="woocommerce-Price-currencySymbol">
                              {this.state.currency}
                            </span>
                          </bdi>
                            </span>
                          </td>
                        </tr>
                        {
                          this.state.discountValue ?
                            <tr className="cart-subtotal">
                              <th>{'Rabatt'.translate(this.props.lang)}</th>
                              <td>
                                <span className="amount">
                                <bdi>                            -{this.props.uData &&
                              !this.props.uData.creditCardPayment
                              ? parseFloat(this.state.totalWithoutDiscount - this.state.total).formatPrice(2)
                              : parseFloat(
                                this.state.totalWithoutDiscount  - (this.state.total - this.state.shippingCost) ,
                              ).formatPrice(2)}
                                                          <span className="woocommerce-Price-currencySymbol">
                              {this.state.currency}
                            </span>

</bdi>
                                </span>
                              </td>
                            </tr>
                            : null
                        }

                        <tr className="cart-subtotal">
                          <th>{'Delsumma'.translate(this.props.lang)}</th>
                          <td>
                            <span className="amount">
                            <bdi>
                              {this.props.uData &&
                              !this.props.uData.creditCardPayment
                              ? parseFloat(this.state.subtotal).formatPrice(2)
                              : parseFloat(
                                this.state.total - this.state.shippingCost,
                              ).formatPrice(2)}
                                &nbsp;
                                <span className="woocommerce-Price-currencySymbol">
                                  {this.state.currency}
                                </span>
                              </bdi>
                            </span>
                          </td>
                        </tr>
                        <tr className="shipping ">
                          <td className="shipping'inner" colspan="2">
                            <table className="shipping-table ">
                              <tbody>
                                <tr>
                                  <th>{'Frakt'.translate(this.props.lang)}</th>
                                  <td data-title="Frakt">
                                    <ul
                                      id="shipping_method"
                                      className="shipping'list"
                                    >
                                      <li className="shipping-list-item">
                                        <label
                                          className="shipping-list-label"
                                          for="shipping_method_0_flat_rate1"
                                        >
                                          {'Beräknas på fakturan'.translate(
                                            this.props.lang,
                                          )}
                                        </label>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr className="tax-total">
                          <th>{'Moms'.translate(this.props.lang)}</th>
                          <td>
                            <span class="amount">
                              <bdi>
                                {Number(this.state.vat).formatPrice(2)}&nbsp;
                                <span>{this.state.currency}</span>
                              </bdi>
                            </span>
                          </td>
                        </tr>
                        <tr className="order-total">
                          <th>{'Summa'.translate(this.props.lang)}</th>
                          <td>
                            <strong>
                              <span class="amount">
                                <bdi>
                                  {Number(this.state.total).formatPrice(2)}&nbsp;
                                  <span>{this.state.currency}</span>
                                </bdi>
                              </span>
                            </strong>{' '}
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <div className="facture-form">
                      <h6>{'Faktura'.translate(this.props.lang)}</h6>
                      <p>
                        {'Betalning sker via faktura efter godkänd beställning'.translate(
                          this.props.lang,
                        )}
                      </p>
                      <div className="account-container facture-form-container">
                        {this.props.uData ? (
                          <FactureForm
                            lang={this.props.lang}
                            loading={
                              this.state.loading ||
                              !this.props.uData ||
                              (this.props.uData &&
                                (this.props.uData.username ==
                                  'Inlogg hemsida' ||
                                  this.props.uData.username ==
                                  'Login homepage' ||
                                  this.props.uData.username ==
                                  'H&M Home Store'))
                            }
                            onSubmit={(data) => {
                              this.setState(
                                {
                                  orderReference: data.orderReference,
                                  deliveryDate: data.deliveryDate,
                                  partialDelivery: data.partialDelivery,
                                  freightMarks: data.freightMarks,
                                },
                                () => {

                                  this.props.dispatch(
                                    submit('cartAddressForm'),
                                  );
                                },
                              );
                            }}
                          />
                        ) : null}
                        {this.state.order ? (
                          this.state.order &&
                            this.state.order.error == 'shippingAddress' ? (
                            <p
                              style={{
                                color: 'red',
                                marginTop: 10,
                                fontWeight: 500,
                              }}
                            >
                              {'Attention! You have not filled out all the fields under your shipping address. Fill in the data '.translate(
                                this.props.lang,
                              )}{' '}
                              <Link
                                style={{
                                  color: 'red',
                                  fontWeight: 600,
                                  textDecoration: 'underline',
                                }}
                                to="/account/edit-address/shipping"
                              >
                                {'HERE.'.translate(this.props.lang)}
                              </Link>
                            </p>
                          ) : this.state.order &&
                            this.state.order.error == 'billingAddress' ? (
                            <p
                              style={{
                                color: 'red',
                                marginTop: 10,
                                fontWeight: 500,
                              }}
                            >
                              {'Attention! You have not filled out all the fields under your billing address. Fill in the data '.translate(
                                this.props.lang,
                              )}{' '}
                              <Link
                                style={{
                                  color: 'red',
                                  fontWeight: 600,
                                  textDecoration: 'underline',
                                }}
                                to="/account/edit-address/billing"
                              >
                                {'HERE.'.translate(this.props.lang)}
                              </Link>
                            </p>
                          ) : (
                            <p
                              style={{
                                color: 'red',
                                marginTop: 10,
                                fontWeight: 500,
                              }}
                            >
                              {'Attention! Something went wrong, please try again'.translate(
                                this.props.lang,
                              )}
                            </p>
                          )
                        ) : null}
                        <Row>
                          <p className="facture-form-text">
                            {'Din personliga data kommer endast att användas för att hantera beställningar, kundservice, för att förbättra din upplevelse av webbplatsen och eventuellt annat som står i vår integritetspolicy.'.translate(
                              this.props.lang,
                            )}
                          </p>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : this.state.order ? (
            <Row>
              <Col lg={{ size: 4, offset: 4 }}>
                {this.state.order.html_snippet ? (
                  <div className="klarna-confirmation-iframe">
                    <iframe
                      src={
                        `data:text/html;charset=utf-8,` +
                        escape(this.state.order.html_snippet)
                      }
                    ></iframe>
                  </div>
                ) : null}
                {this.state.order.html_snippet ? (
                  <div style={{ textAlign: 'center', marginBlock: 15 }}>
                    <Isvg src={logoLight} />
                  </div>
                ) : null}

                <h6 className="checkout-done-title">
                  {'Tack för att du har handlat hos oss!'.translate(
                    this.props.lang,
                  )}
                  <br />
                  {'Vi har tagit emot din beställning.'.translate(
                    this.props.lang,
                  )}
                  <br />
                  {'Din orderbekräftelse kommer inom kort via e-post.'.translate(
                    this.props.lang,
                  )}
                  <br />
                </h6>
              </Col>
              {
                this.state.order.orderNumber ? (
                  <Col lg={{ size: 4, offset: 4 }}>
                    <div className="order-completed-list-container">
                      <ul className="order-completed-list">
                        <li>
                          {'Bestallningsnumer'.translate(this.props.lang)}:{' '}
                          <span>{this.state.order.orderNumber}</span>
                        </li>
                        <li>
                          {'Datum'.translate(this.props.lang)}:{' '}
                          <span>
                            {moment
                              .unix(this.state.order.orderDate)
                              .format('MMMM DD, YYYY')}
                          </span>
                        </li>
                        <li>
                          {'E-post'.translate(this.props.lang)}:{' '}
                          <span>{this.state.order.billingData.email}</span>
                        </li>
                        <li>
                          {'Totalt'.translate(this.props.lang)}:{' '}
                          <span>
                            {Number(this.state.order.total +
                              (this.state.order.shippingCost
                                ? this.state.order.shippingCost
                                : 0)).formatPrice(2)}{' '}
                            {this.state.order.currency}
                          </span>
                        </li>
                        <li>
                          {'Betalningsmetod'.translate(this.props.lang)}:{' '}
                          <span>
                            {this.state.order.paymentMethod
                              ? this.state.order.paymentMethod
                              : 'Faktura'.translate(this.props.lang)}
                          </span>
                        </li>
                        {!this.state.order.paymentMethod ? (
                          <p>
                            {'Betalning sker via faktura efter godkand bestallning'.translate(
                              this.props.lang,
                            )}
                          </p>
                        ) : null}
                      </ul>
                    </div>
                  </Col>
                ) : null
                //     <Col lg="12">
                //     <div className="checkout-loading">
                //     <div className="spinner-wrapper">

                //     <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                //     </div>

                //     </div>

                // </Col>
              }
              {!this.state.order._notReady ? (
                <Col lg={{ size: 4, offset: 4 }}>
                  <div className="order-completed-header">
                    <h2>{'Beställningsdetaljer'.translate(this.props.lang)}</h2>
                  </div>
                  <div className="order-completed-product">
                    <table>
                      <thead>
                        <tr>
                          <th className="product-name">
                            {'Produkt'.translate(this.props.lang)}
                          </th>
                          <th className="product-total">
                            {'Suma'.translate(this.props.lang)}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.order.items.map((item, idx) => {
                          return (
                            <tr className="cart'item">
                              <td class="product-name">
                                {Object.translate(
                                  item,
                                  'name',
                                  this.props.lang,
                                )}{' '}
                                <span>
                                  {item.price ? item.price.salesUnit + (item.price.salesUnit === 'FRP' ? `(${item.price.unitQuantity})` : ''): ''}
                                </span>{' '}
                                x{item.quantity}
                              </td>
                              <td className="product-total">
                                <span className="amount">
                                {
                                  item.price.originalValue && item.price.originalValue != item.price.value ?
                                  <>
                                  <span style={{textDecoration: 'line-through',                              color: '#3A3C47',
                                  fontWeight: 300,
      }}>
                                                                {
                                                                  this.state.order.paymentMethod !== 'Klarna'
                                ? Number(item.price.originalValue * item.quantity).formatPrice(2)
                                : Number(item.price.originalValue * item.quantity * (1 + item.price.vat / 100)).formatPrice(2)}
                              &nbsp;<span>{item.price.currency}</span>

                                  </span>
                                  <br/>

                                  </>
                                 
                                  :

                                  null
                                }

                                  <bdi>
                                    {this.props.uData
                                      ? Number(item.price.value * item.quantity).formatPrice(2)
                                      : Number(item.price.value *
                                      item.quantity *
                                      (1 + item.price.vat / 100)).formatPrice(2)}
                                    &nbsp;<span>{item.price.currency}</span>
                                  </bdi>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        {
                          this.state.order.discountValue ?
                            <>
                                                    <tr>
                          <td>{'Delsumma:'.translate(this.props.lang)}</td>
                          <td className="number">
                            {this.state.order.paymentMethod == 'Klarna'
                              ? Number(this.state.order.totalWithoutDiscount).formatPrice(2)
                              : Number(this.state.order.subtotal).formatPrice(2)}{' '}
                            {this.state.order.currency}
                          </td>
                        </tr>
                        <tr>
                          <td>{'Rabatt'.translate(this.props.lang)} (-{this.state.order.discountValue}%)</td>
                          <td className="number">
                            -{this.state.order.paymentMethod == 'Klarna'
                              ? Number(this.state.order.totalWithoutDiscount  - this.state.order.total).formatPrice(2)
                              : Number(this.state.order.totalWithoutDiscount - this.state.order.total).formatPrice(2)}{' '}
                            {this.state.order.currency}
                          </td>
                        </tr>

                            </>

                          :
                          null

                        }

                        <tr>
                          <td>{'Delsumma:'.translate(this.props.lang)}</td>
                          <td className="number">
                            {this.state.order.paymentMethod == 'Klarna' || this.state.order.paymentMethod === 'Briqpay'
                              ? Number(this.state.order.total).formatPrice(2)
                              : Number(this.state.order.subtotal).formatPrice(2)}{' '}
                            {this.state.order.currency}
                          </td>
                        </tr>
                        <tr>
                          <td>{'Frakt:'.translate(this.props.lang)}</td>
                          <td>

                            {this.state.order.paymentMethod == 'Klarna' || this.state.order.paymentMethod === 'Briqpay'
                              ? Number(this.state.order.shippingCost).formatPrice(2) + ' SEK'
                              : 'Berkanas pa fakturan'.translate(
                                this.props.lang,
                              )}
                          </td>
                        </tr>
                        {!this.state.order.paymentMethod ? (
                          <tr>
                            <td>{'Moms:'.translate(this.props.lang)}</td>
                            <td className="number">
                              {this.state.order.vat} {this.state.order.currency}
                            </td>
                          </tr>
                        ) : null}
                        {!this.state.order.paymentMethod ? (
                          <tr>
                            <td>
                              {'Betalningsmetod:'.translate(this.props.lang)}
                            </td>
                            <td>{'Faktura'.translate(this.props.lang)}</td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>{'Totalt:'.translate(this.props.lang)}</td>
                          <td className="number">
                            {Number(this.state.order.total +
                              (this.state.order.shippingCost
                                ? this.state.order.shippingCost
                                : 0)).formatPrice(2)}{' '}
                            {this.state.order.currency}
                          </td>
                        </tr>
                        {this.state.order.paymentMethod == 'Klarna' ? (
                          <tr>
                            <td>{'Varav moms:'.translate(this.props.lang)}</td>
                            <td className="number">
                              {(
                                (this.state.order.total +
                                  (this.state.order.shippingCost
                                    ? this.state.order.shippingCost
                                    : 0)) *
                                0.2
                              ).formatPrice(2)}{' '}
                              {this.state.order.currency}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                  {this.state.order.paymentMethod != 'Klarna' ? (
                    <div className="order-completed-content-container">
                      <ul className="order-details">
                        <li>
                          {'Asked delivery Date:'.translate(this.props.lang)}{' '}
                          <span>
                            {this.state.order.deliveryDate
                              ? this.state.order.deliveryDate
                              : 'As soon as possible'.translate(
                                this.props.lang,
                              )}
                          </span>
                        </li>
                        <li>
                          {'Shipping phone:'.translate(this.props.lang)}{' '}
                          <span>{this.state.order.shippingData.phone}</span>
                        </li>
                        <li>
                          {'Shipping email:'.translate(this.props.lang)}{' '}
                          <span>{this.state.order.shippingData.email}</span>
                        </li>
                        <li>
                          {'Accepts part delivery:'.translate(this.props.lang)}{' '}
                          <span>
                            {this.state.order.partialDelivery
                              ? 'Ja'.translate(this.props.lang)
                              : 'Nej'.translate(this.props.lang)}
                          </span>
                        </li>
                        <li>
                          {'Order reference:'.translate(this.props.lang)}{' '}
                          <span>
                            {this.state.order.orderReference
                              ? this.state.order.orderReference
                              : '/'}
                          </span>
                        </li>
                      </ul>
                    </div>
                  ) : null}
                  <div className="address">
                    {this.state.order.paymentMethod != 'Klarna' ? (
                      <p>
                        {'Följande adresser kommer att användas i kassan.'.translate(
                          this.props.lang,
                        )}
                      </p>
                    ) : null}
                    <div className="address-container">
                      {this.state.order.paymentMethod != 'Klarna' ? (
                        <div class="address-box">
                          <header>
                            <h3>
                              {'Faktureringsadress'.translate(this.props.lang)}
                            </h3>
                          </header>
                          <address>
                            {this.state.order.billingData.firstName}{' '}
                            {this.state.order.billingData.lastName}
                            <br />
                            {this.state.order.billingData.company}
                            <br />
                            {this.state.order.billingData.address1}{' '}
                            {this.state.order.billingData.address2}
                            <br />
                            {this.state.order.billingData.postalCode}{' '}
                            {this.state.order.billingData.city}
                          </address>
                        </div>
                      ) : null}
                      <div class="address-box">
                        <header>
                          <h3>{'Leveransadress'.translate(this.props.lang)}</h3>
                        </header>
                        <address>
                          {this.state.order.shippingData.company}
                          <br />
                          {this.state.order.shippingData.address1}{' '}
                          {this.state.order.shippingData.address2}
                          <br />
                          {this.state.order.shippingData.postalCode}{' '}
                          {this.state.order.shippingData.city}
                        </address>
                      </div>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          ) : (
            <Row>
              <Col lg="12">
                <div className="checkout-loading">
                  <div className="spinner-wrapper">
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

export default connect()(Page(CheckoutPage));
