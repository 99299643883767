import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import aboutImage from '../assets/images/about.jpg';

var striptags = require('striptags');

class ConditionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <section className="condition-page">
          <Container fluid>
            <Row>
              <Col lg="6">
                <h6>{'Detta är mina köpvillkor'.translate(this.props.lang)}</h6>
                <h2>
                  {'ALLMÄNNA FÖRSÄLJNINGSVILLKOR'.translate(this.props.lang)}
                </h2>

                <p>
                  {'Dessa allmänna leveransvillkor (”Leveransvillkoren”) är tillämpliga vid all försäljning av varor från Venture Design AB (nedan ”Leverantören”). Leverantören och den kund som beställt varor från Leverantören (”Kunden”) benämns nedan var för sig som ”Part” och gemensamt ”Parterna”.'.translate(
                    this.props.lang,
                  )}
                  <br />
                  <br />
                  {'Parternas överenskommelse om leverans av varor, inkluderande Leveransvillkoren, benämns nedan ”Leveransavtalet”. Det noteras att Leveransavtalet kan ha ingåtts muntligen eller skriftligen (exempelvis genom epost).'.translate(
                    this.props.lang,
                  )}
                  <br />
                  <br />
                  {'Utöver Leveransavtalet har Parterna, i den mån så anges i däri (exempelvis i fall där Leverantören på Kundens uppdrag skall skicka varuleveranser direkt till Kundens slutkunder), i samband med ingåendet av Leveransavtalet även ingått personuppgiftsbiträdesavtal enligt Bilaga 1 härtill. Vid motstridighet gäller i första hand Leveransavtalet och i andra hand Personuppgiftsbiträdesavtalet.'.translate(
                    this.props.lang,
                  )}
                  <br />
                  <br />
                  {'Till undvikande av missförstånd förtydligas att Leverantören, i fall där Leverantören enligt Leveransavtalet skall skicka varuleveranser direkt till Kundens slutkunder, inte åtar sig något som helst ansvar gentemot sådana kunder. Leverantören ansvarar därmed endast gentemot Kunden för sådana leveranser och utförandet därav enligt nedan angivna villkor.'.translate(
                    this.props.lang,
                  )}
                </p>

                <h3>{'1. Leverans'.translate(this.props.lang)}</h3>
                <p>
                  {'Alla avtalade leveransvillkor ska tolkas enligt Incoterms 2010.'.translate(
                    this.props.lang,
                  )}{' '}
                  <br />
                  <br />
                  {'I den mån inga leveransvillkor uttryckligen har avtalats skall leverans ske FCA.'.translate(
                    this.props.lang,
                  )}
                  <br />
                  <br />
                  {'Kunden äger ej rätt att avbeställa pågående beställning eller returnera levererade produkter. Om Kunden ej i rätt tid uppfyllt sina förpliktelser eller på annat sätt fördröjt utförandet av leverans, exempelvis genom tillägg eller ändringar i beställningen, är Leverantören berättigad att förlänga leveranstiden med av omständigheterna betingad frist. Om Kunden underlåter att mottaga leveransfärdiga produkter vid i orderbekräftelsen angiven leveranstidpunkt, skall Kunden gentemot Leverantören svara för alla de kostnader som uppstår till följd av att leverans icke kan ske, inkluderande, men inte begränsat till, lagerhållningskostnad för vilken en schablon om 500 kronor per kvadratmeter och dygn tillämpas). Motsvarande skall gälla om Kunden utan skriftlig överenskommelse därom returnerar produkter till Leverantören.'.translate(
                    this.props.lang,
                  )}
                  <br />
                  <br />
                  {'Vid leverans av er order där leverans av fraktbolaget sker vid stängt och/eller obemannat så att denna kommer i retur, står Ni som mottagare för eventuella merkostnader.'.translate(
                    this.props.lang,
                  )}
                  <br />
                  <br />
                  {'Skulle till följd av force majeure fullgörande av leverans hindras, försvåras eller försenas skall Leverantören vara fri från ansvar för leveransens fullbordan. Leverantören äger därvid efter eget val helt eller delvis annullera beställningen eller uppskjuta leveransen med av omständigheterna betingad frist utöver avtalad leveranstid. Såsom force majeure anses bl. a. strejk, arbetsnedläggelse, lockout eller annan arbetskonflikt, krig, mobilisering, rekvisition, beslag, importförbud, exportförbud, allmän varuknapphet, transportsvårigheter, försening av leveranser från underleverantörer eller annan omständighet, över vilken Leverantören icke råder.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Leverantören skall informera Kunden om det föreligger anledning att anta att leverans av produkter kan komma att bli försenad i förhållande till i orderbekräftelsen angivet leveransdatum (efter tillägg av sådan eventuell förlängningsfrist som må följa av vad som anges ovan). Om leveransförsening överstiger 45 dagar och förseningen är väsentlig för Kunden äger kunden rätt att avbeställa sådana produkter som omfattas av förseningen'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Leverantören skall äga rätt att helt eller delvis annullera beställning för det fall Leverantören inte, på grund av väsentliga svårigheter att inköpa de beställda produkterna vilka inte orsakats av Leverantören själv (exempelvis vid brist hos leverantör), kan genomföra leveransen av de aktuella produkterna med kommersiellt rimliga ansträngningar. Leverantörens ansvar är därvid begränsat till att återbetala sådan del av priset för de beställda produkterna som svarar mot de produkter som inte kommer att kunna levereras till Kunden.'.translate(
                    this.props.lang,
                  )}
                </p>

                <h3>{'2. Pris och betalning'.translate(this.props.lang)}</h3>
                <p>
                  {'Samtliga priser och avgifter i Leveransavtalet är angivna exklusive mervärdesskatt och andra efter Leveransavtalets ingående fastställda tillkommande skatter. Betalningstid för faktura är 30 dagar.'.translate(
                    this.props.lang,
                  )}
                </p>
              </Col>
              <Col lg="6">
                <p>
                  {'Samtliga i Leveransavtalet angivna priser är baserade på vid avtalsingåendet gällande valutakurser samt inköpspriser för de aktuella produkterna. Leverantören förbehåller sig rätten att justera priserna för produkterna till följd av väsentliga förändringar i aktuella valutakurser eller inköpspriser för de aktuella produkterna samt vid väsentliga förändringar i Leverantörens övriga omkostnader för dess leverans av produkterna till Kunden över vilka Leverantören inte råder (exempelvis kostnadsökningar i anledning av ändrad lagstiftning, myndighetsåtgärd etc.).'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Levererat gods förblir Leverantörens egendom till dess full betalning erlagts. Vid försenad betalning debiteras dröjsmålsränta enligt räntelagen.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Uppkommer före leverans skälig anledning att antaga att Kunden inte kommer att fullgöra sin betalningsskyldighet äger Leverantören rätt att begära att Kunden ställer säkerhet. Ställes ej säkerhet, som av Leverantören kan godtagas, äger Leverantören rätt att innehålla leveransen intill dess att säkerhet ställts alternativt annullera den aktuella beställningen och kräva ersättning från Kunden för dess direkta kostnader i anledning av beställningen.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Kunden äger ej rätt att kvitta eventuell fordran mot Leverantören som må ha uppkommit i samband med Leveransavtalet eller i övrigt mot Leverantörens anspråk på betalning för dess leveranser av produkter till Kunden.'.translate(
                    this.props.lang,
                  )}
                </p>

                <h3>{'3. Ansvar'.translate(this.props.lang)}</h3>
                <p>
                  {'Levererade produkter skall till kvantitet och kvalitet överensstämma med sådana specifikationer som angivits i Leverantörens orderbekräftelse. Reklamation av brist i leverans samt sådana fel som är möjliga att upptäcka vid en leveranskontroll av levererade produkter skall ske senast fem dagar efter det att produkterna levererats till Kunden. Övriga fel skall reklameras inom 14 dagar från det att felet upptäcktes eller borde upptäckas av Kunden och under alla omständigheter senast sex månader från det att produkterna levererats till Kunden. Göres ej reklamation enligt vad som anges ovan förlorar Kunden rätten att göra felet/bristen gällande.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Vid fel eller brist i leverans skall Leverantören, enligt Leverantörens val, antingen vidta omleverans av felaktiga produkter, leverera erforderliga reservdelar för åtgärdande av felet eller kreditera/återbetala sådan del av priset för leveransen som svarar mot de felaktiga/bristande produkterna.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'I Leveransavtalet angivna rättigheter för Kunden vid leveransförsening, fel respektive brist i leverans utgör Kundens samtliga rättigheter och Kunden skall således inte äga rätt att göra gällande några andra påföljder, exempelvis skadestånd, täckningsköp eller hävning, vid leveransförsening, fel eller brist i leverans.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Leverantörens samlade ersättningsansvar, inklusive prisavdrag, skadeståndsansvar och andra krav under Leveransavtalet är under alla omständigheter begränsat till direkta skador upp till ett totalt belopp motsvarande det pris som Kunden erlagt till Leverantören för de produkter som givit upphov till den aktuella skadan. Leverantören ansvarar inte i något fall för indirekta förluster, inklusive förlorad vinst, produktionsbortfall, tredjemansskada och andra följdskador.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Krav på ersättning under Leveransavtalet skall alltid framställas skriftligen och senast 14 dagar från skadans uppkomst. Om så ej sker skall kravet ej längre kunna göras gällande.'.translate(
                    this.props.lang,
                  )}
                </p>

                <h3>{'4. Övrigt'.translate(this.props.lang)}</h3>
                <p>
                  {'Skulle någon bestämmelse i Leveransavtalet eller del därav befinnas ogiltig, skall detta inte innebära att Leveransavtalet i dess helhet är ogiltigt utan skall, i den mån ogiltigheten väsentligen påverkar Parts utbyte av eller prestation enligt Leveransavtalet, skälig jämkning i Leveransavtalet ske.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Kunden får inte helt eller delvis överlåta eller pantsätta sina rättigheter och/eller förpliktelser enligt Leveransavtalet utan Leverantörens föregående skriftliga godkännande.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Svensk lag skall tillämpas på Leveransavtalet. Tvister som uppstår i anledning av Leveransavtalet skall avgöras i allmän domstol med Växjö tingsrätt som första instans.'.translate(
                    this.props.lang,
                  )}
                </p>
              </Col>
              <Col lg="6">
                <h2 style={{ marginTop: 45 }}>
                  {'TILL ALLMÄNNA FÖRSÄLJNINGSVILLKOR PERSONUPPGIFTSBITRÄDESAVTAL'.translate(
                    this.props.lang,
                  )}
                </h2>
                <p>
                  {'Men Detta personuppgiftsbiträdesavtal (”Biträdesavtalet”) har ingåtts mellan Leverantören och Kunden per dagen för Parternas ingående av Leveransavtalet (enligt definition nedan).'.translate(
                    this.props.lang,
                  )}
                </p>

                <h3>{'1. Definitioner'.translate(this.props.lang)}</h3>
                <p>
                  {'Begrepp med stor begynnelsebokstav i detta Biträdesavtal skall ha den innebörd som anges i Leveransavtalet (enligt definition nedan). Därtill skall följande ord och begrepp ha nedanstående betydelse:'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'”Leveransavtalet” avser det avtal om leverans av varor som ingåtts mellan Leverantören (Venture Design AB) och Kunden, vilket regleras av Allmänna leveransvillkor för Venture Design AB.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'”Biträdesavtalet” avser detta personuppgiftsbiträdesavtal.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'”Den Personuppgiftsansvarige” avser Kunden (enligt Leveransavtalet).'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'”Personuppgiftsbiträdet” avser Venture Design AB (enligt Leveransavtalet).'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'”Tillämplig Dataskyddslagstiftning” avser dataskyddsförordningen (GDPR), dataskyddslagen i Sverige och Datainspektionens bindande föreskrifter och beslut som är tillämpliga på behandlingen av personuppgifter inom ramen för Biträdesavtalet.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'”Tjänsten” avser de tjänster avseende bland annat adressering av varuleveranser till Kundens kunder (varvid Leverantören kommer att behandla personuppgifter avseende sådana Kundens kunder) som Personuppgiftsbiträdet enligt Leveransavtalet skall tillhandahålla till Den Personuppgiftsansvarige.'.translate(
                    this.props.lang,
                  )}
                </p>

                <h3>
                  {'2. Bilagor till Biträdesavtalet'.translate(this.props.lang)}
                </h3>
                <p>
                  {'Till Biträdesavtalet har följande underbilagor fogats, vilka skall utgöra en integrerad del av Biträdesavtalet.'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Underbilaga 1 – Specifikation över behandlingen'.translate(
                    this.props.lang,
                  )}
                  <br /> <br />
                  {'Underbilaga 2 – På förhand godkända underbiträden'.translate(
                    this.props.lang,
                  )}
                </p>

                <h3>
                  {'3. Behandling av personuppgifter'.translate(
                    this.props.lang,
                  )}
                </h3>
                <p>
                  Den Personuppgiftsansvarige är i egenskap av
                  personuppgiftsansvarig ansvarig för att behandling av
                  personuppgifter sker i enlighet med Tillämplig
                  Dataskyddslagstiftning. Den Personuppgiftsansvarige ansvarar
                  för och skall tillse att Personuppgiftsbiträdet inte behandlar
                  andra kategorier av personuppgifter än sådana som anges i
                  Underbilaga 1 och i däri angiven omfattning och således för
                  att endast sådana personuppgifter som till sin natur är att
                  betrakta som harmlösa (såsom kontaktuppgifter till Kundens
                  kunder) och inga uppgifter inom s.k. särskilda kategorier av
                  personuppgifter, såsom uppgift om hälsa eller annan
                  integritetskänslig information, behandlas av
                  Personuppgiftsbiträdet inom ramen för Personuppgiftsbiträdets
                  tillhandahållande av Tjänsten.
                  <br /> <br />
                  Personuppgiftsbiträdet skall endast behandla personuppgifter
                  enligt dokumenterade instruktioner från Den
                  Personuppgiftsansvarige, såvida inte annat följer av
                  Tillämplig Dataskyddslagstiftning. Samtliga Den
                  Personuppgiftsansvariges instruktioner till
                  Personuppgiftsbiträdet framgår nedan och i Underbilaga 1, med
                  undantag för eventuella skriftliga instruktioner som Den
                  Personuppgiftsansvarige under avtalstiden är skyldig att lämna
                  för att kunna uppfylla Tillämplig Dataskyddslagstiftning. Alla
                  andra ändringar i Den Personuppgiftsansvariges instruktioner
                  skall, med undantag från vad som anges i första stycket i
                  punkt 4 nedan, överenskommas separat och, för att bli
                  gällande, dokumenteras skriftligt och undertecknas av båda
                  Parter. Vid samtliga ändringar skall Personuppgiftsbiträdet
                  äga rätt till skälig ersättning enligt punkt 11 nedan.
                  <br /> <br />
                  Med undantag för vad som anges i andra stycket ovan får
                  Personuppgiftsbiträdet inte behandla personuppgifter för egna
                  ändamål eller andra ändamål än de som framgår av
                  Underhållsavtalet. Part skall säker­ställa att den andra
                  Parten har rätt att be­handla kontaktuppgifter och eventuella
                  andra personuppgifter till den förstnämnda Partens anställda
                  om och i den utsträckning det behövs för att underlätta
                  utförandet av Tjänsten.
                </p>

                <h3>4. Säkerhetsåtgärder</h3>
                <p>
                  Personuppgiftsbiträdet skall implementera de organisatoriska
                  och tekniska åtgärder som krävs enligt Tillämplig
                  Dataskyddslagstiftning och som framgår av Underbilaga 1 till
                  skydd mot personuppgiftsincidenter för de personuppgifter som
                  behandlas. Personuppgiftsbiträdet skall vidare vid
                  tillhandahållandet av Tjänsten följa de säkerhetsföreskrifter
                  som framgår av Underbilaga 1 och Underhållsavtalet samt sina
                  interna säkerhetsföreskrifter. Personuppgiftsbiträdet får
                  ändra sina interna säkerhetsföreskrifter förutsatt att sådana
                  ändringar inte strider mot Tillämplig Dataskyddslagstiftning.
                  <br /> <br />
                  Den Personuppgiftsansvarige ansvarar för att de ovan avtalade
                  säkerhetsåtgärderna uppfyller Den Personuppgiftsansvariges
                  skyldigheter enligt Tillämplig Dataskyddslagstiftning om krav
                  på säkerhet för de personuppgifter som behandlas. För det fall
                  Den Personuppgiftsansvarige begär ändringar i
                  säkerhetsåtgärderna gäller för sådan begäran samma principer
                  som vid ändring i Den Personuppgiftsansvariges instruktioner
                  enligt andra stycket i punkt 3 ovan. För det fall
                  Personuppgiftsbiträdet under avtalstiden konstaterar att
                  överenskomna säkerhetsåtgärder strider mot Tillämplig
                  Dataskyddslagstiftning åligger det Personuppgiftsbiträdet att
                  meddela Den Personuppgiftsansvarige därom inom skälig tid och
                  invänta skriftliga instruktioner avseende ändrade
                  säkerhetsåtgärder. För det fall Personuppgiftsbiträdet inte
                  har erhållit sådana instruktioner inom skälig tid har
                  Personuppgiftsbiträdet rätt att på Den
                  Personuppgiftsansvariges bekostnad vidta skäliga och
                  nödvändiga säkerhetsåtgärder för att Tillämplig
                  Dataskyddslagstiftning skall kunna uppfyllas.
                </p>

                <h3>5. Underbiträden och tredjelandsöverföringar</h3>
                <p>
                  Personuppgiftsbiträdet har en generell rätt att anlita
                  underbiträden inom och utanför EU/EES och får överföra
                  personuppgifter utanför EU/EES. Personuppgiftsbiträdet skall
                  tillse att underbiträden är bundna av skriftliga avtal som
                  ålägger dem motsvarande skyldigheter vid databehandlingen som
                  de skyldigheter som gäller enligt detta Biträdesavtal.
                  Personuppgiftsbiträdet ansvarar fullt ut för de underbiträden
                  som anlitas gentemot Den Personuppgiftsansvarige. Underbilaga
                  2 föreskriver på förhand godkända underbiträden vid ingången
                  av detta Biträdesavtal.
                  <br /> <br />
                  Om personuppgifter överförs till, eller åtkomst möjliggörs
                  från, plats utanför EU/EES skall Personuppgiftsbiträdet
                  säkerställa att det finns en laglig grund för överföringen
                  enligt Tillämplig Dataskyddslagstiftning, exempelvis genom
                  EU-kommissionens modellklausuler. Personuppgiftsbiträdet skall
                  ha rätt att ingå sådana standardavtalsklausuler med
                  underbiträden för Den Personuppgiftsansvariges räkning.
                  <br /> <br />
                  Om Personuppgiftsbiträdet avser att anlita ett nytt eller
                  ersätta ett befintligt underbiträde för att behandla
                  personuppgifter som omfattas av detta Biträdesavtal skall
                  Personuppgiftsbiträdet i förväg informera Den
                  Personuppgiftsansvarige om detta och bereda denne möjlighet
                  att framföra invändningar. Om Den Personuppgiftsansvarige vill
                  invända mot sådana förändringar skall det ske skriftligen och
                  omgående i samband med Personuppgiftsbiträdets information om
                  förändringen. Om Personuppgiftsbiträdet trots Den
                  Personuppgiftsansvariges invändning ändå vill ersätta eller
                  anlita ett nytt underbiträde skall Den Personuppgiftsansvarige
                  ha rätt att säga upp Underhållsavtalet och Biträdesavtalet
                  till omedelbart upphörande. Med befogad anledning avses i
                  denna punkt omständigheter på underleverantörens sida som i
                  betydande utsträckning påverkar, eller sannolikt riskerar att
                  påverka, skyddet för den registrerades personliga integritet,
                  såsom att det nya underbiträdet inte uppfyller kraven för
                  personuppgiftsbiträden enligt Tillämplig
                  Dataskyddslagstiftning.
                </p>

                <h3>6. Personuppgiftsincidenter</h3>
                <p>
                  Vid inträffade av personuppgiftsincident skall
                  Personuppgiftsbiträdet utan oskäligt dröjsmål efter det att
                  Personuppgiftsbiträdet fått vetskap om incidenten meddela Den
                  Personuppgiftsansvarige därom. Personuppgiftsbiträdet skall
                  bistå Den Personuppgiftsansvarige med den information som
                  rimligen kan krävas för att uppfylla dennes skyldighet att
                  anmäla personuppgiftsincidenten (exempelvis avseende
                  incidentens art, kategorier av och antal berörda, sannolika
                  konsekvenser samt nödvändiga och vidtagna åtgärder).
                  <br /> <br />
                  Om Den Personuppgiftsansvarige i strid med Tillämplig
                  Dataskyddslagstiftning inte informerar den registrerade om en
                  personuppgiftsincident och Datainspektionen förelägger
                  Personuppgiftsbiträdet att åtgärda bristen, skall Den
                  Personuppgiftsansvarige ersätta leverantörens kostnader för
                  att uppfylla sådant föreläggande.
                </p>

                <h3>7. Assistans</h3>
                <p>
                  Personuppgiftsbiträdet skall assistera Den
                  Personuppgiftsansvarige i uppfyllandet av de registrerades
                  rättigheter enligt Kapitel III i dataskyddsförordningen,
                  exempelvis i förhållande till insyn och villkor, information
                  och tillgång till personuppgifter, rättelse, radering och rätt
                  att göra invändningar och automatiserat individuellt
                  beslutsfattande samt dataportabilitet.
                  <br /> <br />
                  Personuppgiftsbiträdet skall vidare bistå Den
                  Personuppgiftsansvarige i dennes fullgörande av de
                  skyldigheter som Den Personuppgiftsansvarige har avseende
                  säkerhet, personuppgiftsincidenter, konsekvensbedömningar och
                  förhandssamråd enligt Tillämplig Dataskyddslagstiftning.
                </p>

                <h3>8. Sekretess och utlämnande av personuppgifter</h3>
                <p>
                  Med undantag för sådan behandling som anges i detta
                  Biträdesavtal förbinder sig Personuppgiftsbiträdet att inte
                  utan föregående skriftligt medgivande från Den
                  Personuppgiftsansvarige utlämna eller på annat sätt göra
                  personuppgifter som behandlats enligt detta Biträdesavtal
                  tillgängliga för tredje part, om annat inte följer av svensk
                  eller europeisk lag, domstols- eller myndighetsbeslut. Om
                  behörig myndighet begär information från
                  Personuppgiftsbiträdet om behandlingen av personuppgifter
                  skall Personuppgiftsbiträdet utan onödigt dröjsmål informera
                  Den Personuppgiftsansvarige om detta, om annat inte följer av
                  svensk eller europeisk tillämplig lag, domstols- eller
                  myndighetsbeslut.
                  <br /> <br />
                  Personuppgiftsbiträdet skall tillse att samtliga anställda,
                  konsulter och övriga som Personuppgiftsbiträdet svarar för och
                  som behandlar personuppgifterna, är bundna av en
                  sekretessförbindelse. Detta krävs dock inte om dessa redan
                  omfattas av en straffsanktionerad tystnadsplikt som följer av
                  lag. Personuppgiftsbiträdet skall även tillse att det finns
                  sekretessavtal med eventuella underbiträden samt
                  sekretessförbindelser mellan underbiträdet och dess personal.
                  <br /> <br />
                  Om en registrerad begär information från
                  Personuppgiftsbiträdet om behandlingen av dennes
                  personuppgifter skall Personuppgiftsbiträdet utan onödigt
                  dröjsmål hänskjuta sådan begäran till Den
                  Personuppgiftsansvarige.
                </p>
              </Col>
              <Col lg="6">
                <h3>9. Rätt till granskning</h3>
                <p>
                  Den Personuppgiftsansvarige skall, i sin egenskap av
                  personuppgiftsansvarig, ha rätt att vidta erforderliga
                  åtgärder för att verifiera att Personuppgiftsbiträdet fullgör
                  sina skyldigheter enligt detta Biträdesavtal och att
                  Personuppgiftsbiträdet har vidtagit de åtgärder som krävs för
                  att säkerställa att skyldigheterna fullgörs.
                  <br /> <br />
                  Personuppgiftsbiträdet förbinder sig att tillhandahålla Den
                  Personuppgiftsansvarige den information som krävs för att visa
                  att Personuppgiftsbiträdets skyldigheter enligt
                  Biträdesavtalet fullgörs. Personuppgiftsbiträdet skall
                  möjliggöra för och medverka till sådan granskning, inklusive
                  kontroll på plats, som genomförs av Den
                  Personuppgiftsansvarige eller annan granskare som utsetts av
                  denne, under förutsättning att de personer som utför
                  granskningen ingår sekretessavtal som minst motsvarar
                  regleringarna i de sekretessbestämmelser som anges i detta
                  Biträdesavtal.
                </p>

                <h3>10. Ansvar</h3>
                <p>
                  Om Part drabbas av krav enligt Tillämplig
                  Dataskyddslagstiftning till följd av att den andra Parten inte
                  fullgjort sina åtaganden enligt detta Biträdesavtal eller
                  annars enligt Tillämplig Dataskyddslagstiftning skall
                  sistnämnda Part ersätta förstnämnda Part för samtliga
                  kostnader i anledning av sådant krav.
                </p>

                <h3>11. Rätt till ersättning för arbete</h3>
                <p>
                  Reglering av ersättning för Tjänsten och den
                  personuppgiftsbehandling som genomförs av
                  Personuppgiftsbiträdet i samband därmed regleras av
                  Leveransavtalet. Personuppgiftsbiträdet skall dock äga rätt
                  till skälig tillkommande ersättning på löpande räkning för
                  samtliga sådana arbetsinsatser som inte framgår av Underbilaga
                  1, innefattande exempelvis arbetsinsatser hänförliga till
                  assistans vid personuppgiftsincident som ej orsakats av
                  Personuppgiftsbiträdet (punkt 6), assistans i förhållande till
                  registrerade och tillsynsmyndigheter (punkt 7), granskning
                  (punkt 9) och utlämnande/radering av data vid Biträdesavtalets
                  upphörande (punkt 12).
                </p>

                <h3>
                  12. Avtalstid och åtgärder vid Biträdesavtalets upphörande
                </h3>
                <p>
                  Detta Biträdesavtal gäller så länge som Personuppgiftsbiträdet
                  behandlar personuppgifter för vilka Den
                  Personuppgiftsansvarige är personuppgiftsansvarig.
                  <br /> <br />
                  Vid Biträdesavtalets upphörande skall Den
                  Personuppgiftsansvarige från Personuppgiftsbiträdet
                  skriftligen inom 30 dagar begära ut alla sådana
                  personuppgifter som Den Personuppgiftsansvarige önskar
                  behålla, varefter Personuppgiftsbiträdet kommer att radera
                  personuppgifterna i den mån fortsatt lagring av
                  personuppgifterna inte krävs enligt tillämplig lagstiftning.
                  <br /> <br />
                  På Den Personuppgiftsansvariges begäran skall
                  Personuppgiftsbiträdet lämna skriftligt besked avseende vilka
                  åtgärder som vidtagits i samband med Biträdesavtalets
                  upphörande.
                </p>

                <h3>13. Övrigt</h3>
                <p>
                  Parts underlåtenhet att utnyttja någon rättighet enligt
                  Biträdesavtalet eller underlåtenhet att påtala visst
                  förhållande hänförligt till Biträdesavtalet skall inte
                  innebära att Part frånfallit sin rätt i sådant avseende.
                  Skulle Part vilja avstå från att utnyttja viss rättighet eller
                  att påtala visst förhållande skall sådant avstående ske
                  skriftligen i varje enskilt fall.
                  <br /> <br />
                  Skulle någon bestämmelse i Biträdesavtalet eller del därav
                  befinnas ogiltig, skall detta inte innebära att
                  Biträdesavtalet i dess helhet är ogiltigt utan skall, i den
                  mån ogiltigheten väsentligen påverkar Parts utbyte av eller
                  prestation enligt Biträdesavtalet, skälig jämkning i
                  Biträdesavtalet ske.
                  <br /> <br />
                  Part får inte helt eller delvis överlåta eller pantsätta sina
                  rättigheter och/eller förpliktelser enligt detta Biträdesavtal
                  utan den andra Partens föregående skriftliga godkännande.
                </p>

                <h3>14. Tillämplig lag och tvistlösning</h3>
                <p>
                  Svensk lag skall tillämpas på detta Biträdesavtal.
                  <br /> <br />
                  Tvister som uppstår i anledning av detta Biträdesavtal skall
                  avgöras i allmän domstol med Växjö tingsrätt som första
                  instans.
                </p>

                <h2 style={{ marginTop: 45 }}>UNDERBILAGA 1</h2>
                <h4>SPECIFIKATION ÖVER BEHANDLINGEN</h4>
                <br />
                <h5>Kontaktpersoner för personuppgiftsfrågor</h5>
                <p>Enligt vad som anges i Leveransavtalet.</p>
                <h5>Omfattning och syfte för behandlingen</h5>
                <p>
                  Personuppgiftsbehandlingen innefattar registrering, lagring
                  och användning av personuppgifter för att
                  Personuppgiftsbiträdet ska kunna adressera varuleveranser
                  direkt till Den Personuppgiftsansvariges slutkunder (istället
                  för att varorna skickas till Den Personuppgiftsansvarige som
                  sedan skickar dem vidare till slutkunden).
                  <br /> <br />
                  Behandlingen genomförs för att Personuppgiftsbiträdet ska
                  kunna fullgöra sina avtalade förpliktelser enligt
                  Leveransavtalet.
                  <br /> <br />
                  <h5>Varaktighet av behandlingen</h5>
                  <p>
                    Personuppgiftsbehandlingen genomförs så länge
                    Leveransavtalet gäller mellan Parterna och uppgifter om
                    enskilda varuleveranser lagras endast så länge det är
                    nödvändigt för att leveranserna ska kunna genomföras och
                    följas upp samt för att eventuella krav ska kunna hanteras.
                  </p>
                </p>
                <br />
                <h5>Kategorier av registrerade och personuppgifter</h5>
                <p>
                  Personuppgifterna avser Den Personuppgiftsansvariges anställda
                  och kunder och omfattar information avseende namn,
                  referensnummer, kundnummer, adress, telefonnummer, mejladress,
                  personnummer samt beställda varor.
                  <br /> <br />
                  Inga uppgifter inom s.k. särskilda kategorier av
                  personuppgifter ska behandlas.
                </p>
                <br />
                <h5>Säkerhetsåtgärder</h5>
                <p>
                  Tekniskt system för behörighetskontroll skall styra åtkomsten
                  till personuppgifterna. Behörigheten ska begränsas till de
                  medarbetare som behöver uppgifterna för sina arbetsuppgifter.
                  <br /> <br />
                  Användaridentitet och lösenord ska vara personliga och får
                  inte överlåtas till någon annan. Rutiner för tilldelning och
                  borttagande av behörigheter ska finnas.
                  <br /> <br />
                  Fysisk säkerhet ska säkerställas, fysiska nycklar och
                  nyckelkort ska användas på de platser där utrustning som
                  innehåller personuppgifter förvaras.
                  <br /> <br />
                  System som innehåller personuppgifter måste skyddas av
                  lämpliga brandväggar och anti-virusprogram som regelbundet
                  uppdateras.
                  <br /> <br />
                  System som innehåller personuppgifter måste regelbundet
                  uppdateras med säkerhetspatchar.
                  <br /> <br />
                  Personuppgifterna måste vara krypterade vid förflyttning,
                  exempelvis genom SSL eller liknande.
                  <br /> <br />
                  Personuppgifter måste på ett säkert sätt raderas och göras
                  obrukbara i sådan utrustning som avvecklas.
                  <br /> <br />
                  Beprövade rutiner för säkerhetskopiering och återställning av
                  personuppgifter ska användas.
                  <br /> <br />
                  Personuppgiftsbiträdet ska ha rutiner för att meddela Den
                  Personuppgiftsansvarige vid händelse av ett intrång eller
                  förlust avseende personuppgifter.
                  <br /> <br />
                  Personuppgiftsbiträdet ska utbilda och träna personal i de
                  rutiner och åtgärder som nämnts ovan.
                </p>
                <h5>Godkända underbiträden</h5>
                <p>
                  Venture Design Logistic
                  <br /> <br />
                  DHL Global Logistic / Deutsche Post World Net
                  <br /> <br />
                  DSV Global Transport and Logistics
                  <br /> <br />
                  Alwex AB
                  <br /> <br />
                  Post Nord AB
                  <br /> <br />
                  BFT Logistik
                  <br /> <br />
                  Extend Commerce Sverige AB
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default Page(ConditionPage);
