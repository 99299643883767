import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

import LoginForm from '../components/forms/loginForm';
import RegisterForm from '../components/forms/registerForm';
import { API_ENDPOINT } from '../constants';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';
import AccountNav from '../components/accountNav';

var striptags = require('striptags');

class AuthPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  login = (data) => {
    console.log(data);

    fetch(API_ENDPOINT + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (!result.error) {
          if (result.ffCode) {
            this.props[0].history.push(
              '/friends-and-family?code=' + result.ffCode,
            );

            return;
          }

          localStorage.setItem('authToken', result.token);
          this.props.verifyUser(() => {
            this.props[0].history.push('/account');
          });
        } else {
          this.setState({
            loginError: result.error,
          });
        }
      });
  };

  register = (data) => {
    console.log(data);

    fetch(API_ENDPOINT + '/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (!result.error) {
          this.props[0].history.push('/');
        } else {
          this.setState({
            registerError: result.error,
          });
        }
      });
  };

  render() {
    return (
      <div className="account-page">
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? imageMobile
                : image
            }
          />
          <div className="test-content">
            <h1>{'LOGGA IN'.translate(this.props.lang)}</h1>
          </div>
        </section>

        <Container>
          <Row>
            <Col lg="4">
              <AccountNav {...this.props} />
            </Col>
            <Col lg="8">
              <div className="account-container">
                <h3>{'HEJ!'.translate(this.props.lang)}</h3>
                <h6>
                  {'Vänligen ange ditt användarnamn och lösenord som du fick via e-post när du blev vår kund.'.translate(
                    this.props.lang,
                  )}
                </h6>
                <LoginForm lang={this.props.lang} onSubmit={this.login} />
                {this.state.loginError ? (
                  <p className="form-error">{this.state.loginError}</p>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(AuthPage);
