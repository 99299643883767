import React, { Component } from 'react';
import Page from '../containers/page';
import {
    Col,
    Container, Row,

} from 'reactstrap';

import {getSearchParams} from "../helpers/linkHelper";
import {API_ENDPOINT} from "../constants";
import BriqpayFactureForm from "../components/forms/briqpayFactureForm";
import FactureForm from "../components/forms/factureForm";

import Text from "../components/forms/fields/text";
import DatePicker from "../components/forms/fields/date";
import Check from "../components/forms/fields/check";
import {submit} from "redux-form";
const renderTextField = ({
                             input,
                             label,
                             placeholder,
                             meta: { touched, error },
                             type,
                         }) => (
    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
);

const renderDatePickerField = ({
                                   input,
                                   placeholder,
                                   label,
                                   meta: { touched, error },
                               }) => (
    <DatePicker
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
);
const renderCheckField = ({
                              input,
                              placeholder,
                              label,
                              meta: { touched, error },
                          }) => (
    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />

);
class BriqpayPaymentPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            clientToken: null,
            status: null,
            sessionId: null,
            items: [],
            showForm: true,
            deliverTo: 'shipping',
            termsAndConditions: false,
            partialDeliveryBriqPay: false,
            partialDelivery: false,
            deliveryDate: null,
            freightMarks: null,
            orderReference: null,
            validForm: true,
        };


    }
    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
            ).then((data) => {
                this.setState({
                    ...data,
                });
            });
        }

        let params = getSearchParams(this.props[0].location.search, {});

        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](
                    window.fetch,
                    this.props.lang,
                    this.props[0].match,
                    params,
                    this.props[0].location.pathname,
                ).then((data) => {
                    localStorage.setItem('brigPaySession', data.sessionId);
                    this.setState(
                        {
                            ...data,
                            loading: null,
                        },
                        () => {
                            this.props.updateMeta(
                                this.props.generateSeoTags(this.state, this.props.lang),
                            );
                        },
                    );
                });
            }
        }

        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang),
        );
    };
    async componentDidMount() {
        console.log('BRIQPAY PAYMENT 111')
        await this.get();
        this.setupBriqpayExample()


    }
    componentWillUnmount() {
        this.unsubscribeEvents();
    }
    unsubscribeEvents = () => {
        console.log('unsubscribeEvents')
        if(window._briqpay) {
            window._briqpay.unsubscribe('make_decision');
        }
    }

    subscribeToEvents = async () => {
        window._briqpay.subscribe('make_decision', async ({ sessionId }) => {
            if(sessionId) {
                if(!this.state.termsAndConditions) {
                    this.setState({
                        validForm: false
                    })
                    await this.makeDecision(sessionId, 'reject')
                } else {
                    this.setState({
                        validForm: true
                    })
                    await this.saveTemporaryData(sessionId)
                    await this.makeDecision(sessionId, 'true');
                    window._briqpay.v3.resumeDecision();


                }
            }
        });

    }
    setupBriqpayExample = async () => {
        await  window._briqpay.v3.onReady(() => {
            console.log('BRIQPAY READY!')
        });
        this.subscribeToEvents();
    }
    makeDecision = async (sessionId, decision) => {
        await fetch(API_ENDPOINT + `/briqpay/make-decision/${sessionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                decision
            }),
        })
            .then((res) => res.json())

    }
    saveTemporaryData = async (sessionId) => {
        await fetch(API_ENDPOINT + `/briqpay/temporary-order-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                deliveryDate: this.state.deliveryDate,
                freightMarks: this.state.freightMarks,
                orderReference: this.state.orderReference,
                partialDelivery: this.state.partialDelivery,
                sessionId
            }),
        })
            .then((res) => res.json())

    }
    saveBrigPayClient = (sessionId) => {
        fetch(API_ENDPOINT + `/briqpay/client-session-save/${sessionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
    }
    handleFormChange = ({name, value}) => {
        console.log("handleFormChange", name, value)
        this.setState({
            [name]: value
        })
        if(name === 'termsAndConditions') {
            this.setState({
                validForm: value
            })
        }
    }
    render() {
        const iframeSrc = `https://msc-playground.briqpay.com?sessionToken=${this.state.clientToken}`;
        return (
            <div className="cart-page">
                <Container fluid>
                    <Row>
                        <Col lg="5">
                            <div className="cart-ticket">
                                <div className="ticket-details">
                                    <h3>{'Din beställning'.translate(this.props.lang)}</h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th className="product-name">
                                                {'Produkt'.translate(this.props.lang)}
                                            </th>
                                            <th className="product-total">
                                                {'Delsumma'.translate(this.props.lang)}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.items.map((item, idx) => {
                                            return (
                                                <tr className="cart'item">
                                                    <td className="product-name">
                                                        {item.productFamily}{' '}
                                                        {Object.translate(
                                                            item,
                                                            'name',
                                                            this.props.lang,
                                                        )}{' '}
                                                        - {item.price ? item.price.salesUnit + (item.price.salesUnit === 'FRP' ? `(${item.price.unitQuantity})` : '') : ''}&nbsp;
                                                        <strong className="product-quantity">
                                                            ×&nbsp;{item.quantity}
                                                        </strong>
                                                    </td>
                                                    <td className="product-total">
                                <span className="amount">
                                  <bdi>
                                  {
                                      item.price.originalValue && item.price.originalValue != item.price.value ?
                                          <>
                                  <span style={{
                                      textDecoration: 'line-through', color: '#3A3C47',
                                      fontWeight: 300,
                                  }}>
                                                                {this.props.uData &&
                                                                !this.props.uData.creditCardPayment
                                                                    ? Number(item.price.originalValue * item.quantity).formatPrice(2)
                                                                    : Number(item.price.originalValue * item.quantity * (1 + item.price.vat / 100)).formatPrice(2)}
                                      &nbsp;<span>{item.price.currency}</span>

                                  </span>
                                              <br/>

                                          </>

                                          :

                                          null
                                  }


                                      {Number(item.price.value * item.quantity).formatPrice(2)}&nbsp;
                                      <span>{item.price.currency}</span>
                                  </bdi>
                                </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr className="cart-subtotal">
                                            <th>{'Delsumma'.translate(this.props.lang)}</th>
                                            <td>
                                              <span className="amount">
                                                 <bdi>
                                                  {this.props.uData &&
                                                  !this.props.uData.creditCardPayment
                                                      ? parseFloat(this.state.subtotalWithoutDiscount).formatPrice(2)
                                                      : parseFloat(
                                                          this.state.subtotalWithoutDiscount,
                                                      ).formatPrice(2)}
                                                     &nbsp;
                                                     <span className="woocommerce-Price-currencySymbol">
                                                    {this.state.currency}
                                                 </span>
                                                 </bdi>
                                                  </span>
                                            </td>
                                        </tr>
                                        {this.state.discountValue ?
                                            <>
                                                <tr className="tax-total">
                                                    <th>{'Rabat'.translate(this.props.lang)} (-{this.state.discountValue}%)</th>
                                                    <td>
                                            <span className="amount">
                                              <bdi>
                                               -{this.props.uData &&
                                              !this.props.uData.creditCardPayment
                                                  ? parseFloat(this.state.subtotalWithoutDiscount - this.state.subtotal).formatPrice(2)
                                                  : parseFloat(
                                                      this.state.subtotalWithoutDiscount - (this.state.subtotal - this.state.shippingCost),
                                                  ).formatPrice(2)}
                                                  <span>{this.state.currency}</span>
                                              </bdi>
                                            </span>
                                                    </td>
                                                </tr>

                                                <tr className="cart-subtotal">
                                                    <th>{'Delsumma'.translate(this.props.lang)}</th>
                                                    <td>
                                              <span className="amount">
                                                 <bdi>
                                                  {this.props.uData &&
                                                  !this.props.uData.creditCardPayment
                                                      ? parseFloat(this.state.subtotal).formatPrice(2)
                                                      : parseFloat(
                                                          this.state.subtotal,
                                                      ).formatPrice(2)}
                                                     &nbsp;
                                                     <span className="woocommerce-Price-currencySymbol">
                                                    {this.state.currency}
                                                 </span>
                                                 </bdi>
                                                  </span>
                                                    </td>
                                                </tr>
                                            </>

                                            : null}


                                        <tr className="tax-total">
                                            <th>{'Moms'.translate(this.props.lang)}</th>
                                            <td>
                            <span className="amount">
                              <bdi>
                                {Number(this.state.vat).formatPrice(2)}&nbsp;
                                  <span>{this.state.currency}</span>
                              </bdi>
                            </span>
                                            </td>
                                        </tr>
                                        <tr className="cart-subtotal">
                                            <th>{'Delsumma'.translate(this.props.lang)}</th>
                                            <td>
                                            <span className="amount">
                                            <bdi>
                                            {this.props.uData &&
                                            !this.props.uData.creditCardPayment
                                                ? parseFloat(this.state.total).formatPrice(2)
                                                : parseFloat(
                                                    this.state.total,
                                                ).formatPrice(2)}
                                                &nbsp;
                                                <span className="woocommerce-Price-currencySymbol">
                                              {this.state.currency}
                                            </span>
                                          </bdi>
                                            </span>
                                            </td>
                                        </tr>


                                        <tr className="shipping ">
                                            <td className="shipping'inner" colSpan="2">
                                                <table className="shipping-table ">
                                                    <tbody>
                                                    <tr>
                                                        <th>{'Frakt'.translate(this.props.lang)}</th>
                                                        <td data-title="Frakt">
                                                            <ul
                                                                id="shipping_method"
                                                                className="shipping'list"
                                                            >
                                                                <li className="shipping-list-item">
                                                                    <label
                                                                        className="shipping-list-label"
                                                                        htmlFor="shipping_method_0_flat_rate1"
                                                                    >
                                                                          <span className="amount">
                                                                            <bdi>
                                                                              {this.state.shippingCost}&nbsp;
                                                                                <span>{this.state.currency}</span>
                                                                            </bdi>
                                                                          </span>
                                                                        {/*{'Beräknas på fakturan'.translate(
                                                                            this.props.lang,
                                                                        )}*/}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr className="order-total">
                                            <th> {'Totalt'.translate(this.props.lang)}</th>
                                            <td>
                                                <strong>
                              <span className="amount">
                                <bdi>
                                  {Number(this.state.total + this.state.shippingCost).formatPrice(2)}&nbsp;
                                    <span>{this.state.currency}</span>
                                </bdi>
                              </span>
                                                </strong>{' '}
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>

                                    <div className="facture-form">
                                        <div className="account-container facture-form-container">
                                            {this.props.uData ? (
                                                <>
                                                    <BriqpayFactureForm validForm={this.state.validForm}
                                                                        onFormChange={this.handleFormChange}
                                                                        lang={this.props.lang}/>
                                                </>


                                            ) : null}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="7">
                            <div className="briqpay-container">
                                <h3>
                                    {'Företagsregistrering'.translate(this.props.lang)}
                                </h3>
                                <div id="briqpay-container" className="klarna-confirmation-iframe">
                                <iframe id="briqpay-iframe" title="Checkout" scrolling="no" allow="payment"
                                            src={iframeSrc}>
                                    </iframe>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(BriqpayPaymentPage);
