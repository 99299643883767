import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    // console.log(this.props)
    //console.log(generateAlias("ćčććasd"))

    return (
      <>
        <button
          className={this.props.error ? 'required' : this.props.class}
          disabled={this.props.disabled}
          onClick={this.props.onClick}
        >
          {this.props.value}
        </button>
      </>
    );
  }
}

export default Button;
