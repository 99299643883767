import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { routes, generateRoutes } from './routesList';

class Routes extends Component {
  render() {
    return (
      <div>
        <Switch className="react-switch">
          {generateRoutes(routes).map((route) => {
            if (route.loginNeeded) {
              if (!this.props.uData) {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact
                    render={(...renderProps) => {
                      const Component = route.preAuthComponent;
                      return (
                        <Component
                          {...renderProps}
                          {...this.props}
                          loadData={route.loadData}
                          generateSeoTags={route.generateSeoTags}
                        />
                      );
                    }}
                  />
                );
              } else {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact
                    render={(...renderProps) => {
                      const Component = route.component;
                      return (
                        <Component
                          {...renderProps}
                          {...this.props}
                          loadData={route.loadData ? route.loadData : []}
                          loadDataWithQuery={
                            route.loadDataWithQuery
                              ? route.loadDataWithQuery
                              : []
                          }
                          loadDataWithQueryChildren={route.loadDataWithQueryChildren ? route.loadDataWithQueryChildren : []}
                          generateSeoTags={route.generateSeoTags}
                        />
                      );
                    }}
                  />
                );
              }
            } else {
              // console.log(route.path, !route.disableAvailablePaths && this.props.availablePaths && this.props.availablePaths.indexOf(route.path) === -1)
              if (
                !route.disableAvailablePaths &&
                this.props.availablePaths &&
                this.props.availablePaths.indexOf(route.path) === -1
              ) {
                //       console.log(route);
                return null;
              }

              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact
                  render={(...renderProps) => {
                    const Component = route.component;
                    return (
                      <Component
                        {...renderProps}
                        {...this.props}
                        loadData={route.loadData ? route.loadData : []}
                        loadDataWithQuery={
                          route.loadDataWithQuery ? route.loadDataWithQuery : []
                        }
                        loadDataWithQueryChildren={route.loadDataWithQueryChildren ? route.loadDataWithQueryChildren : []}
                        generateSeoTags={route.generateSeoTags}
                      />
                    );
                  }}
                />
              );
            }
          })}
        </Switch>
      </div>
    );
  }
}

export default Routes;
