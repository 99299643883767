import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import homeBackgroundImage from '../assets/images/kategorisaljare.jpg';

var striptags = require('striptags');

class CategorySellerpage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <section className="category-seller-page">
          <section className="test big-height">
            <div className="test-content">
              <div className="special-text">
                {'Sök nu!'.translate(this.props.lang)}
              </div>
              <div className="header-text">
                {'KATEGORISÄLJARE'.translate(this.props.lang)}
              </div>
              <div className="minor-text">
                {'bli en av oss!'.translate(this.props.lang)}
              </div>
              <a href="mailto:jobb@venturedesign.se">
                <button className="button">
                  {'Ansök här!'.translate(this.props.lang)}
                </button>
              </a>
            </div>
            <img src={homeBackgroundImage} />
          </section>

          <div className="about-content-row-1">
            <Container fluid>
              <Row>
                <Col lg="12">
                  <h2>{'OM TJÄNSTEN'.translate(this.props.lang)}</h2>
                  <p>
                    {'Är du en lösningsfokuserad person som är på jakt efter ett jobb där du får utveckla ditt intresse för försäljning? Vi söker just nu en snabbfotad och prestigelös vinnarskalle i rollen som kategorisäljare till vårt säljteam i Växjö. Hos oss finns oändliga möjligheter att utvecklas och utmanas i din yrkesroll i ett omväxlande och stimulerande arbete. Låter det intressant? Sök tjänsten redan idag!'.translate(
                      this.props.lang,
                    )}
                  </p>
                </Col>
                <Col lg="12">
                  <p className="category-seller-question">
                    {'Vad innebär rollen som kategorisäljare?'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <p>
                    {'Du kommer ingå i ett säljteam där du både pushar och pushas i rätt riktning! Dina arbetsuppgifter kommer i första hand handla om att jobba med kategoriförsäljning ut mot befintliga kunder men också en stor del mot nya potentiella kunder. Ett stort fokus inom tjänsten kommer därför ligga på att skapa och bibehålla goda kundrelationer där du motiveras av att uppnå maximal kundnöjdhet. Du kommer arbeta i olika projekt där du har många bollar i luften och där det ställs krav på lösningsfokus, prestigelöshet och laganda. Du har en förmåga att förhandla och triggas av att uppnå satta säljmål.'.translate(
                      this.props.lang,
                    )}
                  </p>
                </Col>
                <Col lg="12">
                  <p className="category-seller-question">
                    {'Vi ser gärna att du som söker;'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <p className="category-seller-italic">
                    {'– Är snabbfotad och har lätt för att hantera olika typer av människor'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <p className="category-seller-italic">
                    {'– Ser möjligheter och är lösningsfokuserad'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <p className="category-seller-italic">
                    {'– Gillar att jobba efter deadlines'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <p className="category-seller-italic">
                    {'– Är resultatorienterad'.translate(this.props.lang)}
                  </p>
                </Col>
                <Col lg="12">
                  <p className="category-seller-question">
                    {'Vad erbjuder vi?'.translate(this.props.lang)}
                  </p>
                  <p>
                    {'Vi är stolta över våra kompetenta och engagerade medarbetare! Vi tror att framgång kommer genom lagarbete, prestigelöshet och att alltid vilja göra lite bättre än vad vi gjorde igår. Vi erbjuder dig en fantastisk chans att ta ett stort steg i din karriär inom försäljning tillsammans med oss, där du får möjlighet att jobba på en arbetsplats tillsammans med ett gäng drivna kollegor där laget kommer framför jaget!'.translate(
                      this.props.lang,
                    )}
                  </p>
                </Col>
                <Col lg="12">
                  <p className="category-seller-question">
                    {'Ansökan'.translate(this.props.lang)}
                  </p>
                  <p>
                    {'Är det dig vi letar efter? Skicka din ansökan med CV och personligt brev till:'.translate(
                      this.props.lang,
                    )}
                  </p>
                  <p className="category-seller-question">
                    jobb@venturedesign.se
                  </p>
                </Col>
                <Col lg="12">
                  <p className="category-seller-question">
                    {'Har du frågor om tjänsten?'.translate(this.props.lang)}
                  </p>
                  <p>{'Kontakta Pål Johansson'.translate(this.props.lang)}</p>
                  <p>073 511 29 55</p>
                  <Link to="/om-oss">
                    <button className="button">
                      {'OM OSS'.translate(this.props.lang)}
                    </button>
                  </Link>
                </Col>
                <Col lg="12"></Col>
              </Row>
            </Container>
          </div>

          <div className="about-footer"></div>
        </section>
      </div>
    );
  }
}

export default Page(CategorySellerpage);
