import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import homeBackgroundImage from '../assets/images/home.jpg';

var striptags = require('striptags');

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      items: [],
    };
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    let params = getSearchParams(this.props[0].location.search, {});

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          params,
          this.props[0].location.pathname,
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <section className="location-page">
          {/*
                <div className="about-header">
                        <Container>
                            <Row style={{ justifyContent: 'center' }}>
                                <Col lg="12">
                                    <div className="about-header-text">
                                        <img src={aboutImage} />
                                        <h2>HITTA ÅTERFÖRSÄLJARE I ALFABETISK ORDNING</h2>
                                        <p>Våra produkter säljs både i fysiska butiker och online. Här nedan finner du en lista på våra utvalda återförsäljare.</p>
                                    </div>
                                    
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    */}

          <section className="test">
            <img src={homeBackgroundImage} />
            <div className="test-content">
              <h1>
                {'HITTA ÅTERFÖRSÄLJARE I ALFABETISK ORDNING'.translate(
                  this.props.lang,
                )}
              </h1>
              <h6>
                {'Våra produkter säljs både i fysiska butiker och online. Här nedan finner du en lista på våra utvalda återförsäljare.'.translate(
                  this.props.lang,
                )}
              </h6>
            </div>
          </section>

          <div className="reseller-row">
            {this.state.items.map((country, idx) => {
              return (
                <Container fluid style={{ marginBottom: 10 }}>
                  <Row>
                    <Col lg="2">
                      <h6 className="reseller-title">
                        {Object.translate(country, 'name', this.props.lang)}
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    {country.items.map((item, idx) => {
                      return (
                        <Col lg="2" className="column">
                          <div class="reseller-wrapper">
                            <div class="reseller-company">
                              <h3>{item.name}</h3>
                            </div>
                            {item.image ? null : (
                              <div class="reseller-border"></div>
                            )}
                            {item.image ? (
                              <div class="reseller-logo">
                                <img
                                  src={
                                    API_ENDPOINT.replace('testapi', 'api') +
                                    item.image
                                  }
                                  width="100%"
                                  height="100%"
                                  className="imglogolocation"
                                />
                              </div>
                            ) : null}
                            <div class="reseller-address">{item.address}</div>
                            {item.online ? (
                              <div class="reseller-zip-city">
                                {Object.translate(
                                  item,
                                  'onlineName',
                                  this.props.lang,
                                )}
                              </div>
                            ) : (
                              <div class="reseller-zip-city">
                                {item.postalCode} {item.city}
                              </div>
                            )}
                            <div class="reseller-country">
                              {Object.translate(
                                item,
                                'country',
                                this.props.lang,
                              )}
                            </div>
                            <div class="reseller-phone">{item.phone}</div>
                            <div class="reseller-email">{item.email}</div>
                            <div class="reseller-website">
                              <a href={item.website} target="blank">
                                {'Hemsida'.translate(this.props.lang)}
                              </a>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              );
            })}
          </div>

          <div className="about-footer"></div>
        </section>
      </div>
    );
  }
}

export default Page(AboutPage);
