import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import ShippingAddressForm from '../components/forms/shippingAddressForm';
import user from '../assets/images/user.png';
import AccountNav from '../components/accountNav';
import { API_ENDPOINT } from '../constants';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';

var striptags = require('striptags');

class AccountShippingAddressPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      controlPanel: true,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }
  submit = (data) => {
    fetch(API_ENDPOINT + '/account/address/shipping', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.setState({ error: result.error });
        } else {
          this.props.verifyUser();
          this.props[0].history.push('/account/edit-address');
        }
      });
  };

  render() {
    return (
      <div className="account-page">
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? imageMobile
                : image
            }
          />
          <div className="test-content">
            <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
          </div>
        </section>

        <Container>
          <Row>
            <Col lg="4">
              <AccountNav {...this.props} />
            </Col>
            <Col lg="8">
              <div className="account-container">
                <h3>{'Leveransadress'.translate(this.props.lang)}</h3>
                <ShippingAddressForm
                  lang={this.props.lang}
                  initialValues={this.props.uData.shippingData}
                  onSubmit={this.submit}
                />
                {this.state.error ? <p>{this.state.error}</p> : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(AccountShippingAddressPage);
