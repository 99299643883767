import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';
import AccountNav from '../components/accountNav';

import AccountInfo from '../components/forms/accountInfo';
import user from '../assets/images/user.png';
import image1 from '../assets/images/account-image1.png';
import image2 from '../assets/images/account-image2.png';
import image3 from '../assets/images/account-image3.png';
import image4 from '../assets/images/account-image4.png';

var striptags = require('striptags');

class AccountPageL extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      controlPanel: true,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="account-page">
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? imageMobile
                : image
            }
          />
          <div className="test-content">
            <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
          </div>
        </section>

        <Container>
          <Row>
            <Col lg="4">
              <AccountNav {...this.props} />
            </Col>
            <Col lg="8">
              <div className="account-container">
                <h5>
                  {'Hej'.translate(this.props.lang)}{' '}
                  <span>
                    {this.props.uData.userData &&
                    this.props.uData.userData.nickName
                      ? this.props.uData.userData.nickName
                      : this.props.uData.username}
                  </span>
                </h5>
                <div className="image-blocks">
                  <Row>
                    <Col lg="6">
                      <Link to="/account/orders">
                        <div>
                          <img src={image1} />
                          <h6>{'MINA ORDRAR'.translate(this.props.lang)}</h6>
                        </div>
                      </Link>
                    </Col>
                    <Col lg="6">
                      <Link to="/account/orders">
                        <div>
                          <img src={image2} />
                          <h6>{'SPÅRA PAKET'.translate(this.props.lang)}</h6>
                        </div>
                      </Link>
                    </Col>
                    <Col lg="6">
                      <Link to="/account/return-goods">
                        <div>
                          <img src={image3} />
                          <h6>{'RETURNERA'.translate(this.props.lang)}</h6>
                        </div>
                      </Link>
                    </Col>
                    <Col lg="6">
                      <Link to="/kontakt">
                        <div>
                          <img src={image4} />
                          <h6>{'KONTAKTA OSS'.translate(this.props.lang)}</h6>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page(AccountPageL);
