import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import homeBackgroundImage from '../assets/images/stedger-banner.jpg';
import homeBackgroundImageMobile from '../assets/images/stedger-banner.jpg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class DynamicPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  get = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          getSearchParams(this.props[0].location.search, {}),
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };
  componentDidMount() {
    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
      this.get();
    }
  }

  render() {
    return (
      <div>
        <section className="test">
          <img
            src={
              typeof window !== 'undefined' && window.innerWidth < 768
                ? homeBackgroundImageMobile
                : homeBackgroundImage
            }
          />
          <div className="test-content">
          </div>
        </section>

        <section className="cookies-page">
          <Container fluid>
            <Row>
              <Col lg={{ size: 6, offset: 3 }}>
                <div
                  className="cookie-content"
                style={{textAlign: 'center'}}
                >
                   <h2>{'Your life just got 10 times easier!'.translate(this.props.lang)} </h2>
                                    <p>{'We are happy to announce that Venture Design has partnered up with Stedger, one of Europes biggest dropshipping partners.'.translate(this.props.lang)}</p>
                                    <p><strong>{'With Stedger x Venture Design you will be able to:'.translate(this.props.lang)}</strong></p>
                                    <ul>
                                    <li style={{fontWeight: 300}}>{'1. Publish products in seconds with all content preloaded with a single click of a button.'.translate(this.props.lang)}</li>
                                    <li style={{fontWeight: 300}}>{'2. Never need to update your stock – Stedger does it for you.'.translate(this.props.lang)}</li>
                                    <li style={{fontWeight: 300}}>{'3. Orders are automatically forwarded to Venture Design.'.translate(this.props.lang)}</li>


                                    </ul>
                                    <a href="https://stedger.com/venture-design" target="_blank"><button className='button'>{'READ MORE HERE'.translate(this.props.lang)}</button></a>
 
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="about-footer"></div>
      </div>
    );
  }
}

export default Page(DynamicPage);
