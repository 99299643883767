import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import homeBackgroundImage from '../assets/images/omm.jpg';
import aboutImage from '../assets/images/about.jpg';
import aboutImage2 from '../assets/images/about2.jpg';
import aboutImage3 from '../assets/images/about3.jpg';
import aboutImage4 from '../assets/images/about4.jpg';
import aboutImage5 from '../assets/images/about5.jpg';

var striptags = require('striptags');

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    console.log(this.props);
    return (
      <div>
        <section className="about-page">
          {/*
                    <div className="about-header">
                        <Container>
                            <Row style={{ justifyContent: 'center' }}>
                                <Col lg="12">
                                    <div className="about-header-text">
                                        <img src={aboutImage} />
                                        <h2>OM OSS</h2>
                                    </div>
                                    
                                </Col>
                            </Row>
                        </Container>
                    </div>
    */}

          <section className="test big-height">
            <img src={homeBackgroundImage} />
            <div className="big-height-content">
              <h6>{'@VENTURE DESIGN'.translate(this.props.lang)}</h6>
              <h1
                dangerouslySetInnerHTML={{
                  __html: 'FLEXIBLA. PRESTIGELÖSA. DRIVNA.'.translate(
                    this.props.lang,
                  ),
                }}
              ></h1>

              <a href={'/om-oss-mov'} target="_blank" className="button-clasic">
                <div className="button-inner">
                  {'SE FILMEN'.translate(this.props.lang)}
                </div>
                <div className="button-outer">
                  {'SE FILMEN'.translate(this.props.lang)}
                </div>
              </a>
            </div>
          </section>

          <div className="about-content-row-1">
            <Container fluid>
              <Row>
                <Col lg="12">
                  <h2>{'OM OSS'.translate(this.props.lang)}</h2>
                  <p>
                    {'På kontoret i Växjö har det fattats många viktiga och avgörande beslut sedan starten 2014. Vad som ligger i grund för Venture Designs framgång är vår moderna företagsideologi, den har fått oss att växa i stadig takt tack vare lång erfarenhet från möbelbranschen samt ett ungt och modernt tänkande och hungrigt tillvägagångssätt. Det är idag som gäller, inte imorgon.'.translate(
                      this.props.lang,
                    )}
                  </p>
                </Col>
                <Col lg="6">
                  <img src={aboutImage2} />
                </Col>
                <Col lg="6">
                  <img src={aboutImage3} />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="about-content-row-2">
            <Row style={{ justifyContent: 'center' }}>
              <Col lg="8">
                <div className="about-content-3">
                  <h2>
                    {'VÅR LAGUPPSTÄLLNING – VÅRT TEAM'.translate(
                      this.props.lang,
                    )}
                  </h2>
                  <p>
                    {'Teamet på Venture Design är uppbyggt på olika åldrar, erfarenheter och kunskaper. Vi har gått från att omsätta 0 till 200 miljoner på 5 år. Idag har vi över 200 återförsäljare världen över, såväl klassiska möbelbutiker som onlinebaserade företag. Försäljningen sker runt om i hela Skandinavium och ca 20 länder till runt om i Europa. Varje år ser vi till att över 150 000 hem får nya möbler för både inomhus- och utomhusbruk.'.translate(
                      this.props.lang,
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="about-content-row-4">
            <Container fluid>
              <Row>
                <Col lg="6">
                  <div className="about-content-4">
                    <h2>
                      {'VENTURE DESIGN – MOD OCH NYTÄNK'.translate(
                        this.props.lang,
                      )}
                    </h2>
                    <p>
                      {'Vi vill implementera ett innovativt och hållbart tänk för att skapa ett mer aktivt och effektivt företagande. Ett företagande som bland annat tydligt genomsyras av nytänkande, där vi själva både designar, utvecklar, marknadsför och distribuerar möbler med skandinavisk stil och hög prisvärdhet.'.translate(
                        this.props.lang,
                      )}
                    </p>
                  </div>
                </Col>
                <Col lg="6">
                  <img src={aboutImage4} />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="about-content-row-5">
            <Row>
              <Col lg="6">
                <img src={aboutImage5} />
              </Col>
              <Col lg="6">
                <div className="about-content-5">
                  <h2>
                    {'VÅRA FABRIKER OCH ARBETE MOT EN BÄTTRE VÄRLD'.translate(
                      this.props.lang,
                    )}
                  </h2>
                  <p>
                    {'I över 50 fabriker i 6 olika länder jobbar mer än 2000 personer och i vårt dotterbolag finns ett eget logistikföretag på 28 000m2 för att kvalitetssäkra hela kedjan från produktion till leverans. Vi jobbar ständigt med att utveckla våra produkter ur ett hållbarhets- och miljövänligt-perspektiv. Till exempel är de flesta av våra möbler ”knock down”-produkter för att minska ner på frakterna och därmed vår miljöpåverkan.'.translate(
                      this.props.lang,
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="about-footer"></div>
        </section>
      </div>
    );
  }
}

export default Page(AboutPage);
