import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

const required = (value) => (value ? undefined : 'Required');

const renderCheckField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Check
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    // multilang={multilang}
    // lang={lang}

    {...input}
  />
);

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  type,
  disabled,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    disabled={disabled}
    label={label}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

class form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
    };
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="login-form accoutn-info-form">
        <Row>
          {/* <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="username"
                                component={renderTextField}
                                label={"Företagsnamn eller Förnamn och Efternamn *".translate(this.props.lang)}
                                disabled
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <p style={{ fontSize: 16, fontStyle: 'italic', fontWeight: 300, marginBottom: 5 }}>Detta är hur ditt namn kommer visas i kontosektionen och i recensioner</p>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="email"
                                component={renderTextField}
                                label={"E-postadress *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col> */}
          <Col lg="12">
            <h3>{'ÄNDRA LÖSENORD'.translate(this.props.lang)}</h3>
            <h6>
              {'Om du vill ändra lösenord du fått från oss till ett som du lättare kommer ihåg, ange det gamla och det nya lösenordet och bekräfta.'.translate(
                this.props.lang,
              )}
            </h6>
          </Col>

          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="currentPassword"
                component={renderTextField}
                label={'Gammalt lösenord'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="newPassword"
                component={renderTextField}
                label={'Nytt lösenord'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="confirmNewPassword"
                component={renderTextField}
                label={'Upprepa lösenord'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>

          <Col lg="12" className="btn-wrap" style={{ marginTop: 30 }}>
            {this.props.loading ? null : (
              <button className="button">
                {'BEKRÄFTA'.translate(this.props.lang)}
              </button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: 'accountForm', // a unique identifier for this form
})(form);
