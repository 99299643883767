import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import Text from './fields/text';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

const required = (value) => (value ? undefined : 'Required');

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  type,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

class form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, menu } = this.props;

    return (
      <form onSubmit={handleSubmit} className="contact-form">
        <Row>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="name"
                component={renderTextField}
                placeholder={'Namn'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="company"
                component={renderTextField}
                placeholder={'Företag'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="email"
                component={renderTextField}
                placeholder={'E-post'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="message"
                component={renderTextareaField}
                placeholder={'Meddelande...'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>

          <Col lg="12" className="btn-wrap">
            {this.props.loading ? null : (
              <button className="button">
                {'Skicka'.translate(this.props.lang)}
              </button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: 'form', // a unique identifier for this form
})(form);
